/*======================================
=       10. Product Support             =       
========================================*/


.single-support-item {
     margin-top: 50px;
     .header-support {
          display: flex;
          align-items: center;
          .icon {
               line-height: 1;
               font-size: 36px;
               margin-right: 20px;

               line-height: 24px;
               font-size: 24px;
               width: 24px;
               height: 24px;
               color: #d3b48c;
          }
     }
     .iconbox-desc {
          margin-top: 20px;
     }
}

.single-support-item-two {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 50px 10px;
          .icon {
               line-height: 1;
               font-size: 36px;
               margin-right: 20px;
               line-height: 24px;
               font-size: 24px;
               width: 24px;
               height: 24px;
          }
          .iconbox-desc {
               .heading {
                    font-size: 16px;
               }
               p {
                    font-style: italic;
               }
          }
          &.color--one {
               background: #e6de9d;
          }
          &.color--two {
               background: #f9f1e2;
          }
          &.color--three {
               background: #e2d5d5;
          }
     
}

.single-shopping-support {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .icon {
         color: #dcb14a;
         font-size: 48px;
         margin-right: 26px;
    }
}



