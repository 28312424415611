/*======================================
=         18. Fun Factor Css            =
=======================================*/
.fun-fact {
     &--one {
          display: flex;
          margin-top: 30px;
          .fun-fact-content {
               max-width: 300px;
               margin-left: 50px;
               @media #{$small-mobile} {
                    margin-left: 10px;
               }
          }
     }
     &--two {
          p {
               font-size: 14px;
          }
     }
     &--three {
          margin-top: 30px;
          .fun-fact-content {
               text-align: center;
          }
     }
}


.bakery-banner-component {
     height: 100%;
}
.openig-time {
     text-align: center;
     padding: 60px 30px;
     .time-list {
          color: $white;
     }  
     .bakery-contact {
          margin-top: 30px;
          .compt_item {
               color: $white;
               margin: 10px;
               display: inline-block;
               span {
                    margin-left: 10px;
               }
          }
     }
}