
/*======================================
=           12. Banner Css             =       
========================================*/

.single-banner-one {
     .banner-thumbnail {
          position: relative;
          display: block;
          overflow: hidden;
          img {
               transform: scale(1.02);
               width: 100%;
          }
          &:before {
               position: absolute;
               top: 0;
               left: 0;
               width: 100%;
               height: 100%;
               background: rgba(255,255,255,.5);
               content: '';
               -webkit-transition: -webkit-transform 0.6s;
               transition: transform 0.6s;
               -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-120%,0);
               transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-120%,0);
               z-index: 1;
          }
     }
     .banner--info {
          top: 50%;
          width: 100%;
          max-width: 80%;
          left: 10%;
          z-index: 0;
          position: absolute;
          transform: translateY(-50%);
          p {
               color: $black;
          }
          .offer {
               font-weight: 600;
               line-height: 30px;
               font-size: 90px;
               .person {
                    font-size: 16px;
                    margin-left: -20px;
               }
          }
          & button {
               color: $black;
               &:hover {
                    color: $white;
               }
          }
          &-three {
               top: 50%;
               width: 100%;
               max-width: 200px;
               left: 10%;
               z-index: 0;
               position: absolute;
               transform: translateY(-50%);
               text-align: center;
               .offer {
                    font-size: 90px;
                    line-height: 70px;
                    line-height: 30px;
                    font-weight: 700;
                    .person {
                         font-size: 16px;
                         margin-left: -20px;
                    }
               }
               p {
                    color: $black;
                    font-size: 24px;
                    letter-spacing: 2px;
                    font-weight: 700;
               }
               & .btn-shop {
                    color: #222;
                    font-size: 12px;
                    font-weight: 600;
                    margin-top: 20px;
                    border-bottom: 2px solid #222;
                    display: inline-block;
               }
          }
          &-four {
               top: 50%;
               width: 100%;
               max-width: 250px;
               left: 10%;
               z-index: 0;
               position: absolute;
               transform: translateY(-50%);
               text-align: center;
               .offer {
                    font-size: 90px;
                    line-height: 70px;
                    line-height: 30px;
                    font-weight: 700;
                    .person {
                         font-size: 16px;
                         margin-left: -20px;
                    }
               }
               p {
                    color: $black;
                    font-size: 14px;
                    letter-spacing: 2px;
                    font-weight: 600;
               }
               & .btn-shop {
                    color: #222;
                    font-size: 12px;
                    font-weight: 600;
                    margin-top: 20px;
                    border-top: 2px solid #222;
                    display: inline-block;
               }
          }
     }
     .banner--link {
          color: #fff;
          font-size: 12px;
          letter-spacing: 1px;
          font-weight: 700;
          border-radius: 0;
          text-align: center;
     }
     &:hover {
          .banner-thumbnail {
               img {
                    transform: scale(1);
               }
               &:before {
                    opacity: 1;
                    transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,120%,0);
               }
          }
     }
}

.single-banner-two {
     .banner-thumbnail {
          position: relative;
          display: block;
          overflow: hidden;
          img {
               transform: scale(1.1);
               width: 100%;
          }
          &::before {
               position: absolute;
               top: 0;
               left: 0;
               width: 100%;
               height: 100%;
               background: rgba(0,0,0,.4);
               content: '';
               transition: all 0.3s ease-in-out;
               opacity: 0;
               z-index: 1;
          }
          &:hover {
               img {
                    transform: scale(1);
               }
               &::before {
                    opacity: 1;
               }
               .banner--info {
                    z-index: 44;
                    p {
                         position: relative;
                         opacity: 1;
                         z-index: 44;
                    }
                    &-lg {
                         z-index: 4;
                         p {
                              opacity: 1;
                              z-index: 4;
                         }
                    }
               }
          }
     }
     .banner--info {
          top: 50%;
          width: 100%;
          max-width: 80%;
          left: 10%;
          z-index: 0;
          position: absolute;
          transform: translateY(-50%);
          p {
               color: $white;
               letter-spacing: 10px;
               transition: all 0.3s ease-in-out;
               opacity: 0;
          }
          &-lg {
               top: 50%;
               width: 100%;
               max-width: 80%;
               left: 10%;
               z-index: 0;
               position: absolute;
               transform: translateY(-50%);
               p {
                    font-size: 24px;
                    font-family: $font-family-two;
                    color: $white;
                    letter-spacing: 0px;
                    transition: all 0.3s ease-in-out;
                    opacity: 0;
               }
          }
     }
     
}
.banner-slider-active {
     .slick-dots {
          text-align: center;
          li {
               display: inline-block;
               margin: 5px;
               button {
                    font-size: 0;
                    border: none;
                    height: 8px;
                    width: 8px;
                    padding: 0;
                    background: #333;
                    border-radius: 5000px;
               }
               &.slick-active {
                    button {
                         width: 10px;
                         height: 10px;
                         background: #ddd;
                    }
               }
          }
     }
}

.watch-banner-text {
     padding: 60px;
     @media #{$small-mobile}{
          padding: 60px 20px;
     }
}

.shop-section-text-area {
     padding: 150px 0;
     & .sub-title {
          letter-spacing: 3px;
          font-size: 12px;
     }
     h2 {
          font-size: 72px;
          line-height: 72px;
          @media #{$tablet-device} {
               font-size: 52px;
               line-height: 52px;
          }
          @media #{$large-mobile} {
               font-size: 32px;
               line-height: 32px;
          }
     }
     p {
          border-top: 1px solid rgba(255,255,255,.5);
          border-bottom: 1px solid rgba(255,255,255,.5);
          letter-spacing: 3px;
          text-transform: uppercase;
          display: inline-block;
          padding: 10px 0;
          font-size: 12px;
          margin-bottom: 30px;
     }
}

.beauty-single-product {
     position: relative;
     overflow: hidden;
     .banner--image a{
          display: block;
          width: 100%;
          transition: transform 0.35s ease-out;
          transform: scale(1.01);
          position: relative;
          img {
               width: 100%;
          }
          &::before {
               position: absolute;
               content: '';
               height: 100%;
               width: 100%;
               background: rgba(0,0,0,0.2);
               left: 0;
               top: 0;
               transition: all 0.3s ease-in-out;
               opacity: 0;
          }
     }
     .banner--info {
          z-index: 2;
          text-align: center;
          position: absolute;
          left: 0;
          width: 100%;
          transition: all .35s;
          padding: 20px 20px 40px;
          -webkit-transform: translate3d(0,-webkit-calc(100% - 70px),0);
          transform: translate3d(0,calc(100% - 58px),0);
          bottom: 0;
          .title {
               font-size: 18px;
               font-weight: 700;
               position: relative;
               padding-bottom: 30px;
               font-family: $font-family-two;
               &:after {
                    content: "";
                    border-top-width: 1px;
                    border-top-style: solid;
                    border-top-color: transparent;
                    width: 30px;
                    position: absolute;
                    left: 50%;
                    margin-left: -15px;
                    bottom: 15px;
                    transition: all .35s;
                    border-top-color: inherit;
               }
          }
          .banner--info-content {
               opacity: 0;
               visibility: hidden;
               transition: all .35s;
               p {
                    color: #333;
               }
               a {
                    font-weight: 700;
                    font-size: 12px;
                    letter-spacing: 1px;
                    text-decoration: underline;
                    color: #333;
                    &:hover {
                         text-decoration: inherit;
                    }
               }
          }
     }
     &:hover {
          .banner--image a {
               -webkit-transform: scale(1.01) translate3d(0,-50px,0);
               transform: scale(1.01) translate3d(0,-50px,0);
               &::before {
                    opacity: 1;
               }
          }
          .banner--info {
               transform: translate3d(0,0,0);
               .banner--info-content {
                    opacity: 1;
                    visibility: visible;
               }
          }
     }
     & .color-1 {
          background: #ffc7d4;
     }
     & .color-2 {
          background: #eef48f;
     }
     & .color-3 {
          background: #a7adbc;
     }
     & .color-4 {
          background: #9fc539;
     }
     & .color-5 {
          background: #baf2ef;
     }
     & .color-6 {
          background: #b6f4d2;
     }
     & .color-7 {
          background: #2ec4b6;
     }
     & .color-8 {
          background: #ffcb65;
     }
}


.spa-service-item {
     position: relative;
     overflow: hidden;
     .banner--info {
          z-index: 2;
          text-align: center;
          position: absolute;
          left: 50%;
          max-width: 70%;
          transition: all .35s;
          padding: 20px;
          top: 50%;
          width: 100%;
          transform: translate(-50%,-50%);
          .title {
               font-size: 40px;
               line-height: 1.15;
               color: #fff;
               text-transform: uppercase;
               letter-spacing: 5px;
               @media #{$desktop-device} {
                    font-size: 28px;
               }
               @media #{$tablet-device} {
                    font-size: 28px;
               }
               @media #{$large-mobile} {
                    font-size: 28px;
               }
          }
          .banner--btn {
               border: 2px solid #fff;
               color: #fff;
               font-size: 12px;
               padding: 20px 30px;
               letter-spacing: 1px;
               width: 360px;
               display: block;
               margin: 20px auto 0;
               max-width: 100%;
               transition: all 250ms ease-out;
               &:hover {
                    background: #fff;
                    color: $black;
                    border: 2px solid #fff;
               }
               @media #{$tablet-device} {
                    padding: 10px 20px;
                    width: 220px;
               }
               @media #{$large-mobile} {
                    width: 220px;
                    padding: 10px 20px;
               }
          }
     }
     &:hover {
          .image {
               img {
                    transform: scale(1.1);
               }
          }
     }
}


.single-banner--three {
     display: block;
     position: relative;
     overflow: hidden;
     img {
          width: 100%;
     }
     .banner--info {
          position: absolute;
          max-width: 90%;
          left: 50%;
          transition: all .45s ease-out;
          z-index: 2;
          top: 50%;
          transform: translate(-50%,-50%);
          text-align: center;
          .sub-title {
               font-size: 12px;
               color: $white;
               letter-spacing: 5px;
          }
          .title {
               font-size: 36px;
               color: $white;
               letter-spacing: 5px;
               @media #{$tablet-device}{
                    font-size: 26px;
               }
          }
          .text-btn {
               text-transform: uppercase;
               border-bottom: 1px solid #ddd;
               color: $white;
               font-size: 12px;
               margin-top: 30px;
               display: inline-block;
          }
     }
     &:hover {
          .banner-images {
               img {
                    transform: scale(1.07);
               }
          }
     }
}


.vc_col-sm-4 {
     width: 28.125%;
     @media #{$large-mobile}{
          width: 100%;
     }
     &:nth-child(2) {
          width: 43.75%;
          @media #{$large-mobile}{
               width: 100%;
          }
     }
}
