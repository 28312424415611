/*==============================================
=         29. Cart & Checkout Pages Css          =
================================================*/
h3.cart-page-title {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 15px;
}
.header-color-gray {
    &.cart-table-content {
        table {
            thead > tr {
                th {
                    padding: 10px 0;
                }
            }
            tbody > tr {
                td {
                    padding: 20px 0px 20px;
                }
            }
        }
    }
}
.cart-table-content {
    table {
        width: 100%;
        thead > tr {
            th {
                border-top: medium none;
                font-size: 14px;
                text-transform: capitalize;
                vertical-align: middle;
                white-space: nowrap;
                color: $black;
                letter-spacing: 1px;
                font-weight: 500;
                text-transform: capitalize;
                padding: 10px 0 10px 0;
                @media #{$desktop-device}{
                    padding: 0 0 33px 0;
                }
                @media #{$large-mobile}{
                    padding: 0 20px 33px 20px;
                }
                .product-name {
                    max-width: 500px;
                }
                .product-price {
                    max-width: 300px;
                }
            }
        }
        tbody > tr {
            td {
                font-size: 14px;
                padding: 40px 0px 40px;
                border-bottom: 1px solid #ddd;
            }
            td.product-remove {
                min-width: 40px;
                a {
                    color: #777777;
                }      
            }
            td.product-img {
                min-width: 150px;
            }
            td.product-name {
                width: 470px;
                @media #{$tablet-device}{
                    min-width: 300px;
                }
                @media #{$small-mobile}{
                    min-width: 250px;
                }
                a {
                    font-size: 14px;
                    font-weight: 500;
                    &:hover {
                        color: $theme-color--default;
                    }
                }
            }
            td.cart-quality {
                min-width: 180px;
            }
            td.product-price {
                min-width: 150px;
                span {
                    color: #1f2226;
                    font-weight: 500;
                }
            }
            td.product-stock-status {
                min-width:  180px;
                font-weight: 500;
                p {
                    color: #690;
                    font-size: 16px;
                }
                
            }
            td.product-total {
                min-width: 70px;
                span {
                    font-weight: 600;
                }
            }
            td.price-total {
                min-width: 70px;
                span {
                    font-weight: 600;
                }
            }
            td.product-wishlist-cart {
                text-align: right;
                min-width: 250px;
                > a {
                    background-color: #232323;
                    color: #fff;
                    font-size: 16px;
                    line-height: 1;
                    padding: 14px 25px;
                    text-transform: capitalize;
                    &:hover {
                        background-color: $theme-color--default;
                    }
                }
            }
        }
    }
}
.cart-shiping-update-wrapper {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #CDCDCD;
    padding: 18px 0 0;
    @media #{$large-mobile}{
        display: block;
    }
    .discount-code {
        position: relative;
        flex: 0 0 50%;
        input {
            padding: 0 115px 0 0;
            border: none;
            height: 34px;
            font-size: 12px;
            color: #282828;
            background-color: transparent;
            border-bottom: 1px solid #D8D8D8;
        }
        button {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            border: none;
            padding: 0;
            background-color: transparent;
            font-weight: 600;
            font-size: 14px;
            
        }
    }
    .cart-clear {
        a {
            font-weight: 600;
            display: inline-block;
            margin: 3px 0 0 7px;
        }
        @media #{$large-mobile}{
            margin-top: 20px;
        }
    }
}

.grand-total-wrap {
    
    border: 1px solid #bfbfbf;
    background: #F9F9F9;
    padding: 0px 30px 0;
    @media #{$tablet-device}{
        margin-top: 40px;
    }
    @media #{$large-mobile}{
        margin-top: 40px;
    }
    h4 {
        font-weight: 800;
        font-size: 14px;
        margin: 0;
    }
    .grand-total-content {
        padding: 30px 0 30px;
        ul {
            li {
                font-weight: bold;
                border-bottom: 1px solid #CDCDCD;
                padding-bottom: 16px;
                margin-bottom: 17px;
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                }
                span {
                    float: right;
                    font-weight: 400;
                }
            }
        }
    }
    .grand-btn {
        margin-left: -30px;
        margin-right: -30px;
        a {
            background-color: #262626;
            color: #fff;
            text-align: center;
            font-size: 14px;
            display: block;
            padding: 23px 10px 20px;
            &:hover {
                background-color: $theme-color--default;
            }
        }
    }
}
.discount-code {
    max-width: 400px;
    input {
        width: 100%;
        margin-bottom: 30px;
        border: 1px solid #ccc;
        color: #000;
        border-radius: 0;
        padding: 15px 15px 13px;
    }
}

.cart_totals {
    max-width: 400px;
}

/*-------- 26. Checkout style ---------*/

.customer-zone {
    > p {
        position: relative;
        padding: 14px 30px 14px 60px;
        background-color: #f4f5f7;
        margin: 0;
        &::before {
            content: "\f058";
            color: #6dde6d;
            display: inline-block;
            position: absolute;
            font-family: fontawesome;
            left: 30px;
        }
    }
}

.checkout-login-info {
    border: 1px solid #ddd;
    padding: 30px;
    p {
        color: #777;
        margin: 0 0 22px;
    }
    display: none;
    margin-top: 30px;
    
    .single-input {
        input {
            border: 1px solid #CDCDCD;
            height: 50px;
            background-color: transparent;
            width: 100%;
            color: #777;
            font-size: 14px;
            padding: 0 20px;
            &:focus {
                border: 1px solid #262626;
            }
        }
        label {
            display: block;
            margin: 0 0 10px;
            span {
                color: red;
            }
        }
    }
}

.checkout-coupon-info {
    border: 1px solid #ddd;
    padding: 30px;
    p {
        color: #777;
        margin: 0 0 22px;
    }
    display: none;
    margin-top: 30px;
    form {
        input[type="text"] {
            border: 1px solid #CDCDCD;
            height: 50px;
            background-color: transparent;
            width: 100%;
            color: #777;
            font-size: 14px;
            padding: 0 20px;
            &:focus {
                border: 1px solid #262626;
            }
        }
        input[type="submit"] {
            background: #262626 none repeat scroll 0 0;
            border: medium none;
            color: #fff;
            height: 50px;
            margin-top: 15px;
            padding: 5px 30px 7px;
            text-transform: capitalize;
            font-weight: 400;
            width: inherit;
            @media #{$desktop-device} {
                padding: 5px 15px;
            }
            @media #{$large-mobile} {
                padding: 5px 16px;
                margin-left: 5px;
            }
            &:hover {
                background-color: $theme-color--default;
            }
        }
    }
}

.billing-info-wrap {
    .billing-info {
        label {
            display: block;
            margin: 0 0 10px;
            span {
                color: red;
            }
        }
        input{
            border: 1px solid #e8e8e8;
            height: 50px;
            background-color: transparent;
            padding: 2px 20px;
            color: #777;
            width: 100%;
            &:focus {
                border: 1px solid #262626;
            }
            &.billing-address {
                margin-bottom: 20px;
            }
        }
    }
    .billing-select {
        label {
            display: block;
            margin: 0 0 10px;
            span {
                color: red;
            }
        }
        .select-active {
            border: 1px solid #e6e6e6;
            color: #262626;
            border-radius: 0;
            background: transparent url(../images/icons/selector-icon.png) no-repeat center right 20px !important;
            &:focus {
                background: transparent;
            }
        }
        .select2-container {
            display: block;
            .select2-choice {
                border: 1px solid #e6e6e6;
                background: transparent;
                -moz-appearance: none;
                height: 50px;
                padding: 2px 20px;
                color: #262626;
                cursor: pointer;
                .select2-chosen {
                    height: 50px;
                }
            }
        }
    }
    .additional-info-wrap {
        margin: 3px 0 0;
        label {
            display: block;
            margin: 0 0 10px;
            padding: 5px 0 0;
        }
        textarea {
            min-height: 120px;
            background-color: transparent;
            border: 1px solid #e8e8e8;
            padding: 20px;
            color: #8a8a8a;
            width: 100%;
        }
    }
    @media #{$desktop-device} {
        &.mr-100 {
            margin-right: 30px;
        }
    }
    @media #{$tablet-device} {
        &.mr-100 {
            margin-right: 0px;
        }
    }
    @media #{$small-mobile} {
        &.mr-100 {
            margin-right: 0px;
        }
    }
}

.select2-container--default .select2-selection--single {
	border: 1px solid #e6e6e6;
	border-radius: 0px;
    cursor: pointer;
    display: block;
    height: 50px;
    user-select: none;
    -webkit-user-select: none;
    line-height: 50px;
    .select2-selection__rendered {
        height: 50px;
        color: #262626;
        line-height: 50px;
        padding: 0px 20px;
    }
    .select2-selection__arrow {
        height: 50px;
    }
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    height: 34px;
    background-color: transparent;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #0073aa;
	color: #fff;
}


.your-order-area {
	padding: 40px 45px 50px;
	border-width: 2px;
	border-style: solid;
	position: relative;
	border: 1px solid #bfbfbf;
    background-color: #f6f6f6;
    @media #{$desktop-device} {
        padding: 40px 25px 50px;
    }
    @media #{$tablet-device} {
        margin-top: 30px;
    }
    @media #{$large-mobile} {
        padding: 40px 12px 50px;
        margin-top: 30px;
    }
    h3 {
        font-size: 20px;
        font-weight: 700;
        position: relative;
        margin: 0 0 45px;
        &:before {
            position: absolute;
            content: "";
            left: 0;
            bottom: -14px;
            background-color: #000;
            width: 50px;
            height: 2px;
        }
    }
    .your-order-wrap {
        .your-order-info {
            border-bottom: 1px solid #CDCDCD;
            ul {
                li {
                    padding: 0 0 7px;
                    span {
                        float: right;
                    }
                }
            }
            &.order-subtotal {
                padding: 15px 0 10px;
                ul {
                    li {
                        span {
                            font-size: 16px;
                        }
                    }
                }
            }
            &.order-total {
                padding: 15px 0 10px;
                ul {
                    li {
                        span {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        .your-order-middle {
            border-bottom: 1px solid #CDCDCD;
            padding: 24px 0 23px;
            ul {
                li {
                    font-size: 14px;
                    margin: 0 0 11px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    span {
                        float: right;
                        font-size: 16px;
                    }
                }
            }
        }
        .payment-method {
            margin: 25px 0 0;
            h5 {
                text-transform: uppercase;
            }
            p {
                color: #777;
                font-size: 12px;
                margin: 14px 0 0;
            }
        }
        .condition-wrap {
            margin: 40px 0 0;
            p {
                color: #777;
                line-height: 1.88;
                margin: 0;
                a {
                    color: #777;
                }
            }
            .condition-form {
                input {
                    border: 1px solid #9fa0a2;
                    display: inline-block;
                    height: 10px;
                    width: 10px;
                    position: relative;
                    top: 6px;
                }
                span {
                    color: #262626;
                    font-weight: 400;
                    margin: 0 0 0 5px;
                    span.star {
                        color: red;
                    }
                }
            }
        }
    }
    .Place-order {
        a {
            background-color: #262626;
            display: block;
            cursor: pointer;
            padding: 22px 50px 21px;
            width: 100%;
            color: #fff;
            text-align: center;
            &:hover {
                background-color: $theme-color--default;
            }
        }
    }
}

.order-tracking-form-box {
    input{
        border: 1px solid #e8e8e8;
        height: 50px;
        background-color: transparent;
        padding: 2px 20px;
        color: #777;
        width: 100%;
        margin-top: 10px;
        &:focus {
            border: 1px solid #262626;
        }
        &.billing-address {
            margin-bottom: 20px;
        }
    }
}

