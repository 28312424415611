
/*======================================
=        08. Featuted Product Css
=======================================*/

.featuted-product-one {
     & .product-thumbnail {
          a {
               display: block;
          }
          @media #{$tablet-device,$large-mobile} {
               margin-bottom: 30px;
          }
     }
     .featured-product-contect {
          .right-cont-featured-text {
               margin-left: -30%;
               @media #{$large-mobile}{
                    margin-left: 0%;
               }
          }
          .left-cont-featured-text {
               margin-right: -30%;
               @media #{$large-mobile}{
                    margin-right: 0%;
               }
          }
     }
}
