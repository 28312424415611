/*======================================
=         11. Newsletter Css            =
=======================================*/
.newsletter {
     &--one {
          position: relative;
          .input-box {
               border: none;
               background: #f4f5f7;
               width: 100%;
               height: 54px;
               padding: 10px 170px 10px 20px;
          }
          .submit-btn {
               position: absolute;
               top: 0;
               right: 0px;
               font-size: 16px;
               opacity: 1;
               padding: 0 40px;
               border: none;
               height: 54px;
               background: $black;
               color: $white;
          }
     }
     &--two {
          position: relative;
          .input-box {
               border: none;
               background: #ddd;
               width: 100%;
               height: 54px;
               padding: 10px 140px 10px 20px;
          }
          .submit-btn {
               position: absolute;
               top: 0;
               right: 0px;
               font-size: 16px;
               opacity: 1;
               padding: 0 30px;
               border: none;
               height: 54px;
               background: $black;
               color: $white;
               font-size: 12px;
          }
     }
     &--three {
          position: relative;
          max-width: 400px;
          margin: auto;
          .input-box {
               border: none;
               background: #fff;
               width: 100%;
               height: 54px;
               padding: 10px 140px 10px 20px;
               border-radius: 35px;
          }
          .submit-btn {
               position: absolute;
               top: 0;
               right: 0px;
               font-size: 16px;
               opacity: 1;
               padding: 0 20px;
               border: none;
               height: 54px;
               background: $black;
               color: $white;
               font-size: 12px;
               border-radius: 0 35px 35px 0;
          }
     }
}


.social-media-link {
     .style-default {
          li {
               display: inline-block;
               margin: 10px;
               &:first-child {
                    margin-left: 0;
               }
          }
     }
}