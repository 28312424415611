/*======================================
=           13. Testimonial Css           =       
========================================*/

.testimonial-quote {
     i {
          font-size: 20px;
     }
}

.single-testimonial {
     &-item {
          max-width: 800px;
          margin: auto;
          
          .testimonial-image {
               width: 90px;
               height: 90px;
               margin: auto;
               & img {
                    border-radius: 50%;
                    border: 1px solid #333;
               }
               &--two {
                   width: 40px;
                    height: 40px;
                    margin: auto; 
                    text-align: center;
               }
          }
          .testimonial_item--info {
               .testimonial-excerpt {
                    padding-top: 10px;
                    margin: 0 auto;
                    font-size: 36px;
                    line-height: 1.34;
                    color: $black;
                    @media #{$large-mobile} {
                         font-size: 26px;
                    }
               }
               .testimonial_author {
                    .testimonial-title {
                         display: inline-block;
                         font-size: 20px;
                         font-weight: 700;
                         margin-right: 10px;
                    }
                    .testimonial_client {
                         font-style: italic;
                         display: inline-block;
                         font-family: $font-family-two;
                    }
               }
               &--two {
                    .testimonial-excerpt {
                         padding-top: 10px;
                         margin: 0 auto;
                         font-size: 36px;
                         line-height: 1.34;
                         color: $theme-color--default;
                         @media #{$large-mobile} {
                              font-size: 26px;
                         }
                    }
                    .testimonial_author {
                         .testimonial-title {
                              display: inline-block;
                              font-size: 12px;
                              font-weight: 700;
                              margin-right: 10px;
                              margin-top: 20px;
                              text-transform: uppercase;
                              letter-spacing: 4px;
                              color: #fff;
                         }
                    }
               }
          }
     }
     &-bakery {
          max-width: 800px;
          margin: auto;
          .testimonial_item--info {
               .testimonial-excerpt {
                    padding-top: 10px;
                    margin: 0 auto;
                    font-size: 36px;
                    line-height: 1.34;
                    color: $white;
               }
               .testimonial_author {
                    .testimonial_client {
                         text-transform: uppercase;
                         color: $white;
                         display: block;
                    }
               }
          }
     }
}
.testimonial-image {
     &--two {
          width: 40px;
          height: 40px;
          margin: auto; 
          text-align: center;
     }
     &--three {
          width: auto;
          height: auto;
          margin: auto; 
          text-align: center;
          display: block;
     }
}
.testimonial-slider-active {
     .slick-dots {
          text-align: center;
          
          li {
               display: inline-block;
               margin: 5px;
               transition: all 0.3s ease-in-out;
               button {
                    font-size: 0px;
                    border: none;
                    background: #999;
                    height: 8px;
                    width: 8px;
                    padding: 0;
                    border-radius: 5000px;
                    transition: all 0.3s ease-in-out;
               }
               &.slick-active {
                    button {
                         background: #000;
                         height: 9px;
                         width: 9px;
                    }
               }
          }
     }
     
}

.ht-testimonial {
     &--four {
          background: #fff none repeat scroll 0 0;
          margin-top: 53px;
          position: relative;
          text-align: center;
          margin: 10px;

          .ht-testimonial-thumb {
               margin-top: 52px;
               img {
                    border-radius: 50%;
                    box-sizing: content-box;
                    left: 50%;
                    max-width: 100px;
                    position: absolute;
                    top: 0;
                    transform: translate(-50%, -50%);
               }
          }
          .ht-testimonial-content {
               margin-top: 0;
               padding: 60px 30px 30px 30px;
               .ht-testimonial-text {
                    margin-bottom: 20px;
                    p {
                         font-size: 14px;
                         line-height: 1.75;
                         margin: 0;
                         color: #000;
                    }
               }

          }
          .ht-testimonial-info {
               position: relative;
               &::before {
                    content: "";
                    width: 25px;
                    display: block;
                    height: 3px;
                    margin: 25px auto 15px;
                    background-image: linear-gradient(90deg,#d1ecff 0%,#efcdcd 100%);
               }
          }
          .ht-testimonial-position {
               font-size: 14px;
          }
     }
     
     

}
.testimonial-gradient-bg {
    background: linear-gradient(left, rgb(255, 238, 187) 0%, rgb(149, 255, 227) 98%);
}