/*============================
    CSS Index         
==============================

    01. Default Css
        - Breadvrumb 
        - Preloader   
        - Scroll top 
    02. Section Spacing Css
    03. container Css 
    04. Header Css  
        - Sticky Css 
        - logo
        - Navigation Menu Css
        - Multilevel Submenu Css
        - Mega Menu Css
        - Offcanvas Minicart Css
        - Offcanvas Menu Css  
        - Offcanvas Search Css  
        - Mobile menu css
    05. Footer Css
    06. Sidebar Css
07. Button Css
08. Featuted Product Css
09. Product Css
10. Product Support 
11. Newsletter Css
12. Banner Css
13. Testimonial Css 
14. Brand Css 
15. Countdown Css
16. Choose Css
17. Team Css
18. Fun Factor Css   
19. Bakery Item Css 
20. Accordions Css  
21. Counterup Css 
22. Video Bnanre Css  
23. Hero Slider Css 
24. Product Details Css 
25. Blog Css  
26. About us Css
27. Shop style Css
28. My account Page Css
29. Cart & Checkout Pages Css
30. Contact Us Pages Css
31. Compare Page Css
32. Preview Page Css

/*=====  End of CSS Index  ======*/
/*=============================================
=             01. Default Css                 =
=============================================*/
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,40&family=Mr+Dafoe&family=Roboto+Condensed:wght@300;400;700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@font-face {
  font-family: "LucidaGrande";
  src: url(../fonts/LucidaGrande.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "LucidaGrande";
  src: url(../fonts/LucidaGrandeBold.ttf);
  font-weight: 700;
  font-style: normal;
}

*, *::after, *::before {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  line-height: 1.74;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  visibility: visible;
  font-family: "LucidaGrande";
  color: #696969;
  position: relative;
  background-color: #ffffff;
}

body.no-overflow {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  color: #333333;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.41;
}

h1 {
  font-size: 56px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h1 {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 34px;
  }
}

h2 {
  font-size: 48px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h2 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 30px;
  }
}

h3 {
  font-size: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h3 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h3 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 25px;
  }
}

h4 {
  font-size: 34px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h4 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h4 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  h4 {
    font-size: 24px;
  }
}

h5 {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  h5 {
    font-size: 20px;
  }
}

h6 {
  font-size: 18px;
}

p:last-child {
  margin-bottom: 0;
}

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a, button, img, input, span {
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

.btn.focus, .btn:focus {
  outline: none;
  box-shadow: none;
}

a:hover {
  text-decoration: none;
  color: #d3b48c;
}

button, input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.mark, mark {
  padding: 0 0;
  background-color: transparent;
}

.font-weight--xl-bold {
  font-weight: 800;
}

.font-weight--bold {
  font-weight: 600;
}

.font-weight--reguler {
  font-weight: 500;
}

.font-weight--normal {
  font-weight: 400;
}

.font-weight--light {
  font-weight: 300;
}

/*--
    Default Class
-- */
.text-color-primary {
  color: #d3b48c;
}

.text-color-secondary {
  color: #d2a98e;
}

.text-black {
  color: #333333 !important;
}

.bg-white {
  background: #ffffff;
}

.bg-black {
  background: #000000;
}

.bg-gray {
  background: #F8F8F8;
}

.bg-gray-2 {
  background: #F6FAFE;
}

.bg-gray-3 {
  background: #f6f2ed;
}

.section-bg-gray {
  background: #f4f4f4;
}

.bg-theme-default {
  background: #d3b48c;
}

.theme-bg-secondary {
  background: #d2a98e;
}

.sub-heading {
  color: #6d70a6;
}

.black-bg {
  background: #000000;
}

.border-radus-5 {
  border-radius: 5px;
}

.text-green {
  color: #d2a98e;
}

.bg-gradient {
  background: linear-gradient(90deg, #4ddfbd 0%, #9ee347 100%);
}

.section-bg-gradient-two {
  background: linear-gradient(left, #ffebcf 0%, #c0fff1 100%);
}

.font-family-two {
  font-family: "Playfair Display", serif;
}

.font-family-four {
  font-family: "Mr Dafoe", cursive;
}

.font-family-roboto {
  font-family: "Roboto Condensed", sans-serif;
}

.font-family-montserrat {
  font-family: "Montserrat", sans-serif;
}

.ls-1 {
  letter-spacing: 1px;
}

.ls-2 {
  letter-spacing: 2px;
}

.ls-3 {
  letter-spacing: 3px;
}

.ls-4 {
  letter-spacing: 4px;
}

.ls-5 {
  letter-spacing: 5px;
}

.ls-6 {
  letter-spacing: 6px;
}

.ls-7 {
  letter-spacing: 7px;
}

.font-italic {
  font-style: italic;
}

.text-small {
  font-size: 12px;
}

/*-- Tab Content & Pane Fix --*/
.tab-content .tab-pane {
  display: block;
  overflow: hidden;
  height: 0;
  visibility: hidden;
  max-width: 100%;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

select {
  padding: 3px 20px;
  height: 56px;
  max-width: 100%;
  width: 100%;
  outline: none;
  border: 1px solid #f8f8f8;
  border-radius: 5px;
  background: #f8f8f8 url("../images/icons/selector-icon.png") no-repeat center right 20px;
  background-color: #f8f8f8;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select:focus {
  background: #f8f8f8 url("../images/icons/selector-icon.png") no-repeat center right 20px !important;
}

.fixed-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/*------- Defauld Class --------*/
.text-black {
  color: #333;
}

.box-shadow-top {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
}

.border {
  border: 1px solid #ededed !important;
}

.border-top {
  border-top: 1px solid #ededed !important;
}

.border-right {
  border-right: 1px solid #ededed !important;
}

.border-bottom {
  border-bottom: 1px solid #ededed !important;
}

.border-left {
  border-left: 1px solid #ededed !important;
}

.border-top-dash {
  border-top: 1px dashed #ddd !important;
}

.border-bottom-dash {
  border-bottom: 1px dashed #ddd !important;
}

.border-top-thick {
  border-top: 2px solid #ededed !important;
}

.border-bottom-thick {
  border-bottom: 2px solid #ededed !important;
}

.border-top-drak {
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.border-bottom-drak {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.img-width {
  width: 100%;
}

.title-dec-text {
  text-align: center;
  max-width: 760px;
  margin: auto;
}

::selection {
  color: #ffffff;
  background-color: #d3b48c;
}

form {
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
}

form input:focus::-webkit-input-placeholder {
  color: transparent;
}

form input:focus:-moz-placeholder {
  color: transparent;
}

form input:focus::-moz-placeholder {
  color: transparent;
}

form input:focus:-ms-input-placeholder {
  color: transparent;
}

form input::placeholder, form textarea::placeholder {
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="number"]:focus, input[type="tel"]:focus, input[type="range"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="time"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="color"]:focus, textarea:focus, select:focus, select:focus, textarea:focus {
  color: #d3b48c;
  border-color: #d3b48c;
}

input[type="checkbox"] {
  position: relative;
  background: 0 0;
  border-width: 0;
  box-shadow: none;
  margin: 0 10px 0 3px;
  cursor: pointer;
}

.section-title {
  position: relative;
  position: relative;
}

.section-title--one {
  position: relative;
  padding-bottom: 20px;
}

.section-title--one::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: #d3b48c;
  height: 1px;
  width: 60px;
}

.section-title--center {
  position: relative;
}

.section-title--center::after {
  position: absolute;
  content: "";
  left: 50%;
  bottom: 0;
  background: #d3b48c;
  height: 1px;
  width: 60px;
  transform: translateX(-50%);
}

.section-title--gradient {
  position: relative;
  padding-bottom: 20px;
}

.section-title--gradient::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 1px;
  width: 60px;
  background-image: linear-gradient(90deg, #4ddfbd 0%, #9ee347 100%);
}

.section-title--gradient-center {
  position: relative;
  padding-bottom: 15px;
}

.section-title--gradient-center::after {
  position: absolute;
  content: "";
  left: 50%;
  bottom: 0;
  background-image: linear-gradient(90deg, #4ddfbd 0%, #9ee347 100%);
  height: 3px;
  width: 80px;
  transform: translateX(-50%);
}

.section-title--three .ht-separator--black {
  position: relative;
  border-bottom: 1px solid #c4c4c4;
  display: inline-block;
  width: 160px;
  margin-left: auto;
  margin-right: auto;
}

.section-title--three .ht-separator--black::after {
  background-color: #d3b48c;
  content: "";
  height: 5px;
  width: 5px;
  position: absolute;
  left: 50%;
  border-radius: 50%;
  top: -2px;
  z-index: 2;
  margin-left: -2px;
}

.section-title--three .ht-separator--black::before {
  content: "";
  background-color: #fff;
  width: 40px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  z-index: 1;
  height: 8px;
  top: -4px;
}

.section-title--three .ht-separator--white {
  position: relative;
  border-bottom: 1px solid #333;
  display: inline-block;
  width: 160px;
  margin-left: auto;
  margin-right: auto;
}

.section-title--three .ht-separator--white::after {
  background-color: #333;
  content: "";
  height: 5px;
  width: 5px;
  position: absolute;
  left: 50%;
  border-radius: 50%;
  top: -2px;
  z-index: 2;
  margin-left: -2px;
}

.section-title--three .ht-separator--white::before {
  content: "";
  background-color: #fbf2eb;
  width: 40px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  z-index: 1;
  height: 8px;
  top: -4px;
}

.section-title .p-dec {
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
}

.section-title--bakery .title {
  font-size: 72px;
  color: #432917;
}

.section-sub-title {
  font-size: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 1.43;
  margin-top: -5px;
  font-weight: 600;
}

.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax .jarallax-img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.section-radius-before {
  position: relative;
}

.section-radius-before::before {
  content: "";
  background-image: url(../images/radius/bg-white-radius.png);
  background-position: center top;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/*===================================
    - Breadvrumb  
=================================*/
.breadcrumb-area {
  background-color: #f3e9df;
  background-repeat: no-repeat;
  background-position: center center;
  border-bottom-width: 0px;
  padding-top: 150px;
  padding-bottom: 80px;
  background-size: cover;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area {
    padding-top: 120px;
    padding-bottom: 80px;
  }
}

.breadcrumb-list {
  margin-top: 10px;
}

.breadcrumb-list li {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.58;
  text-transform: uppercase;
}

.breadcrumb-list li::before {
  display: inline-block;
  padding-right: 6px !important;
  color: #6c757d;
  content: "/";
}

.breadcrumb-list li:first-child::before {
  display: none;
}

.breadcrumb-list li a {
  position: relative;
}

.breadcrumb-list li a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
  z-index: 0;
}

.breadcrumb-list li.active {
  color: #36393e;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .1rem;
}

.page-pagination li {
  display: inline-block;
  margin: 8px;
}

.page-pagination li a {
  font-weight: 500;
  padding: 0 0px;
  display: block;
  text-align: center;
  line-height: 21px;
  text-transform: uppercase;
  color: #555;
}

.page-pagination li a.current {
  color: #000;
  border-bottom: 2px solid #000;
}

.page-pagination li a:hover {
  color: #d3b48c;
}

/*=============================================
=                - Preloader                =
=============================================*/
.preloader-active {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
}

.preloader-active .preloader-area-wrap {
  background-color: #ffffff;
  position: absolute;
  left: 0;
  display: block;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999999;
}

.preloader-active .preloader-area-wrap .spinner div {
  background-color: #d3b48c;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.preloader-active .preloader-area-wrap .spinner div.bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.preloader-active .preloader-area-wrap .spinner div.bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.preloader-area-wrap {
  display: none;
}

.open_tm_preloader {
  position: fixed;
  background-color: transparent;
  z-index: 9999;
  height: 100%;
  width: 100%;
  -webkit-transition: .2s all ease;
  -o-transition: .2s all ease;
  transition: .2s all ease;
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

.open_tm_preloader.loaded {
  opacity: 0;
  visibility: hidden;
}

.open_tm_preloader.loaded:before, .open_tm_preloader.loaded:after {
  width: 0%;
}

.open_tm_preloader:before, .open_tm_preloader:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
}

.open_tm_preloader:before {
  top: 0;
  left: 0;
}

.open_tm_preloader:after {
  bottom: 0;
  right: 0;
}

/*=============================================
   - Scroll top         
=============================================*/
.scroll-top {
  position: fixed;
  right: 30px;
  bottom: -60px;
  z-index: 999;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
  display: block;
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  background-color: #d3b48c;
  background-size: 200% auto;
  background-position: left center;
  color: #ffffff;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden;
}

@media only screen and (max-width: 479px) {
  .scroll-top {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}

.scroll-top.show {
  visibility: visible;
  opacity: 1;
  bottom: 60px;
}

.scroll-top i {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.scroll-top .arrow-top {
  transform: translate(-50%, -50%);
}

.scroll-top .arrow-bottom {
  transform: translate(-50%, 80px);
}

.scroll-top:hover {
  background-position: right center;
}

.scroll-top:hover .arrow-top {
  transform: translate(-50%, -80px);
}

.scroll-top:hover .arrow-bottom {
  transform: translate(-50%, -50%);
}

/*=====  End of Default CSS  ======*/
/*============================================
=           02. Section Spacing Css          =
=============================================*/
.section-space {
  /* Section Padding Css */
  /* Section Margin Css */
}

.section-space--ptb_120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_120 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_120 {
  padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_120 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_120 {
    padding-top: 60px;
  }
}

.section-space--pb_120 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_120 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_120 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_100 {
  padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_100 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_100 {
    padding-top: 60px;
  }
}

.section-space--pb_100 {
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_100 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_100 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_90 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_90 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section-space--pt_90 {
  padding-top: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_90 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_90 {
    padding-top: 40px;
  }
}

.section-space--pb_90 {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_90 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_90 {
    padding-bottom: 40px;
  }
}

.section-space--ptb_80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_80 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_80 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section-space--pt_80 {
  padding-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_80 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_80 {
    padding-top: 40px;
  }
}

.section-space--pb_80 {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_80 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_80 {
    padding-bottom: 40px;
  }
}

.section-space--ptb_70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_70 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_70 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.section-space--pt_70 {
  padding-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_70 {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_70 {
    padding-top: 30px;
  }
}

.section-space--pb_70 {
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_70 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_70 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_60 {
  padding-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

.section-space--pb_60 {
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

.section-space--pt_40 {
  padding-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

.section-space--pb_40 {
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.section-space--pt_30 {
  padding-top: 30px;
}

.section-space--pb_30 {
  padding-bottom: 30px;
}

.section-space--mt_15 {
  margin-top: 15px;
}

.section-space--mt_20 {
  margin-top: 20px;
}

.section-space--mt_30 {
  margin-top: 30px;
}

.section-space--mt_40 {
  margin-top: 40px;
}

.section-space--mt_50 {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_50 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_50 {
    margin-top: 30px;
  }
}

.section-space--mt_60 {
  margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_60 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_60 {
    margin-top: 30px;
  }
}

.section-space--mt_70 {
  margin-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_70 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_70 {
    margin-top: 30px;
  }
}

.section-space--mt_80 {
  margin-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_80 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_80 {
    margin-top: 30px;
  }
}

.section-space--mt_100 {
  margin-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_100 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_100 {
    margin-top: 60px;
  }
}

.section-space--mt_120 {
  margin-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_120 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_120 {
    margin-top: 60px;
  }
}

.section-space--mb_15 {
  margin-bottom: 15px;
}

.section-space--mb_20 {
  margin-bottom: 20px;
}

.section-space--mb_30 {
  margin-bottom: 30px;
}

.section-space--mb_40 {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .section-space--mb_40 {
    margin-bottom: 30px;
  }
}

.section-space--mb_50 {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_50 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_50 {
    margin-bottom: 30px;
  }
}

.section-space--mb_60 {
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_60 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_60 {
    margin-bottom: 30px;
  }
}

.section-space--mb_100 {
  margin-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_100 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_100 {
    margin-bottom: 60px;
  }
}

.section-space--mb_120 {
  margin-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_120 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_120 {
    margin-bottom: 60px;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .small-mt__0 {
    margin-top: 0px;
  }
  .small-mt__10 {
    margin-top: 10px;
  }
  .small-mt__20 {
    margin-top: 20px;
  }
  .small-mt__30 {
    margin-top: 30px;
  }
  .small-mt__40 {
    margin-top: 40px;
  }
  .small-mt__50 {
    margin-top: 50px;
  }
  .small-mt__60 {
    margin-top: 60px;
  }
  .small-mb__30 {
    margin-bottom: 30px;
  }
  .small-mb__40 {
    margin-bottom: 40px;
  }
  .small-mb__50 {
    margin-bottom: 50px;
  }
  .small-mb__60 {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tablet-mt__0 {
    margin-top: 0px;
  }
  .tablet-mt__30 {
    margin-top: 30px;
  }
  .tablet-mt__40 {
    margin-top: 40px;
  }
  .tablet-mt__50 {
    margin-top: 50px;
  }
  .tablet-mt__60 {
    margin-top: 60px;
  }
  .tablet-mb__30 {
    margin-bottom: 30px;
  }
  .tablet-mb__40 {
    margin-bottom: 40px;
  }
  .tablet-mb__50 {
    margin-bottom: 50px;
  }
  .tablet-mb__60 {
    margin-bottom: 60px;
  }
}

/*=====  End of spacing  ======*/
/*=============================================
=            03. container Css            =
=============================================*/
@media (min-width: 1600px) {
  .container-fluid--cp-150 {
    padding: 0 150px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-150 {
    padding: 0 110px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-150 {
    padding: 0 50px;
  }
}

@media (min-width: 1700px) {
  .container-fluid--cp-140 {
    padding: 0 140px !important;
  }
}

@media (min-width: 1700px) and (max-width: 1663px) {
  .container-fluid--cp-140 {
    padding: 0 100px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-140 {
    padding: 0 30px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-140 {
    padding: 0 30px;
  }
}

@media (min-width: 1600px) {
  .container-fluid--cp-120 {
    padding: 0 120px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-120 {
    padding: 0 110px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-120 {
    padding: 0 50px;
  }
}

@media (min-width: 1600px) {
  .container-fluid--cp-100 {
    padding: 0 100px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-100 {
    padding: 0 100px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-100 {
    padding: 0 50px;
  }
}

@media (min-width: 1600px) {
  .container-fluid--cp-80 {
    padding: 0 80px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-80 {
    padding: 0 30px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-80 {
    padding: 0 30px;
  }
}

.container-fluid--cp-60 {
  padding: 0 60px;
}

@media only screen and (max-width: 767px) {
  .container-fluid--cp-60 {
    padding: 0 15px;
  }
}

.container-fluid--cp-30 {
  padding: 0 30px;
}

@media only screen and (max-width: 767px) {
  .container-fluid--cp-30 {
    padding: 0 15px;
  }
}

@media (min-width: 1200px) {
  .page-content-double-sidebar .container {
    max-width: 1600px;
  }
}

@media (max-width: 1919px) {
  .container-fluid--cp-60 {
    padding: 0 30px;
  }
  .container-fluid--cp-80 {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 1919px) and (max-width: 767px) {
  .container-fluid--cp-80 {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 1919px) and (max-width: 575px) {
  .container-fluid--cp-80 {
    padding: 0 15px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.container_fl {
  padding-left: 15px;
  padding-right: 15px;
}

.col-06__left {
  width: 600px;
  max-width: 100%;
  float: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .col-06__left {
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}

.col-06__right {
  width: 600px;
  max-width: 100%;
  float: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .col-06__right {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

.banner-image__content {
  width: 570px;
  max-width: 100%;
}

.row--35 {
  margin-left: -35px;
  margin-right: -35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--35 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--35 > [class*="col"],
.row--35 > [class*="col-"] {
  padding-left: 35px;
  padding-right: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--30 {
  margin-left: -30px;
  margin-right: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--30 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--30 > [class*="col"],
.row--30 > [class*="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--20 {
  margin-left: -20px;
  margin-right: -20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--20 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--20 > [class*="col"],
.row--20 > [class*="col-"] {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--20 > [class*="col"],
  .row--20 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--20 > [class*="col"],
  .row--20 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--20 > [class*="col"],
  .row--20 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/*=====  End of container  ======*/
/*=============================================
=              04. Header Css               =
=============================================*/
.header-area .is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-sticky {
  z-index: 9999;
}

.header-sticky.scroll-down {
  transform: translate3d(0, -100%, 0);
}

.header-sticky.is-sticky {
  background-color: #fff;
  position: fixed;
}

.header-sticky .scroll-up {
  -o-transform: none;
  transform: none;
}

.header-area--black .header-sticky.is-sticky {
  background-color: #111;
  position: fixed;
}

.header-area--black .dl-menuwrapper .dl-trigger::after {
  background-color: #fff;
  box-shadow: 0 8px 0 #fff, 0 16px 0 #fff;
}

.header-area--black .header-icon {
  color: #ffffff;
}

.header-absolute {
  position: absolute;
  z-index: 1;
  width: 100%;
}

.header-height--120 {
  height: 120px;
  align-items: center;
  display: flex;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-height--120 {
    height: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .header-height--120 {
    height: 80px;
  }
}

.header {
  display: flex;
  align-items: center;
  /* - logo */
}

.header__logo {
  flex-basis: 15%;
  padding: 14px 0;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.header__logo.top-logo {
  padding: 25px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .header__logo {
    flex-basis: 15%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__logo {
    flex-basis: 15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__logo {
    flex-basis: 30%;
    padding: 20px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header__logo {
    flex-basis: 40%;
    padding: 20px 0;
  }
  .header__logo.top-logo {
    padding: 15px 0;
  }
}

.header__logo > a img {
  width: 160px;
}

.header__logo .dark-logo {
  display: none;
}

.header__logo .light-logo {
  display: inherit;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header__logo .dark-logo {
    display: inherit;
  }
  .header__logo .light-logo {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header__logo.mt-20 {
    margin-top: 0;
  }
  .header__logo.mb-20 {
    margin-bottom: 0;
  }
}

.header-right {
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.menu-header-center {
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.header-menu__right {
  display: flex;
  align-items: center;
  flex-basis: 15%;
  justify-content: flex-end;
}

.header-menu__right a {
  font-size: 14px;
  margin-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-menu__right {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .header-menu__right {
    margin-right: 50px;
  }
}

.header-menu__right .minicart-btn {
  position: relative;
}

.header-menu__right .minicart-btn .item-counter {
  background-color: #d3b48c;
  position: absolute;
  font-size: 10px;
  height: 15px;
  line-height: 15px;
  color: #fff;
  display: block;
  top: -10px;
  right: -10px;
  min-width: 15px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}

.header-menu__right_sport a:hover {
  color: #54dfb2;
}

.header-menu__right_sport .minicart-btn {
  position: relative;
}

.header-menu__right_sport .minicart-btn .item-counter {
  background-color: #54dfb2;
}

.header-menu__right_white a {
  color: #fff;
}

.header-menu__right_white a:hover {
  color: #fff;
}

.header-menu__right_white .minicart-btn {
  position: relative;
}

.header-menu__right_white .minicart-btn .item-counter {
  background-color: #d3b48c;
}

.header-menu__right.header-menu__sidebar {
  flex-basis: 100%;
  justify-content: flex-start;
}

.header-col__right {
  display: flex;
  align-items: center;
  color: #ffffff;
}

.header-col__right a {
  font-size: 30px;
  margin-left: 25px;
}

.header-col__right a.search-icon {
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  .header-col__right a {
    margin-left: 13px;
    font-size: 24px;
  }
}

.header-col__right .header-icon {
  font-size: 20px;
}

.minicart-count_area {
  position: relative;
}

span.cart-item_count {
  position: absolute;
  background: #333;
  color: #fff;
  font-size: 10px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  text-align: center;
  margin-left: -50%;
  transform: translateX(-50%);
}

.header-left-info {
  flex-basis: 45%;
}

.header-logo-center {
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header-logo-center {
    justify-content: flex-start;
  }
}

.header-logo-center .logo {
  padding: 30px 0;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header-logo-center .logo {
    padding: 20px 0;
  }
}

.header-right_info {
  flex-basis: 45%;
  justify-content: flex-end;
}

.header-info-text {
  display: flex;
}

.header-info-text .header-info-item {
  font-size: 12px;
  margin-right: 15px;
}

.header-info-text .header-info-item:last-child {
  margin-right: 0;
}

.header-info-text .header-info-item i {
  margin-right: 5px;
}

/*============================== 
    - Navigation Menu Css
===============================*/
.navigation-menu > ul {
  display: flex;
}

.navigation-menu > ul > li {
  margin: 0 20px;
  padding: 40px 0;
}

@media only screen and (min-width: 1500px) and (max-width: 1599px) {
  .navigation-menu > ul > li {
    margin: 0 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .navigation-menu > ul > li {
    margin: 0 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navigation-menu > ul > li {
    margin: 0 10px;
  }
}

.navigation-menu > ul > li > a {
  display: block;
  color: #000000;
  padding: 0px 0px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.18;
}

.navigation-menu > ul > li > a:before {
  content: '';
  width: 0;
  height: 2px;
  bottom: 0;
  position: absolute;
  left: 0;
  background-color: #d3b48c;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.navigation-menu > ul > li.has-children > a {
  position: relative;
}

.navigation-menu > ul > li.has-children--multilevel-submenu {
  position: relative;
}

.navigation-menu > ul > li.has-children:hover .megamenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.navigation-menu > ul > li.has-children:hover .megamenu--home-variation__item {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.navigation-menu > ul > li.has-children:hover > .submenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.navigation-menu > ul > li:hover > a:after, .navigation-menu > ul > li.active > a:after {
  color: #d3b48c;
}

.navigation-menu > ul > li:hover > a span, .navigation-menu > ul > li.active > a span {
  color: #d3b48c;
}

.navigation-menu > ul > li:hover > a:before, .navigation-menu > ul > li.active > a:before {
  width: 100%;
  left: 0;
}

.navigation-menu.nav-style-one > ul > li > a {
  color: #fff;
}

.navigation-menu.nav-style-one > ul > li:hover > a:after, .navigation-menu.nav-style-one > ul > li.active > a:after {
  color: #d3b48c;
}

.navigation-menu.nav-style-one > ul > li:hover > a span, .navigation-menu.nav-style-one > ul > li.active > a span {
  color: #d3b48c;
}

.navigation-menu.nav-text-white > ul > li > a {
  color: #fff;
}

.navigation-menu.nav-text-white > ul > li > a:before {
  background-color: #ffffff;
}

.navigation-menu.nav-text-white > ul > li:hover > a:after, .navigation-menu.nav-text-white > ul > li.active > a:after {
  color: #ffffff;
}

.navigation-menu.nav-text-white > ul > li:hover > a span, .navigation-menu.nav-text-white > ul > li.active > a span {
  color: #ffffff;
}

.navigation-menu.nav-sport > ul > li > a {
  color: #333;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Roboto Condensed", sans-serif;
}

.navigation-menu.nav-sport > ul > li > a:before {
  background-color: #54dfb2;
}

.navigation-menu.nav-sport > ul > li:hover > a:after, .navigation-menu.nav-sport > ul > li.active > a:after {
  color: #54dfb2;
}

.navigation-menu.nav-sport > ul > li:hover > a span, .navigation-menu.nav-sport > ul > li.active > a span {
  color: #54dfb2;
}

.navigation-menu.left-sidebar-menu > ul {
  flex-direction: column;
}

.navigation-menu.left-sidebar-menu > ul > li {
  padding: 20px 0;
  margin: 5px 0;
  padding-top: 0;
  position: relative;
}

.navigation-menu.left-sidebar-menu > ul > li > a {
  font-family: "Playfair Display", serif;
}

.navigation-menu.left-sidebar-menu > ul > li > a:before {
  width: 0%;
}

.navigation-menu.left-sidebar-menu > ul > li > .megamenu--mega {
  left: 100%;
  position: absolute;
  top: -100%;
  width: 1000px;
}

.navigation-menu.left-sidebar-menu > ul > li .submenu {
  left: 100%;
  top: -50%;
}

.navigation-menu.left-sidebar-menu > ul > li.active > a:before {
  width: 0%;
}

/*===================================
    - Multilevel Submenu Css
====================================*/
.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  transform: translateY(50px);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.2s;
  transition-duration: 0.4s;
  visibility: hidden;
  opacity: 0;
  min-width: 240px;
  padding: 20px 0;
  z-index: 9;
  border-bottom: 2px solid #d3b48c;
}

.submenu > li {
  position: relative;
}

.submenu > li > a {
  display: block;
  padding: 5px 30px;
  color: #444;
  font-weight: 400;
  transition: 0s;
  font-size: 14px;
}

.submenu > li > a > span {
  position: relative;
}

.submenu > li > a > span:after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  background-color: #d3b48c;
  transition: 0.3s;
}

.submenu > li > a:hover {
  color: #d3b48c;
}

.submenu > li > a:hover > span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.submenu > li:hover > .submenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  z-index: 9;
}

.submenu > li.active > a {
  color: #d3b48c;
}

.submenu > li.has-children > a {
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.submenu > li.has-children > a:after {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  content: "\ea12";
  font-size: 12px;
  vertical-align: middle;
  color: #ababab;
  font-family: 'LaStudioIcons';
}

.submenu > li.has-children > a:hover:after {
  color: #d3b48c;
}

.submenu > li.has-children.active > a {
  color: #d3b48c;
}

.submenu .submenu {
  top: 0px;
  left: 100%;
  right: auto;
}

.submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

/*=========================================
    - Mega Menu Css
===========================================*/
.megamenu {
  position: absolute;
  top: 100%;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  transform: translateY(50px);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.2s;
  transition-duration: 0.4s;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
  border-bottom: 2px solid #d3b48c;
}

.megamenu--mega {
  width: 1170px;
  padding: 40px 30px;
  display: flex;
  justify-content: space-around;
  left: auto;
  right: 200px;
  z-index: 9;
  border-bottom: 2px solid #d3b48c;
}

.megamenu--mega__03 {
  width: 970px;
}

.megamenu--mega__04 {
  width: 100%;
  right: 0;
  background-image: url(../images/bg/menu-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  max-width: none;
  background-color: #fff;
  border-bottom: 2px solid #d3b48c;
  padding-bottom: 20px;
}

.megamenu--mega__04 > .center-menu {
  display: flex;
  justify-content: flex-start;
  min-width: 600px;
}

.megamenu--mega__04 > .center-menu > li {
  flex-basis: 40%;
  padding-left: 15px;
  padding-right: 15px;
}

.megamenu--mega__04 > .center-menu > li .page-list-title {
  font-size: 12px;
  margin-bottom: 20px;
  color: #333333;
  font-weight: 600;
}

.megamenu--mega__04 > .center-menu > li > ul > li > a {
  padding: 10px 0;
  color: #333;
  line-height: 1.2;
  transition: 0.1s;
  font-size: 14px;
}

.megamenu--mega__04 > .center-menu > li > ul > li > a:hover {
  color: #d3b48c;
}

.megamenu--mega__04 > .center-menu > li > ul > li > a:hover span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.megamenu--mega__04 > .center-menu > li > ul > li > a > span {
  position: relative;
}

.megamenu--mega__04 > .center-menu > li > ul > li > a > span:after {
  content: '';
  width: 0;
  height: 0.8px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  background-color: #d3b48c;
  transition: 0.3s;
}

.megamenu--mega__04 > .center-menu > li > ul > li.active > a {
  color: #d3b48c;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .megamenu--mega {
    width: 970px;
  }
  .megamenu--mega__04 {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .megamenu--mega {
    width: 100%;
    left: 0;
    right: 0;
  }
}

.megamenu--mega > li {
  flex-basis: 22%;
  padding-left: 15px;
  padding-right: 15px;
}

.megamenu--mega > li .page-list-title {
  font-size: 12px;
  margin-bottom: 20px;
  color: #333333;
  font-weight: 600;
}

.megamenu--mega > li > ul > li > a {
  padding: 10px 0;
  color: #333;
  line-height: 1.2;
  transition: 0.1s;
  font-size: 14px;
}

.megamenu--mega > li > ul > li > a:hover {
  color: #d3b48c;
}

.megamenu--mega > li > ul > li > a:hover span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.megamenu--mega > li > ul > li > a > span {
  position: relative;
}

.megamenu--mega > li > ul > li > a > span:after {
  content: '';
  width: 0;
  height: 0.8px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  background-color: #d3b48c;
  transition: 0.3s;
}

.megamenu--mega > li > ul > li.active > a {
  color: #d3b48c;
}

/*======================================
=       - Offcanvas Minicart Css   =
=======================================*/
.offcanvas-minicart_wrapper .offcanvas-menu-inner {
  padding: 60px;
  width: 450px;
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .offcanvas-minicart_wrapper .offcanvas-menu-inner {
    width: 100%;
    padding: 53px 50px 45px;
  }
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .close-btn-box {
  text-align: left;
  margin-bottom: 30px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .remove-button-box {
  width: 100%;
  text-align: right;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .btn-close {
  background: transparent;
  color: #333;
  top: 0;
  right: 0;
  left: auto;
  text-align: right;
  font-size: 26px;
  display: inline-block;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .btn-close:hover {
  color: #d3b48c;
  transform: rotate(90deg);
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-heading {
  margin-bottom: 20px;
  margin-top: 20px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-heading .title {
  font-weight: 600;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list {
  max-height: 300px;
  position: relative;
  overflow: auto;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li {
  padding-bottom: 20px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li:last-child {
  padding-bottom: 0;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product:last-child {
  border: none;
  margin-bottom: 0px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_img img {
  width: 80px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product > a.product-item_remove > i {
  position: absolute;
  font-size: 14px;
  top: auto;
  right: 15px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content {
  flex-basis: calc(100% - 70px);
  max-width: calc(100% - 70px);
  padding-left: 20px;
  padding-right: 10px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content a.product-item_title {
  color: #000000;
  line-height: 1.4;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
  width: 90%;
  display: block;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content a.product-item_title:hover {
  color: #333;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content label {
  font-weight: 500;
  display: block;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 15px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content label span {
  color: #666;
  padding-left: 10px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content .product-item_quantity {
  display: block;
  font-size: 13px;
  color: #333;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-item_total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 0;
  font-size: 16px;
  font-weight: 500;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area {
  padding-bottom: 15px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area .hiraola-btn {
  height: 60px;
  line-height: 60px;
  color: #ffffff !important;
}

.offcanvas-minicart_wrapper.open .offcanvas-menu-inner {
  visibility: visible;
  left: auto;
  right: 0;
  opacity: 1;
  z-index: 999;
}

/* ---Offcanvas--- */
.offcanvas-minicart_wrapper .offcanvas-menu-inner {
  background-color: #fff;
  position: fixed;
  top: 0;
  right: -440px;
  width: 440px;
  height: 100%;
  z-index: 9999;
  visibility: hidden;
  overflow-y: auto;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .offcanvas-inner_logo {
  padding: 30px 0;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .offcanvas-minicart_wrapper .offcanvas-menu-inner {
    width: 300px;
    padding: 60px 20px 0 !important;
  }
}

.global-overlay {
  background-color: rgba(33, 30, 28, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  visibility: hidden;
}

.global-overlay.overlay-open {
  visibility: visible;
  opacity: 1;
}

/*========================================
=	    - Offcanvas Menu Css        =
==========================================*/
.offcanvas-menu_wrapper .offcanvas-menu-inner {
  padding: 60px 60px 0;
  position: fixed;
  top: 0;
  right: -285px;
  width: 470px;
  height: 100%;
  background: #fff;
  z-index: 9999;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  text-align: left;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .offcanvas-menu_wrapper .offcanvas-menu-inner {
    width: 300px;
    padding: 60px 20px 0;
  }
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .remove-button-box {
  width: 100%;
  text-align: right;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .btn-close {
  top: 45px;
  left: 25px;
  width: auto;
  line-height: 1;
  height: auto;
  color: #000000;
  font-size: 30px;
  display: block;
  background-color: transparent;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .btn-close:hover {
  background: transparent;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_banner {
  padding-top: 40px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_banner .inner-img {
  position: relative;
  cursor: pointer;
  width: 100%;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_banner .inner-img a {
  display: block;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_banner .inner-img img {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .social-list li {
  display: inline-block;
  margin-right: 10px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-info-list {
  margin-top: 30px;
  margin-bottom: 30px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-info-list li {
  margin-bottom: 10px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-info-list li i {
  margin-right: 5px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-copyright {
  margin-bottom: 40px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-copyright span {
  font-size: 12px;
}

.offcanvas-menu_wrapper.open .offcanvas-menu-inner {
  left: auto;
  right: 0;
  opacity: 1;
  visibility: visible;
}

/*========================================
    - Offcanvas Search Css  
======================================== */
.offcanvas-search_wrapper .offcanvas-menu-inner {
  background-color: rgba(255, 255, 255, 0.9);
  opacity: 0.97;
  width: 100%;
  right: 0;
  transform: all 0.4s ease-in-out;
  transform: scale(0);
  display: flex;
  align-items: center;
  visibility: visible;
  opacity: 0;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .btn-close {
  background: transparent;
  color: #ffffff;
  top: 100%;
  right: 30px;
  left: auto;
  font-size: 30px;
  color: #000000;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .btn-close:hover i {
  color: #d3b48c;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  transform: scale(0.5);
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search > span.searchbox-info {
  color: #000000;
  font-size: 20px;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox {
  position: relative;
  transform-origin: top bottom;
  width: calc(100% - 10%);
  margin-top: 20px;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #000000;
  height: 76px;
  line-height: 100px;
  width: 100%;
  padding: 0;
  color: #000000;
  transform-origin: top bottom;
  text-align: center;
  font-size: 30px;
  padding-right: 40px;
}

@media only screen and (max-width: 575px) {
  .offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > input {
    font-size: 16px;
    height: 60px;
    line-height: 60px;
  }
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > input::placeholder {
  color: #000000;
  text-align: center;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > .search_btn {
  position: absolute;
  font-size: 30px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  border: 0;
  background: transparent;
  color: #000000;
}

@media only screen and (max-width: 575px) {
  .offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > .search_btn {
    font-size: 16px;
  }
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > .search_btn:hover > i {
  color: #d3b48c;
}

.offcanvas-search_wrapper.open .offcanvas-menu-inner {
  left: auto;
  right: 0;
  padding: 0;
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

.offcanvas-search_wrapper.open .offcanvas-menu-inner .offcanvas-search {
  transform: scale(1);
}

.offcanvas-search_wrapper {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
}

.offcanvas-search_wrapper a.btn-close {
  left: 0;
  width: 100%;
  text-align: right;
  margin-bottom: 80px;
}

.offcanvas-search_wrapper .offcanvas-menu-inner {
  width: 100%;
  height: 100%;
}

.offcanvas-search_wrapper.open {
  opacity: 1;
  visibility: visible;
}

.overlay.open-canvas, .overlay.open-cart {
  opacity: 1;
  visibility: visible;
  z-index: 99;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 35, 36, 0.7);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all .3s linear;
}

.dl-menuwrapper button:hover, .dl-menuwrapper button.dl-active, .dl-menuwrapper ul {
  background: transparent;
}

.dl-menuwrapper .dl-trigger {
  float: right;
  background: 0 0;
  width: 28px;
  height: 28px;
  margin-right: 15px;
  margin-top: -45px;
}

.dl-menuwrapper .dl-trigger::after {
  width: 80%;
  height: 2px;
  top: 0;
  left: 0;
  background-color: #232324;
  box-shadow: 0 8px 0 #232324, 0 16px 0 #232324;
}

.dl-menuwrapper button:after {
  content: '';
  position: absolute;
  width: 68%;
  height: 5px;
  background: #fff;
  top: 10px;
  left: 16%;
  box-shadow: 0 8px 0 #fff, 0 18px 0 #fff;
}

.dl-menuwrapper li a {
  font-size: 14px;
  background-color: #fff !important;
  color: #232324;
  padding: 12px 30px;
  text-transform: uppercase;
  border-top: 1px solid rgba(35, 35, 36, 0.15);
  -webkit-transition: all .3s linear;
  -moz-transition: all .3s linear;
  -ms-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
}

.dl-menuwrapper {
  float: none;
  margin-right: 0;
  position: relative;
  max-width: inherit;
  z-index: 999;
}

.dl-menuwrapper .dl-menu {
  margin-top: 0;
  top: 100%;
}

.dl-menuwrapper li .dl-submenu .dl-back::after {
  left: 30px;
  color: #232324;
}

.dl-menuwrapper li .dl-submenu .dl-back {
  text-align: right;
}

.dl-menuwrapper li a::after {
  color: #232324;
  font-size: 14px;
  right: 30px;
  line-height: 44px;
}

.header-white-content .dl-menuwrapper .dl-trigger::after {
  background-color: #fff;
  box-shadow: 0 8px 0 #fff, 0 18px 0 #fff;
}

.header-white-content .header-icon {
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-white-content .dl-menuwrapper .dl-trigger {
    margin-top: -64px;
  }
}

@media only screen and (max-width: 767px) {
  .header-white-content .dl-menuwrapper .dl-trigger {
    margin-top: -64px;
  }
}

@media only screen and (max-width: 575px) {
  .header-white-content .dl-menuwrapper .dl-trigger {
    margin-top: -50px;
  }
}

.off-canvas-wrapper .off-canvas-navbar {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  max-width: 90%;
}

.off-canvas-wrapper .off-canvas-navbar .menu-close {
  position: absolute;
  top: 20%;
  right: 20%;
  line-height: 40px;
  transition: all .3s linear;
  font-size: 40px;
  color: rgba(35, 35, 36, 0.6);
}

.off-canvas-wrapper.open {
  opacity: 1;
  visibility: visible;
}

.off-canvas-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(255, 255, 255, 0.9);
  overflow: auto;
  text-align: center;
  transition: all .3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}

.off-canvas-wrapper.open .off-canvas-navbar .canvas-menu .main-menu > li {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.off-canvas-wrapper .off-canvas-navbar .canvas-menu .main-menu > li:nth-of-type(2) {
  -webkit-transition-delay: .3s;
  -moz-transition-delay: .3s;
  -ms-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
}

.off-canvas-wrapper .off-canvas-navbar .canvas-menu .main-menu > li .sub-mega-menu li a {
  font-size: 16px;
  font-weight: 700;
  line-height: 2;
}

.off-canvas-wrapper .off-canvas-navbar .canvas-menu .main-menu > li a {
  font-size: 60px;
  font-weight: 700;
  color: #232324;
  line-height: 1.6;
  -webkit-transition: all .3s linear;
  -moz-transition: all .3s linear;
  -ms-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .off-canvas-wrapper .off-canvas-navbar .canvas-menu .main-menu > li a {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .off-canvas-wrapper .off-canvas-navbar .canvas-menu .main-menu > li a {
    font-size: 30px;
  }
}

.off-canvas-wrapper .off-canvas-navbar .canvas-menu .main-menu > li .sub-mega-menu li .sub-menu {
  padding: 10px 0;
}

.off-canvas-wrapper .off-canvas-navbar .canvas-menu .main-menu > li .sub-mega-menu li .sub-menu li a {
  font-size: 14px;
  font-weight: 400;
}

.off-canvas-wrapper .off-canvas-navbar .canvas-menu .main-menu > li .sub-menu li a {
  font-size: 16px;
  font-weight: 700;
  line-height: 2;
}

.header-select-item {
  display: flex;
  width: 280px;
  border: 1px solid #ddd;
}

.header-select-item .select-top-inner {
  width: auto;
}

.header-select-item .select-top-inner select {
  border: transparent;
  width: 100%;
  height: 34px;
  padding: 0 20px 0 10px;
  font-size: 14px;
  background: transparent url("../images/icons/selector-icon.png") no-repeat center right 5px;
}

.header-select-item .select-top-inner select:focus {
  background: transparent url("../images/icons/selector-icon.png") no-repeat center right 5px !important;
}

.header-select-item input {
  border: transparent;
  width: 100%;
  font-size: 14px;
}

.header-select-item .search-btn {
  border: none;
  background: transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container-box .header-menu__right {
    margin-right: 68px;
  }
}

@media only screen and (max-width: 767px) {
  .container-box .header-menu__right {
    margin-right: 68px;
  }
}

.sidebar-left-wrapper {
  background: #f1f1f1 none repeat scroll 0 0;
  height: 100%;
  position: fixed;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 300px;
  z-index: 7;
  padding: 40px 0 40px 40px;
  z-index: 999;
}

.sidebar-left-wrapper .header-menu__right .header-icon:first-child a {
  margin-left: 0;
}

.sidebar-left-wrapper .copy-right-text p {
  font-size: 12px;
}

.sidebar-right-wrapper {
  margin-left: 300px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-right-wrapper {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-right-wrapper {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-right-wrapper {
    margin-left: 0;
  }
}

/*=====  End of Header  ======*/
/*=========================================
=               05. Footer Css            =
===========================================*/
.section-radius {
  background-image: url(../images/radius/bg-white-radius.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: scroll;
  background-color: #f7f7f7;
  padding-top: 60px;
  padding-bottom: 0;
}

.reveal-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .reveal-footer {
    position: static;
  }
}

.footer-widget-wrapper {
  margin-bottom: -35px;
}

.footer-widget {
  margin-bottom: 30px;
}

.footer-widget__title {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}

.footer-widget__inline_list li {
  margin-bottom: 10px;
  display: inline-block;
  margin: 10px 25px;
  color: #000;
}

.footer-widget__inline_list li:last-child {
  margin-right: 0;
}

.footer-widget__inline_list li:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 767px) {
  .footer-widget__inline_list li {
    margin: 10px 10px;
  }
}

.footer-widget__list li {
  margin-bottom: 20px;
}

.footer-widget__list li:last-child {
  margin-bottom: 0;
}

.footer-widget.center-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.footer-widget-two .footer-widget {
  margin-bottom: 30px;
}

.footer-widget-two .footer-widget__title {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
}

.footer-widget-two .footer-widget__inline_list li {
  margin-bottom: 10px;
  display: inline-block;
  margin: 10px 25px;
  color: #ffffff;
}

.footer-widget-two .footer-widget__inline_list li:last-child {
  margin-right: 0;
}

.footer-widget-two .footer-widget__inline_list li:first-child {
  margin-left: 0;
}

.footer-widget-two .footer-widget__list li {
  font-size: 12px;
  margin-bottom: 20px;
  color: #eeeeee;
}

.footer-widget-two .footer-widget__list li:last-child {
  margin-bottom: 0;
}

.footer-widget-two .footer-widget.center-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .footer-widget-two .footer-widget.center-content.sm-center {
    align-items: center;
  }
}

.social-group__list .ht-social-networks.tilte-style .link-text {
  color: #000;
}

.social-group__list .ht-social-networks.tilte-style .link-text::before {
  background: rgba(255, 255, 255, 0.2);
}

.social-group__list .ht-social-networks.tilte-style .link-text:hover {
  color: #ffffff;
}

.social-group__list .ht-social-networks.tilte-style .social-link::before {
  color: #ccc;
}

.googleMap-1 {
  height: 200px;
}

.copy-text p {
  font-size: 12px;
}

.copyright-text {
  display: inline-block;
  letter-spacing: 2px;
  font-size: 14px;
}

.footer-handheld-footer-bar {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.7);
  z-index: 999;
  transition: all .3s ease;
}

@media only screen and (max-width: 575px) {
  .footer-handheld-footer-bar {
    display: block;
  }
}

.footer-handheld-footer-bar .footer-handheld__inner {
  display: flex;
  background: #232324;
  position: static;
}

.footer-handheld-footer-bar .footer-handheld__inner .single-footer-bar {
  flex-basis: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 8px 0;
  color: #fff;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}

.footer-handheld-footer-bar .footer-handheld__inner .single-footer-bar .component-target {
  width: 40px;
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  height: 40px;
  margin: auto;
}

.footer-handheld-footer-bar .footer-handheld__inner .single-footer-bar .minicart-btn {
  position: relative;
}

.footer-handheld-footer-bar .footer-handheld__inner .single-footer-bar .minicart-btn .item-counter {
  background-color: #d3b48c;
  position: absolute;
  font-size: 10px;
  height: 15px;
  line-height: 15px;
  color: #fff;
  display: block;
  top: 0px;
  right: 0px;
  min-width: 15px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}

.account-user-statc {
  position: static;
}

.menu-footer-profile {
  background-color: #232324;
  width: 100%;
  bottom: 100%;
  top: auto;
  position: absolute;
  padding: 10px 0;
  opacity: 0;
  visibility: hidden;
}

.menu-footer-profile.open {
  opacity: 1;
  visibility: visible;
}

.menu-footer-profile .menu-item {
  text-align: left;
}

.menu-footer-profile .menu-item a {
  display: block;
  padding: 8px 30px;
}

/*=====  End of footer ======*/
/*=======================================
=            06. Sidebar Css           =
=========================================*/
.shop-widget-title {
  font-weight: 600;
}

.search-widget-box .search-form-action {
  position: relative;
}

.search-widget-box .search-form-action input {
  padding: 14px 40px 14px 14px;
  line-height: 20px;
  width: 100%;
  border: 1px solid #ddd;
}

.search-widget-box .search-form-action .search-button {
  right: 15px;
  font-size: 14px;
  margin-top: 2px;
  position: absolute;
  border: none;
  background: transparent;
  top: 50%;
  transform: translateY(-50%);
}

.shop-categories-list li {
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 12px;
}

.widget-nav-color li {
  display: inline-block;
}

.widget-nav-color li .swatch-color {
  position: relative;
  border-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  display: inline-block;
  margin-top: 20px;
  margin-right: 12px;
  background: #b9afa1;
}

.widget-nav-color li .swatch-color.black {
  background: #000000;
}

.widget-nav-color li .swatch-color.blue {
  background: #4b59a3;
}

.widget-nav-color li .swatch-color.bronze {
  background: #b9afa1;
}

.widget-nav-color li .swatch-color.green {
  background: #61c58d;
}

.widget-nav-color li .swatch-color.pink {
  background: #f6b7cf;
}

.widget-list-size li {
  margin-top: 15px;
}

.widget-list-size input[type="checkbox"] {
  padding: 0;
  margin: 0;
  margin-right: 10px;
}

.ui-widget-content {
  background: #eee;
  border: medium none;
  border-radius: 0px;
  height: 3px;
  margin: 35px 0 25px;
}

.ui-widget-content .ui-state-default {
  background: #e9595e;
  border: 3px solid #e9595e;
  border-radius: 25px;
  box-shadow: none;
  color: #ffffff;
  cursor: ew-resize;
  font-weight: normal;
  height: 10px;
  width: 10px;
  top: -3px;
}

.ui-widget-content .ui-state-default:hover {
  background: #e9595e;
}

.ui-widget-content .ui-slider-range {
  background: #111;
}

.filter-price-cont {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  margin-bottom: 10px;
}

.filter-price-cont {
  font-size: 14px;
}

.filter-price-cont .input-type input {
  border: medium none;
  text-align: center;
  width: 40px;
  background: transparent;
}

.filter-price-cont .input-type:last-child {
  padding-left: 8px;
}

.shop-tag a {
  font-size: 12px;
  text-transform: uppercase;
}

.shop-tag a:hover {
  text-decoration: underline;
}

.single-widget-product-list-item {
  display: flex;
  margin-top: 20px;
}

.single-widget-product-list-item .thumnil {
  flex-basis: 0 0 auto 0;
}

.single-widget-product-list-item .thumnil img {
  max-width: 70px;
  margin-right: 20px;
}

.single-widget-product-list-item .content .title {
  font-size: 15px;
  margin-bottom: 10px;
}

.single-widget-product-list-item .content .price-box-wrap {
  display: flex;
}

.single-widget-product-list-item .content .price-box-wrap .reting-star {
  display: flex;
  margin-right: 15px;
}

.single-widget-product-list-item .content .price-box-wrap .reting-star a {
  color: #f5b223;
  font-size: 14px;
}

.blog-widget .search-form-action {
  position: relative;
}

.blog-widget .search-form-action input {
  padding: 14px 40px 14px 14px;
  line-height: 20px;
  width: 100%;
  border: 1px solid #ddd;
}

.blog-widget .search-form-action .search-button {
  right: 15px;
  font-size: 14px;
  margin-top: 2px;
  position: absolute;
  border: none;
  background: transparent;
  top: 50%;
  transform: translateY(-50%);
}

.category-sub-menu ul li {
  margin-bottom: 15px;
  font-size: 12px;
}

.category-sub-menu ul li.has-sub > a {
  color: #363f4d;
  cursor: pointer;
  display: block;
  line-height: 15px;
  position: relative;
}

.category-sub-menu ul li.has-sub > a:after {
  color: #333333;
  content: "\f067";
  font-family: "FontAwesome";
  font-size: 10px;
  position: absolute;
  right: 5px;
}

.category-sub-menu ul li.has-sub.open {
  display: block;
  width: auto;
}

.category-sub-menu ul li.has-sub.open > a {
  text-decoration: underline;
}

.category-sub-menu ul li.has-sub.open > a:after {
  content: "\f068";
}

.category-sub-menu ul li.has-sub li a {
  border: 0 none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  display: block;
  line-height: 14px;
  margin: 0 0px 10px 0;
  padding: 0 10px 5px;
  color: #333;
}

.category-sub-menu ul li.has-sub li a:hover {
  color: #d3b48c;
}

.category-sub-menu ul li.has-sub li:last-child a {
  border: none;
  margin-bottom: 10px;
}

.category-sub-menu ul li ul {
  display: none;
  margin-top: 15px;
}

.single-widget-popular-post-list-item {
  display: flex;
  margin-top: 20px;
}

.single-widget-popular-post-list-item .thumnil {
  flex-basis: 0 0 auto 0;
}

.single-widget-popular-post-list-item .thumnil img {
  max-width: 100px;
  margin-right: 15px;
}

.single-widget-popular-post-list-item .content .title {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 600;
}

.single-widget-popular-post-list-item .content .widget-meta {
  font-size: 12px;
}

.widget-blog-comments .recentcomments {
  padding-left: 30px;
  position: relative;
  margin-bottom: 20px;
  color: #8a8a8a;
}

.widget-blog-comments .recentcomments:before {
  content: "\f0e6";
  font-family: "FontAwesome";
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.widget-blog-comments .recentcomments a {
  display: inline;
  padding: 0;
  margin: 0;
  color: #000;
}

.blog-tag a {
  margin-top: 10px;
}

/*=====  End of Sidebar  ======*/
/*======================================
=        07. Button Css
=======================================*/
.btn {
  border-radius: 0;
  font-size: 15px;
  font-weight: 500;
  height: 50px;
  line-height: 38px;
  border: transparent;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: transparent;
}

.btn--text-s {
  font-size: 12px;
  font-weight: 600;
}

.btn--sm {
  line-height: 38px;
  padding: 0 22px;
  height: 40px;
}

.btn--md {
  line-height: 38px;
  padding: 0 32px;
  height: 40px;
  display: inline-block;
}

.btn--lg {
  line-height: 44px;
  padding: 0 42px;
  height: 46px;
  display: inline-block;
}

.btn--black {
  background: #000000;
  color: #ffffff;
}

.btn--black:hover, .btn--black:focus {
  background: #d3b48c;
  color: #ffffff;
}

.btn--white {
  background: #ffffff;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}

.btn--white:hover, .btn--white:focus {
  background: #d3b48c;
  color: #ffffff;
}

.btn--border_1 {
  background: transparent;
  border: 1px solid #222;
}

.btn--border_1:hover {
  border: 1px solid #d3b48c;
  background: #d3b48c;
  color: #ffffff;
}

.btn--border_2 {
  background: transparent;
  border: 2px solid #222;
}

.btn--border_2:hover {
  border: 2px solid #d3b48c;
  background: #d3b48c;
  color: #ffffff;
}

.btn--border-white_2 {
  background: transparent;
  border: 2px solid #fff;
}

.btn--border-white_2:hover {
  border: 2px solid #d3b48c;
  background: #d3b48c;
  color: #ffffff;
}

.btn--border-white_1 {
  background: transparent;
  border: 1px solid #fff;
}

.btn--border-white_1:hover {
  border: 1px solid #d3b48c;
  background: #d3b48c;
  color: #ffffff;
}

.btn--full {
  width: 100%;
}

.btn--icon {
  border: 1px solid #222;
  text-align: center;
  height: 46px;
  line-height: 44px;
  padding: 0 22px;
}

.btn--icon:hover {
  border: 1px solid #d3b48c;
  background: #d3b48c;
  color: #ffffff;
}

.btn--text-icon {
  font-size: 18px;
  font-weight: 500;
}

.btn--text-icon i {
  margin-right: 10px;
}

.btn--text-small {
  font-size: 12px;
}

.btn--text-md {
  font-size: 14px;
}

.btn--border-bottom {
  font-size: 18px;
  text-decoration: underline;
  font-weight: 500;
  line-height: 1;
}

.btn--border-bottom:hover {
  text-decoration: underline;
}

.btn--default {
  background: #d3b48c;
  color: #ffffff;
}

.btn--default:hover {
  background: #222;
  color: #ffffff;
}

/*======================================
=        08. Featuted Product Css
=======================================*/
.featuted-product-one .product-thumbnail a {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .featuted-product-one .product-thumbnail {
    margin-bottom: 30px;
  }
}

.featuted-product-one .featured-product-contect .right-cont-featured-text {
  margin-left: -30%;
}

@media only screen and (max-width: 767px) {
  .featuted-product-one .featured-product-contect .right-cont-featured-text {
    margin-left: 0%;
  }
}

.featuted-product-one .featured-product-contect .left-cont-featured-text {
  margin-right: -30%;
}

@media only screen and (max-width: 767px) {
  .featuted-product-one .featured-product-contect .left-cont-featured-text {
    margin-right: 0%;
  }
}

/*======================================
=            09. Product Css             =       
========================================*/
.single-product-item {
  margin-top: 30px;
  position: relative;
}

.single-product-item .products-images {
  position: relative;
}

.single-product-item .products-images.image-border {
  border: 1px solid #ddd;
}

.single-product-item .product-thumbnail {
  position: relative;
  display: block;
}

.single-product-item .product-thumbnail::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.single-product-item .product-thumbnail .image-primary img {
  width: 100%;
}

.single-product-item .product-thumbnail .image-secondary {
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  width: 100%;
}

.single-product-item .product-thumbnail .image-secondary > img {
  width: 100%;
}

.single-product-item .product-thumbnail .ribbon {
  position: absolute;
  left: 0px;
  top: 0px;
  background: #000000;
  color: #ffffff;
  padding: 3px 12px;
  font-size: 12px;
}

.single-product-item .product-thumbnail .ribbon.out-of-stock {
  background: #000000;
}

.single-product-item .product-thumbnail .ribbon.onsale {
  background: #f14705;
}

.single-product-item .product-thumbnail > img {
  width: 100%;
}

.single-product-item .product-wishlist {
  position: absolute;
  right: 10px;
  top: 15px;
  padding: 0 15px;
}

.single-product-item .product-actions {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.single-product-item .product-actions a {
  width: 40px;
  height: 40px;
  line-height: 43px;
  background-color: #fff;
  text-align: center;
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  color: #000;
  transition: .5s;
  opacity: 0;
  transform: translateY(20px);
}

.single-product-item .product-actions a:nth-child(1) {
  transition-delay: 0s;
}

.single-product-item .product-actions a:nth-child(2) {
  transition-delay: .15s;
}

.single-product-item .product-actions a:nth-child(3) {
  transition-delay: .3s;
}

.single-product-item .product-actions a .tool-tip {
  position: absolute;
  background: #000;
  text-align: center;
  padding: 8px 12px;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity .6s, margin .3s;
  white-space: pre;
  font-size: 13px;
  line-height: 14px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  border-radius: 2px;
}

.single-product-item .product-actions a .tool-tip::after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #000;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: "";
  height: 0;
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 0;
  bottom: -6px;
  transform: translateX(-50%) rotate(90deg);
}

.single-product-item .product-actions a:hover {
  color: #d3b48c;
}

.single-product-item .product-actions a:hover .tool-tip {
  visibility: visible;
  opacity: 1;
  top: -35px;
}

.single-product-item:hover .product-thumbnail::after {
  opacity: 1;
  visibility: visible;
}

.single-product-item:hover .product-thumbnail .image-secondary {
  opacity: 1;
  visibility: visible;
}

.single-product-item:hover .product-actions a {
  transform: translateY(0);
  opacity: 1;
}

.single-product-item:hover .product-actions a:nth-child(1) {
  transition-delay: 0s;
}

.single-product-item:hover .product-actions a:nth-child(2) {
  transition-delay: .15s;
}

.single-product-item:hover .product-actions a:nth-child(3) {
  transition-delay: .3s;
}

.single-product-item:hover .product-content-two {
  background: #fff;
}

.single-product-item:hover .product-content-two .product_item--action {
  margin-bottom: 0;
  visibility: visible;
  opacity: 1;
}

.single-product-item .product-content {
  margin-top: 20px;
}

.single-product-item .product-content .prodect-title {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
}

.single-product-item .product-content .product-price span {
  color: #666;
  font-size: 14px;
}

.single-product-item .product-content .product-price .old-price {
  text-decoration: line-through;
}

.single-product-item .product-content .product-price .new-price {
  color: #d3b48c;
}

.single-product-item .product-content-two {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: rgba(225, 225, 225, 0.9);
  padding: 20px;
}

.single-product-item .product-content-two .prodect-title {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
}

.single-product-item .product-content-two .product_item--action {
  display: block;
  margin-bottom: -20px;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
}

.single-product-item .product-content-two .product_item--action ul li {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.single-product-item .product-content-two .product_item--action ul li::before {
  content: "";
  position: absolute;
  height: 12px;
  top: 50%;
  left: -5px;
  width: 1px;
  background: #000;
  transform: translateY(-50%);
}

.single-product-item .product-content-two .product_item--action ul li:first-child::before {
  display: none;
}

.single-product-item-two {
  margin-top: 30px;
  border: 1px solid #ddd;
  position: relative;
}

.single-product-item-two .products-images {
  position: relative;
}

.single-product-item-two .product-thumbnail {
  position: relative;
  display: block;
}

.single-product-item-two .product-thumbnail .image-primary img {
  width: 100%;
}

.single-product-item-two .product-thumbnail .image-secondary {
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  width: 100%;
}

.single-product-item-two .product-thumbnail .image-secondary > img {
  width: 100%;
}

.single-product-item-two .product-thumbnail .ribbon {
  position: absolute;
  left: 0px;
  top: 0px;
  background: #000000;
  color: #ffffff;
  padding: 3px 12px;
  font-size: 12px;
}

.single-product-item-two .product-thumbnail .ribbon.out-of-stock {
  background: #000000;
}

.single-product-item-two .product-thumbnail .ribbon.onsale {
  background: #f14705;
}

.single-product-item-two .product-thumbnail > img {
  width: 100%;
}

.single-product-item-two .product-wishlist {
  position: absolute;
  right: 10px;
  top: 15px;
  padding: 0 15px;
  z-index: 3;
}

.single-product-item-two .product-actions {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.single-product-item-two .product-actions a {
  width: 40px;
  height: 40px;
  line-height: 43px;
  background-color: #fff;
  text-align: center;
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  color: #000;
  transition: .5s;
  opacity: 0;
  background: #ebebeb;
  transform: translateY(20px);
}

.single-product-item-two .product-actions a:nth-child(1) {
  transition-delay: 0s;
}

.single-product-item-two .product-actions a:nth-child(2) {
  transition-delay: .15s;
}

.single-product-item-two .product-actions a:nth-child(3) {
  transition-delay: .3s;
}

.single-product-item-two .product-actions a .tool-tip {
  position: absolute;
  background: #000;
  text-align: center;
  padding: 8px 12px;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity .6s, margin .3s;
  white-space: pre;
  font-size: 13px;
  line-height: 14px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  border-radius: 2px;
}

.single-product-item-two .product-actions a .tool-tip::after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #000;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: "";
  height: 0;
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 0;
  bottom: -6px;
  transform: translateX(-50%) rotate(90deg);
}

.single-product-item-two .product-actions a:hover {
  color: #d3b48c;
}

.single-product-item-two .product-actions a:hover .tool-tip {
  visibility: visible;
  opacity: 1;
  top: -35px;
}

.single-product-item-two:hover .product-thumbnail .image-secondary {
  opacity: 1;
  visibility: visible;
}

.single-product-item-two:hover .product-actions a {
  transform: translateY(0);
  opacity: 1;
}

.single-product-item-two:hover .product-actions a:nth-child(1) {
  transition-delay: 0s;
}

.single-product-item-two:hover .product-actions a:nth-child(2) {
  transition-delay: .15s;
}

.single-product-item-two:hover .product-actions a:nth-child(3) {
  transition-delay: .3s;
}

.single-product-item-two .product-content {
  margin: 20px 0;
}

.single-product-item-two .product-content .prodect-title {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
}

.single-product-item-two .product-content .product-price span {
  color: #666;
  font-size: 14px;
}

.single-product-item-two .product-content .product-price .old-price {
  text-decoration: line-through;
}

.single-product-item-two .product-content .product-price .new-price {
  color: #d3b48c;
}

.single-product-item-three {
  margin-top: 30px;
  border: 1px solid #ddd;
  position: relative;
}

.single-product-item-three .products-images {
  position: relative;
}

.single-product-item-three .product-thumbnail {
  position: relative;
  display: block;
}

.single-product-item-three .product-thumbnail .image-primary img {
  width: 100%;
}

.single-product-item-three .product-thumbnail .image-secondary {
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  width: 100%;
}

.single-product-item-three .product-thumbnail .image-secondary > img {
  width: 100%;
}

.single-product-item-three .product-thumbnail .ribbon {
  position: absolute;
  left: 0px;
  top: 0px;
  background: #000000;
  color: #ffffff;
  padding: 3px 12px;
  font-size: 12px;
}

.single-product-item-three .product-thumbnail .ribbon.out-of-stock {
  background: #000000;
}

.single-product-item-three .product-thumbnail .ribbon.onsale {
  background: #f14705;
}

.single-product-item-three .product-thumbnail > img {
  width: 100%;
}

.single-product-item-three .product-wishlist {
  position: absolute;
  right: 10px;
  top: 15px;
  padding: 0 15px;
  z-index: 3;
}

.single-product-item-three .product-actions {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.single-product-item-three .product-actions a {
  width: 40px;
  height: 40px;
  line-height: 43px;
  background-color: #fff;
  text-align: center;
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  color: #000;
  transition: .5s;
  opacity: 0;
  background: #ebebeb;
  transform: translateY(20px);
}

.single-product-item-three .product-actions a:nth-child(1) {
  transition-delay: 0s;
}

.single-product-item-three .product-actions a:nth-child(2) {
  transition-delay: 0s;
}

.single-product-item-three .product-actions a:nth-child(3) {
  transition-delay: 0s;
}

.single-product-item-three .product-actions a .tool-tip {
  position: absolute;
  background: #000;
  text-align: center;
  padding: 8px 12px;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity .6s, margin .3s;
  white-space: pre;
  font-size: 13px;
  line-height: 14px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  border-radius: 2px;
}

.single-product-item-three .product-actions a .tool-tip::after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #000;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: "";
  height: 0;
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 0;
  bottom: -6px;
  transform: translateX(-50%) rotate(90deg);
}

.single-product-item-three .product-actions a:hover {
  color: #d3b48c;
}

.single-product-item-three .product-actions a:hover .tool-tip {
  visibility: visible;
  opacity: 1;
  top: -35px;
}

.single-product-item-three .product-content {
  border-top: 1px solid #ddd;
  padding: 20px 10px;
}

.single-product-item-three .product-content .product-content-box {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 250ms ease-out;
}

.single-product-item-three .product-content .prodect-title {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
}

.single-product-item-three .product-content .product-price span {
  color: #666;
  font-size: 14px;
}

.single-product-item-three .product-content .product-price .old-price {
  text-decoration: line-through;
}

.single-product-item-three .product-content .product-price .new-price {
  color: #d3b48c;
}

.single-product-item-three:hover .product-thumbnail .image-secondary {
  opacity: 1;
  visibility: visible;
}

.single-product-item-three:hover .product-actions a {
  transform: translateY(0);
  opacity: 1;
}

.single-product-item-three:hover .product-actions a:nth-child(1) {
  transition-delay: 0s;
}

.single-product-item-three:hover .product-actions a:nth-child(2) {
  transition-delay: 0s;
}

.single-product-item-three:hover .product-actions a:nth-child(3) {
  transition-delay: 0s;
}

.single-product-item-three:hover .product-content-box {
  opacity: 0;
  transform: translate(0, -20px);
}

.single-product-item-four {
  margin-top: 30px;
  position: relative;
}

.single-product-item-four .products-images {
  position: relative;
}

.single-product-item-four .product-thumbnail {
  position: relative;
  display: block;
}

.single-product-item-four .product-thumbnail .image-primary img {
  width: 100%;
}

.single-product-item-four .product-thumbnail .image-secondary {
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  width: 100%;
}

.single-product-item-four .product-thumbnail .image-secondary > img {
  width: 100%;
}

.single-product-item-four .product-thumbnail .ribbon {
  position: absolute;
  left: 0px;
  top: 0px;
  background: #000000;
  color: #ffffff;
  padding: 3px 12px;
  font-size: 12px;
}

.single-product-item-four .product-thumbnail .ribbon.out-of-stock {
  background: #000000;
}

.single-product-item-four .product-thumbnail .ribbon.onsale {
  background: #f14705;
}

.single-product-item-four .product-thumbnail > img {
  width: 100%;
}

.single-product-item-four .product-wishlist {
  position: absolute;
  right: 10px;
  top: 15px;
  padding: 0 15px;
  z-index: 3;
}

.single-product-item-four .product-actions {
  position: absolute;
  top: 100%;
  left: 0%;
  transform: translateY(-100%);
  width: 100%;
  overflow: hidden;
}

.single-product-item-four .product-actions a {
  width: 100%;
  height: 40px;
  line-height: 43px;
  background: rgba(39, 39, 39, 0.9);
  text-align: center;
  font-size: 14px;
  color: #fff;
  transition: .5s;
  opacity: 0;
  font-size: 12px;
  text-transform: uppercase;
  transform: translateY(20px);
}

.single-product-item-four .product-actions a:hover {
  color: #ffffff;
  background: #d3b48c;
}

.single-product-item-four .content-action {
  position: relative;
}

.single-product-item-four .content-action .prodect-price {
  opacity: 1;
  transition: all 250ms ease-out;
}

.single-product-item-four .content-action .cart--action {
  opacity: 0;
  position: absolute;
  width: 100%;
  top: 0;
}

.single-product-item-four .content-action .cart--action a {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}

.single-product-item-four .content-action .cart--action a::before {
  content: "+";
  font-weight: 400;
  padding-right: 2px;
}

.single-product-item-four:hover {
  box-shadow: 0 0 38px 0 rgba(41, 44, 58, 0.09);
}

.single-product-item-four:hover .product-thumbnail .image-secondary {
  opacity: 1;
  visibility: visible;
}

.single-product-item-four:hover .product-actions a {
  transform: translateY(0);
  opacity: 1;
}

.single-product-item-four:hover .cart--action {
  opacity: 1;
  transition: all 250ms ease-out;
}

.single-product-item-four:hover .prodect-price {
  opacity: 0;
}

.single-product-item-four .product-content {
  padding: 20px 0;
}

.single-product-item-four .product-content .prodect-title {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
}

.single-product-item-four .product-content .product-price span {
  color: #666;
  font-size: 14px;
}

.single-product-item-four .product-content .product-price .old-price {
  text-decoration: line-through;
}

.single-product-item-four .product-content .product-price .new-price {
  color: #d3b48c;
}

.one-product-area {
  overflow: hidden;
  position: relative;
  background: linear-gradient(90deg, #4ddfbd 0%, #9ee347 100%);
}

.one-product-area .one-product-price {
  margin-right: 32px;
  text-align: right;
  margin-bottom: 20px;
}

.one-product-area .one-product-price span {
  background: linear-gradient(90deg, #f98c43 0%, #f76b17 100%);
  display: inline-block;
  padding: 12px 30px;
  color: #ffffff;
}

.one-product-area .content {
  text-align: center;
  position: relative;
  font-family: "Roboto Condensed", sans-serif;
}

.one-product-area .content .title-1 {
  font-size: 200px;
  line-height: 1;
  letter-spacing: -10px;
  text-shadow: 0 0 59px rgba(0, 0, 0, 0.17);
  margin: -19% 0 0;
  position: relative;
  z-index: 2;
  color: #ffffff;
  font-weight: 700;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .one-product-area .content .title-1 {
    font-size: 180px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .one-product-area .content .title-1 {
    font-size: 98px;
    letter-spacing: -3px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .one-product-area .content .title-1 {
    font-size: 68px;
    letter-spacing: -3px;
  }
}

@media only screen and (max-width: 767px) {
  .one-product-area .content .title-1 {
    font-size: 68px;
    letter-spacing: -3px;
  }
}

@media only screen and (max-width: 575px) {
  .one-product-area .content .title-1 {
    font-size: 48px;
    letter-spacing: -3px;
  }
}

.one-product-area .content .images {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .one-product-area .content .images {
    display: block;
  }
}

.one-product-area .content .title-2 {
  font-size: 200px;
  letter-spacing: -16px;
  text-shadow: 0 0 59px rgba(0, 0, 0, 0.17);
  line-height: normal;
  opacity: .5;
  position: absolute;
  left: 50%;
  white-space: nowrap;
  top: 8px;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  z-index: 0;
  font-weight: 700;
  color: #ffffff;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .one-product-area .content .title-2 {
    font-size: 180px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .one-product-area .content .title-2 {
    font-size: 98px;
    letter-spacing: -3px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .one-product-area .content .title-2 {
    font-size: 68px;
    letter-spacing: -3px;
  }
}

@media only screen and (max-width: 767px) {
  .one-product-area .content .title-2 {
    font-size: 68px;
    letter-spacing: -3px;
  }
}

@media only screen and (max-width: 575px) {
  .one-product-area .content .title-2 {
    font-size: 48px;
    letter-spacing: -3px;
  }
}

.product-slider-active .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  z-index: 3;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  transition: all 0.3s ease-in-out;
}

.product-slider-active .slick-arrow.arrow-next {
  right: 0px;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .product-slider-active .slick-arrow {
    left: 15px;
  }
  .product-slider-active .slick-arrow.arrow-next {
    right: 15px;
  }
}

.product-list-item .products-images {
  position: relative;
}

.product-list-item .products-images .product-thumbnail {
  position: relative;
  display: block;
}

.product-list-item .products-images .product-thumbnail .image-primary img {
  width: 100%;
}

.product-list-item .products-images .product-thumbnail .image-secondary {
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  width: 100%;
}

.product-list-item .products-images .product-thumbnail .image-secondary > img {
  width: 100%;
}

.product-list-item .products-images .product-thumbnail > img {
  width: 100%;
}

.product-list-item .products-images .product-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.product-list-item .products-images .product-actions a {
  width: 40px;
  height: 40px;
  line-height: 43px;
  background-color: #fff;
  text-align: center;
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  color: #000;
  transition: .5s;
  opacity: 0;
  background: #ebebeb;
  transform: translateY(20px);
}

.product-list-item .products-images .product-actions a:nth-child(1) {
  transition-delay: 0s;
}

.product-list-item .products-images .product-actions a .tool-tip {
  position: absolute;
  background: #000;
  text-align: center;
  padding: 8px 12px;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity .6s, margin .3s;
  white-space: pre;
  font-size: 13px;
  line-height: 14px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  border-radius: 2px;
}

.product-list-item .products-images .product-actions a .tool-tip::after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #000;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: "";
  height: 0;
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 0;
  bottom: -6px;
  transform: translateX(-50%) rotate(90deg);
}

.product-list-item .products-images .product-actions a:hover {
  color: #d3b48c;
}

.product-list-item .products-images .product-actions a:hover .tool-tip {
  visibility: visible;
  opacity: 1;
  top: -35px;
}

.product-list-item:hover .product-thumbnail .image-secondary {
  opacity: 1;
  visibility: visible;
}

.product-list-item:hover .product-actions a {
  transform: translateY(0);
  opacity: 1;
}

.product-list-item:hover .product-actions a:nth-child(1) {
  transition-delay: 0s;
}

.product-list-item .product-content {
  margin-top: 30px;
}

.product-list-item .product-content .prodect-title {
  font-weight: 600;
}

.product-list-item .product-content .reting-star a {
  color: #f5b223;
}

.product-list-item .product-content .product-price span {
  color: #666;
  font-size: 16px;
}

.product-list-item .product-content .product-price .old-price {
  text-decoration: line-through;
}

.product-list-item .product-content .product-price .new-price {
  color: #000000;
}

.product-list-item .product-list-action a:hover {
  color: #d3b48c;
}

.reting-star a {
  color: #f5b223;
}

.single-product-item-mesonry {
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}

.single-product-item-mesonry .product-thumbnail {
  position: relative;
  display: block;
}

.single-product-item-mesonry .product-thumbnail::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.single-product-item-mesonry .product-thumbnail .image-primary > img {
  width: 100%;
}

.single-product-item-mesonry .product-wishlist {
  position: absolute;
  right: 10px;
  top: 15px;
  padding: 0 15px;
}

.single-product-item-mesonry .product-actions {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.single-product-item-mesonry .product-actions a {
  width: 40px;
  height: 40px;
  line-height: 43px;
  background-color: #fff;
  text-align: center;
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  color: #000;
  transition: .5s;
  opacity: 0;
  transform: translateY(20px);
}

.single-product-item-mesonry .product-actions a .tool-tip {
  position: absolute;
  background: #000;
  text-align: center;
  padding: 8px 12px;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity .6s, margin .3s;
  white-space: pre;
  font-size: 13px;
  line-height: 14px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  border-radius: 2px;
}

.single-product-item-mesonry .product-actions a .tool-tip::after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #000;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: "";
  height: 0;
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 0;
  bottom: -6px;
  transform: translateX(-50%) rotate(90deg);
}

.single-product-item-mesonry .product-actions a:hover {
  color: #d3b48c;
}

.single-product-item-mesonry .product-actions a:hover .tool-tip {
  visibility: visible;
  opacity: 1;
  top: -35px;
}

.single-product-item-mesonry:hover .product-thumbnail::after {
  opacity: 1;
  visibility: visible;
}

.single-product-item-mesonry:hover .product-actions {
  top: 50%;
}

.single-product-item-mesonry:hover .product-actions a {
  transform: translateY(0);
  opacity: 1;
}

.single-product-item-mesonry:hover .product-content {
  bottom: 10px;
  opacity: 1;
  visibility: visible;
}

.single-product-item-mesonry .product-content {
  margin-top: 20px;
  position: absolute;
  bottom: -10px;
  width: 100%;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.single-product-item-mesonry .product-content .prodect-title {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
  color: #ffffff;
}

.single-product-item-mesonry .product-content .prodect-price span {
  color: #fff;
  font-size: 14px;
}

.single-product-item-mesonry .product-content .prodect-price .old-price {
  text-decoration: line-through;
}

.single-product-item-mesonry .product-content .prodect-price .new-price {
  color: #ffffff;
}

.product-code p {
  font-size: 10px !important;
  font-weight: 600;
  color: #000;
}

/*======================================
=      18. Product Tab list Css        =       
=======================================*/
.product-tab-menu li {
  padding: 0 15px;
  margin-top: 10px;
  line-height: 12px;
  cursor: pointer;
  display: block;
}

.product-tab-menu li:last-child {
  border-right: none;
}

.product-tab-menu li:last-child {
  padding-right: 0;
}

.product-tab-menu li:first-child {
  padding-left: 0;
}

@media only screen and (max-width: 767px) {
  .product-tab-menu li {
    padding: 0 1px;
  }
}

.product-tab-menu li a {
  padding: 0;
  border: 1px solid transparent;
  padding: 15px 20px;
}

.product-tab-menu li a.active, .product-tab-menu li a:hover {
  color: #999;
  border: 1px solid #ddd;
}

.product-tab-menu--sport li a {
  font-size: 13px;
  padding: 0;
  border: 1px solid transparent;
  padding: 12px 20px;
  position: relative;
}

.product-tab-menu--sport li a::after {
  content: "";
  height: 3px;
  background-image: linear-gradient(90deg, #4ddfbd 0%, #9ee347 100%);
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  opacity: 0;
  visibility: hidden;
}

.product-tab-menu--sport li a.active, .product-tab-menu--sport li a:hover {
  color: #999;
  border: 1px solid #ddd;
}

.product-tab-menu--sport li a.active::after, .product-tab-menu--sport li a:hover::after {
  background-image: linear-gradient(90deg, #4ddfbd 0%, #9ee347 100%);
  opacity: 1;
  visibility: visible;
}

.product-tab-menu--gradient-two li a {
  font-size: 13px;
  padding: 0;
  border: 1px solid transparent;
  padding: 12px 20px;
  position: relative;
}

.product-tab-menu--gradient-two li a::after {
  content: "";
  height: 3px;
  background-image: linear-gradient(90deg, #d1ecff 0%, #efcdcd 90%);
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  opacity: 0;
  visibility: hidden;
}

.product-tab-menu--gradient-two li a.active, .product-tab-menu--gradient-two li a:hover {
  color: #999;
  border: 1px solid #ddd;
}

.product-tab-menu--gradient-two li a.active::after, .product-tab-menu--gradient-two li a:hover::after {
  background-image: linear-gradient(90deg, #d1ecff 0%, #efcdcd 90%);
  opacity: 1;
  visibility: visible;
}

.product-tab-menu--two li {
  line-height: 12px;
  cursor: pointer;
  display: block;
}

.product-tab-menu--two li:last-child {
  border-right: none;
}

.product-tab-menu--two li:last-child {
  padding-right: 0;
}

.product-tab-menu--two li:first-child {
  padding-left: 0;
}

.product-tab-menu--two li a {
  padding: 0;
  border: 1px solid transparent;
  padding: 5px 20px;
  letter-spacing: 2px;
  font-family: "Playfair Display", serif;
  font-size: 30px;
}

.product-tab-menu--two li a.active, .product-tab-menu--two li a:hover {
  color: #d3b48c;
}

.product-tab-menu-two li {
  padding: 5px 20px;
  margin-top: 10px;
  line-height: 12px;
  cursor: pointer;
  display: block;
  font-size: 12px;
}

.product-tab-menu-two li:last-child {
  border-right: none;
}

.product-tab-menu-two li:last-child {
  padding-right: 0;
}

.product-tab-menu-two li:first-child {
  padding-left: 0;
}

.product-tab-menu-two li a {
  padding: 0;
  border-bottom: 2px solid transparent;
  padding: 5px 0px;
}

.product-tab-menu-two li a.active, .product-tab-menu-two li a:hover {
  color: #000;
  border-bottom: 2px solid #d3b48c;
}

.more-product {
  font-size: 12px;
  color: #36393e;
  line-height: 1;
  text-align: center;
}

.tab-content .tab-pane {
  display: block;
  overflow: hidden;
  height: 0;
  visibility: hidden;
  max-width: 100%;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/*================================================
=     19. Single Product details Css             =       
==================================================*/
.product-modal-box .modal-dialog {
  margin: 5% auto 8%;
  max-width: 1000px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-modal-box .modal-dialog {
    width: calc(100% - 15px);
  }
}

@media only screen and (max-width: 767px) {
  .product-modal-box .modal-dialog {
    width: calc(100% - 10px);
  }
}

.product-modal-box .modal-dialog .modal-content {
  border-radius: 0;
  padding: 0;
}

.product-modal-box .modal-body {
  padding: 30px;
}

@media only screen and (max-width: 767px) {
  .product-modal-box .modal-body {
    padding: 15px;
  }
}

.product-modal-box .modal-dialog .modal-content .modal-header {
  border: none;
  padding: 0;
  right: 20px;
  top: 20px;
  position: absolute;
  z-index: 9999;
  color: #000000;
}

.product-modal-box .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  position: absolute;
  top: -48px;
  right: 0;
  padding: 0;
}

.product-dec-small {
  margin: 5px;
}

.product-small-image {
  margin: 0 -10px;
  margin-top: 20px;
}

.product-dec-small {
  padding: 0 10px;
}

.product-small-image {
  position: relative;
}

.product-small-image .slick-arrow {
  font-size: 14px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  background: #fff;
  top: calc(50% - 15px);
  text-align: center;
  left: 15px;
  right: auto;
  z-index: 3;
  color: #000;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.15);
}

.product-small-image .slick-arrow.pro-dec-next {
  right: 0px;
  left: auto;
}

.product-details-content p {
  font-size: 14px;
}

.quickview-action-wrap .quickview-cart-box {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 479px) {
  .quickview-action-wrap .quickview-cart-box {
    flex-direction: column;
    display: block;
  }
}

.quickview-action-wrap .quickview-quality {
  border: 1px solid #ddd;
}

.quickview-action-wrap .quickview-button {
  display: flex;
  align-items: center;
  width: 100%;
}

.quickview-action-wrap .quickview-button .button {
  margin-left: 15px;
}

@media only screen and (max-width: 479px) {
  .quickview-action-wrap .quickview-button .button:first-child {
    margin-left: 0px;
  }
}

.quickview-action-wrap .quickview-wishlist a {
  width: 50px;
  height: 46px;
  line-height: 46px;
  display: inline-block;
  font-size: 20px;
  border: 1px solid #ddd;
  text-align: center;
}

.cart-quality-box {
  border: 1px solid #ddd;
  max-width: 100px;
}

.cart-quality-box-2 {
  display: inline-block;
  border: 1px solid #ddd;
}

.cart-plus-minus {
  display: inline-block;
  height: 36px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100px;
  text-align: center;
}

.cart-plus-minus .qtybutton {
  color: #333;
  cursor: pointer;
  float: inherit;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 24px;
}

.cart-plus-minus .qtybutton.dec {
  height: 34px;
  top: 12px;
  left: 4px;
  font-weight: 600;
  font-size: 15px;
  font-family: sans-serif;
}

.cart-plus-minus .qtybutton.inc {
  height: 34px;
  top: 12px;
  right: 4px;
  font-weight: 600;
  font-size: 15px;
  font-family: sans-serif;
}

.cart-plus-minus input.cart-plus-minus-box {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #262626;
  font-size: 14px;
  height: 46px;
  line-height: 40px;
  margin: 0;
  padding: 0px;
  text-align: center;
  width: 100px;
  font-weight: 600;
}

.quality-height-dec .cart-plus-minus {
  height: 45px;
}

.quality-height-dec .cart-plus-minus .qtybutton.dec {
  height: 45px;
  top: 11px;
}

.quality-height-dec .cart-plus-minus .qtybutton.inc {
  height: 45px;
  top: 12px;
}

.quality-height-dec .cart-plus-minus input.cart-plus-minus-box {
  height: 45px;
  line-height: 45px;
}

.quality-height-dec2 .cart-plus-minus {
  height: 45px;
}

.quality-height-dec2 .cart-plus-minus .qtybutton.dec {
  height: 45px;
  top: 11px;
  font-weight: 500;
  left: 22px;
}

.quality-height-dec2 .cart-plus-minus .qtybutton.inc {
  height: 45px;
  top: 12px;
  font-weight: 500;
  right: 22px;
}

.quality-height-dec2 .cart-plus-minus input.cart-plus-minus-box {
  height: 45px;
  line-height: 45px;
  width: 80px;
  font-weight: 400;
}

.singel-cart-wrap {
  margin-bottom: 20px;
}

.singel-cart-wrap .singel-cart-title {
  display: inline-block;
  margin-left: 10px;
}

.singel-cart-wrap .singel-cart-title .title {
  font-size: 14px;
  font-weight: 600;
}

.product-support-text a {
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
}

.product-support-text a i {
  margin-right: 10px;
}

.quickview-meta span {
  display: block;
  font-size: 12px;
  margin-bottom: 10px;
}

.product_meta .item_meta {
  font-weight: 500;
  color: #666;
}

.product_meta .item_meta .label {
  color: #000000;
}

.product_meta .item_meta a:hover {
  color: #666;
  text-decoration: underline;
}

.product_socials-inline li {
  display: inline-block;
  margin-right: 15px;
}

.section-title-6 .title {
  font-size: 70px;
  font-weight: 700;
  color: #232324;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
  padding-bottom: 18px;
  letter-spacing: -5px;
}

.tab-pane.active {
  -webkit-animation: tabUp .5s;
  -moz-animation: tabUp .5s;
  -o-animation: tabUp .5s;
  animation: tabUp .5s;
}

@-webkit-keyframes tabUp {
  from {
    transform: translate3d(0, 30px, 0);
    -webkit-transform: translate3d(0, 30px, 0);
    -moz-transform: translate3d(0, 30px, 0);
    -ms-transform: translate3d(0, 30px, 0);
    -o-transform: translate3d(0, 30px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
  }
}

@keyframes tabUp {
  from {
    transform: translate3d(0, 30px, 0);
    -webkit-transform: translate3d(0, 30px, 0);
    -moz-transform: translate3d(0, 30px, 0);
    -ms-transform: translate3d(0, 30px, 0);
    -o-transform: translate3d(0, 30px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
  }
}

/*======================================
=       10. Product Support             =       
========================================*/
.single-support-item {
  margin-top: 50px;
}

.single-support-item .header-support {
  display: flex;
  align-items: center;
}

.single-support-item .header-support .icon {
  line-height: 1;
  font-size: 36px;
  margin-right: 20px;
  line-height: 24px;
  font-size: 24px;
  width: 24px;
  height: 24px;
  color: #d3b48c;
}

.single-support-item .iconbox-desc {
  margin-top: 20px;
}

.single-support-item-two {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 10px;
}

.single-support-item-two .icon {
  line-height: 1;
  font-size: 36px;
  margin-right: 20px;
  line-height: 24px;
  font-size: 24px;
  width: 24px;
  height: 24px;
}

.single-support-item-two .iconbox-desc .heading {
  font-size: 16px;
}

.single-support-item-two .iconbox-desc p {
  font-style: italic;
}

.single-support-item-two.color--one {
  background: #e6de9d;
}

.single-support-item-two.color--two {
  background: #f9f1e2;
}

.single-support-item-two.color--three {
  background: #e2d5d5;
}

.single-shopping-support {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.single-shopping-support .icon {
  color: #dcb14a;
  font-size: 48px;
  margin-right: 26px;
}

/*======================================
=         11. Newsletter Css            =
=======================================*/
.newsletter--one {
  position: relative;
}

.newsletter--one .input-box {
  border: none;
  background: #f4f5f7;
  width: 100%;
  height: 54px;
  padding: 10px 170px 10px 20px;
}

.newsletter--one .submit-btn {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 16px;
  opacity: 1;
  padding: 0 40px;
  border: none;
  height: 54px;
  background: #000000;
  color: #ffffff;
}

.newsletter--two {
  position: relative;
}

.newsletter--two .input-box {
  border: none;
  background: #ddd;
  width: 100%;
  height: 54px;
  padding: 10px 140px 10px 20px;
}

.newsletter--two .submit-btn {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 16px;
  opacity: 1;
  padding: 0 30px;
  border: none;
  height: 54px;
  background: #000000;
  color: #ffffff;
  font-size: 12px;
}

.newsletter--three {
  position: relative;
  max-width: 400px;
  margin: auto;
}

.newsletter--three .input-box {
  border: none;
  background: #fff;
  width: 100%;
  height: 54px;
  padding: 10px 140px 10px 20px;
  border-radius: 35px;
}

.newsletter--three .submit-btn {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 16px;
  opacity: 1;
  padding: 0 20px;
  border: none;
  height: 54px;
  background: #000000;
  color: #ffffff;
  font-size: 12px;
  border-radius: 0 35px 35px 0;
}

.social-media-link .style-default li {
  display: inline-block;
  margin: 10px;
}

.social-media-link .style-default li:first-child {
  margin-left: 0;
}

/*======================================
=           12. Banner Css             =       
========================================*/
.single-banner-one .banner-thumbnail {
  position: relative;
  display: block;
  overflow: hidden;
}

.single-banner-one .banner-thumbnail img {
  transform: scale(1.02);
  width: 100%;
}

.single-banner-one .banner-thumbnail:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  content: '';
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -120%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -120%, 0);
  z-index: 1;
}

.single-banner-one .banner--info {
  top: 50%;
  width: 100%;
  max-width: 80%;
  left: 10%;
  z-index: 0;
  position: absolute;
  transform: translateY(-50%);
}

.single-banner-one .banner--info p {
  color: #000000;
}

.single-banner-one .banner--info .offer {
  font-weight: 600;
  line-height: 30px;
  font-size: 90px;
}

.single-banner-one .banner--info .offer .person {
  font-size: 16px;
  margin-left: -20px;
}

.single-banner-one .banner--info button {
  color: #000000;
}

.single-banner-one .banner--info button:hover {
  color: #ffffff;
}

.single-banner-one .banner--info-three {
  top: 50%;
  width: 100%;
  max-width: 200px;
  left: 10%;
  z-index: 0;
  position: absolute;
  transform: translateY(-50%);
  text-align: center;
}

.single-banner-one .banner--info-three .offer {
  font-size: 90px;
  line-height: 70px;
  line-height: 30px;
  font-weight: 700;
}

.single-banner-one .banner--info-three .offer .person {
  font-size: 16px;
  margin-left: -20px;
}

.single-banner-one .banner--info-three p {
  color: #000000;
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: 700;
}

.single-banner-one .banner--info-three .btn-shop {
  color: #222;
  font-size: 12px;
  font-weight: 600;
  margin-top: 20px;
  border-bottom: 2px solid #222;
  display: inline-block;
}

.single-banner-one .banner--info-four {
  top: 50%;
  width: 100%;
  max-width: 250px;
  left: 10%;
  z-index: 0;
  position: absolute;
  transform: translateY(-50%);
  text-align: center;
}

.single-banner-one .banner--info-four .offer {
  font-size: 90px;
  line-height: 70px;
  line-height: 30px;
  font-weight: 700;
}

.single-banner-one .banner--info-four .offer .person {
  font-size: 16px;
  margin-left: -20px;
}

.single-banner-one .banner--info-four p {
  color: #000000;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 600;
}

.single-banner-one .banner--info-four .btn-shop {
  color: #222;
  font-size: 12px;
  font-weight: 600;
  margin-top: 20px;
  border-top: 2px solid #222;
  display: inline-block;
}

.single-banner-one .banner--link {
  color: #fff;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 700;
  border-radius: 0;
  text-align: center;
}

.single-banner-one:hover .banner-thumbnail img {
  transform: scale(1);
}

.single-banner-one:hover .banner-thumbnail:before {
  opacity: 1;
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 120%, 0);
}

.single-banner-two .banner-thumbnail {
  position: relative;
  display: block;
  overflow: hidden;
}

.single-banner-two .banner-thumbnail img {
  transform: scale(1.1);
  width: 100%;
}

.single-banner-two .banner-thumbnail::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  content: '';
  transition: all 0.3s ease-in-out;
  opacity: 0;
  z-index: 1;
}

.single-banner-two .banner-thumbnail:hover img {
  transform: scale(1);
}

.single-banner-two .banner-thumbnail:hover::before {
  opacity: 1;
}

.single-banner-two .banner-thumbnail:hover .banner--info {
  z-index: 44;
}

.single-banner-two .banner-thumbnail:hover .banner--info p {
  position: relative;
  opacity: 1;
  z-index: 44;
}

.single-banner-two .banner-thumbnail:hover .banner--info-lg {
  z-index: 4;
}

.single-banner-two .banner-thumbnail:hover .banner--info-lg p {
  opacity: 1;
  z-index: 4;
}

.single-banner-two .banner--info {
  top: 50%;
  width: 100%;
  max-width: 80%;
  left: 10%;
  z-index: 0;
  position: absolute;
  transform: translateY(-50%);
}

.single-banner-two .banner--info p {
  color: #ffffff;
  letter-spacing: 10px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.single-banner-two .banner--info-lg {
  top: 50%;
  width: 100%;
  max-width: 80%;
  left: 10%;
  z-index: 0;
  position: absolute;
  transform: translateY(-50%);
}

.single-banner-two .banner--info-lg p {
  font-size: 24px;
  font-family: "Playfair Display", serif;
  color: #ffffff;
  letter-spacing: 0px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.banner-slider-active .slick-dots {
  text-align: center;
}

.banner-slider-active .slick-dots li {
  display: inline-block;
  margin: 5px;
}

.banner-slider-active .slick-dots li button {
  font-size: 0;
  border: none;
  height: 8px;
  width: 8px;
  padding: 0;
  background: #333;
  border-radius: 5000px;
}

.banner-slider-active .slick-dots li.slick-active button {
  width: 10px;
  height: 10px;
  background: #ddd;
}

.watch-banner-text {
  padding: 60px;
}

@media only screen and (max-width: 575px) {
  .watch-banner-text {
    padding: 60px 20px;
  }
}

.shop-section-text-area {
  padding: 150px 0;
}

.shop-section-text-area .sub-title {
  letter-spacing: 3px;
  font-size: 12px;
}

.shop-section-text-area h2 {
  font-size: 72px;
  line-height: 72px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-section-text-area h2 {
    font-size: 52px;
    line-height: 52px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-section-text-area h2 {
    font-size: 32px;
    line-height: 32px;
  }
}

.shop-section-text-area p {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  letter-spacing: 3px;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 0;
  font-size: 12px;
  margin-bottom: 30px;
}

.beauty-single-product {
  position: relative;
  overflow: hidden;
}

.beauty-single-product .banner--image a {
  display: block;
  width: 100%;
  transition: transform 0.35s ease-out;
  transform: scale(1.01);
  position: relative;
}

.beauty-single-product .banner--image a img {
  width: 100%;
}

.beauty-single-product .banner--image a::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.beauty-single-product .banner--info {
  z-index: 2;
  text-align: center;
  position: absolute;
  left: 0;
  width: 100%;
  transition: all .35s;
  padding: 20px 20px 40px;
  -webkit-transform: translate3d(0, -webkit-calc(100% - 70px), 0);
  transform: translate3d(0, calc(100% - 58px), 0);
  bottom: 0;
}

.beauty-single-product .banner--info .title {
  font-size: 18px;
  font-weight: 700;
  position: relative;
  padding-bottom: 30px;
  font-family: "Playfair Display", serif;
}

.beauty-single-product .banner--info .title:after {
  content: "";
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: transparent;
  width: 30px;
  position: absolute;
  left: 50%;
  margin-left: -15px;
  bottom: 15px;
  transition: all .35s;
  border-top-color: inherit;
}

.beauty-single-product .banner--info .banner--info-content {
  opacity: 0;
  visibility: hidden;
  transition: all .35s;
}

.beauty-single-product .banner--info .banner--info-content p {
  color: #333;
}

.beauty-single-product .banner--info .banner--info-content a {
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1px;
  text-decoration: underline;
  color: #333;
}

.beauty-single-product .banner--info .banner--info-content a:hover {
  text-decoration: inherit;
}

.beauty-single-product:hover .banner--image a {
  -webkit-transform: scale(1.01) translate3d(0, -50px, 0);
  transform: scale(1.01) translate3d(0, -50px, 0);
}

.beauty-single-product:hover .banner--image a::before {
  opacity: 1;
}

.beauty-single-product:hover .banner--info {
  transform: translate3d(0, 0, 0);
}

.beauty-single-product:hover .banner--info .banner--info-content {
  opacity: 1;
  visibility: visible;
}

.beauty-single-product .color-1 {
  background: #ffc7d4;
}

.beauty-single-product .color-2 {
  background: #eef48f;
}

.beauty-single-product .color-3 {
  background: #a7adbc;
}

.beauty-single-product .color-4 {
  background: #9fc539;
}

.beauty-single-product .color-5 {
  background: #baf2ef;
}

.beauty-single-product .color-6 {
  background: #b6f4d2;
}

.beauty-single-product .color-7 {
  background: #2ec4b6;
}

.beauty-single-product .color-8 {
  background: #ffcb65;
}

.spa-service-item {
  position: relative;
  overflow: hidden;
}

.spa-service-item .banner--info {
  z-index: 2;
  text-align: center;
  position: absolute;
  left: 50%;
  max-width: 70%;
  transition: all .35s;
  padding: 20px;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.spa-service-item .banner--info .title {
  font-size: 40px;
  line-height: 1.15;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .spa-service-item .banner--info .title {
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .spa-service-item .banner--info .title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .spa-service-item .banner--info .title {
    font-size: 28px;
  }
}

.spa-service-item .banner--info .banner--btn {
  border: 2px solid #fff;
  color: #fff;
  font-size: 12px;
  padding: 20px 30px;
  letter-spacing: 1px;
  width: 360px;
  display: block;
  margin: 20px auto 0;
  max-width: 100%;
  transition: all 250ms ease-out;
}

.spa-service-item .banner--info .banner--btn:hover {
  background: #fff;
  color: #000000;
  border: 2px solid #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .spa-service-item .banner--info .banner--btn {
    padding: 10px 20px;
    width: 220px;
  }
}

@media only screen and (max-width: 767px) {
  .spa-service-item .banner--info .banner--btn {
    width: 220px;
    padding: 10px 20px;
  }
}

.spa-service-item:hover .image img {
  transform: scale(1.1);
}

.single-banner--three {
  display: block;
  position: relative;
  overflow: hidden;
}

.single-banner--three img {
  width: 100%;
}

.single-banner--three .banner--info {
  position: absolute;
  max-width: 90%;
  left: 50%;
  transition: all .45s ease-out;
  z-index: 2;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.single-banner--three .banner--info .sub-title {
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 5px;
}

.single-banner--three .banner--info .title {
  font-size: 36px;
  color: #ffffff;
  letter-spacing: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner--three .banner--info .title {
    font-size: 26px;
  }
}

.single-banner--three .banner--info .text-btn {
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;
  color: #ffffff;
  font-size: 12px;
  margin-top: 30px;
  display: inline-block;
}

.single-banner--three:hover .banner-images img {
  transform: scale(1.07);
}

.vc_col-sm-4 {
  width: 28.125%;
}

@media only screen and (max-width: 767px) {
  .vc_col-sm-4 {
    width: 100%;
  }
}

.vc_col-sm-4:nth-child(2) {
  width: 43.75%;
}

@media only screen and (max-width: 767px) {
  .vc_col-sm-4:nth-child(2) {
    width: 100%;
  }
}

/*======================================
=           13. Testimonial Css           =       
========================================*/
.testimonial-quote i {
  font-size: 20px;
}

.single-testimonial-item {
  max-width: 800px;
  margin: auto;
}

.single-testimonial-item .testimonial-image {
  width: 90px;
  height: 90px;
  margin: auto;
}

.single-testimonial-item .testimonial-image img {
  border-radius: 50%;
  border: 1px solid #333;
}

.single-testimonial-item .testimonial-image--two {
  width: 40px;
  height: 40px;
  margin: auto;
  text-align: center;
}

.single-testimonial-item .testimonial_item--info .testimonial-excerpt {
  padding-top: 10px;
  margin: 0 auto;
  font-size: 36px;
  line-height: 1.34;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .single-testimonial-item .testimonial_item--info .testimonial-excerpt {
    font-size: 26px;
  }
}

.single-testimonial-item .testimonial_item--info .testimonial_author .testimonial-title {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  margin-right: 10px;
}

.single-testimonial-item .testimonial_item--info .testimonial_author .testimonial_client {
  font-style: italic;
  display: inline-block;
  font-family: "Playfair Display", serif;
}

.single-testimonial-item .testimonial_item--info--two .testimonial-excerpt {
  padding-top: 10px;
  margin: 0 auto;
  font-size: 36px;
  line-height: 1.34;
  color: #d3b48c;
}

@media only screen and (max-width: 767px) {
  .single-testimonial-item .testimonial_item--info--two .testimonial-excerpt {
    font-size: 26px;
  }
}

.single-testimonial-item .testimonial_item--info--two .testimonial_author .testimonial-title {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #fff;
}

.single-testimonial-bakery {
  max-width: 800px;
  margin: auto;
}

.single-testimonial-bakery .testimonial_item--info .testimonial-excerpt {
  padding-top: 10px;
  margin: 0 auto;
  font-size: 36px;
  line-height: 1.34;
  color: #ffffff;
}

.single-testimonial-bakery .testimonial_item--info .testimonial_author .testimonial_client {
  text-transform: uppercase;
  color: #ffffff;
  display: block;
}

.testimonial-image--two {
  width: 40px;
  height: 40px;
  margin: auto;
  text-align: center;
}

.testimonial-image--three {
  width: auto;
  height: auto;
  margin: auto;
  text-align: center;
  display: block;
}

.testimonial-slider-active .slick-dots {
  text-align: center;
}

.testimonial-slider-active .slick-dots li {
  display: inline-block;
  margin: 5px;
  transition: all 0.3s ease-in-out;
}

.testimonial-slider-active .slick-dots li button {
  font-size: 0px;
  border: none;
  background: #999;
  height: 8px;
  width: 8px;
  padding: 0;
  border-radius: 5000px;
  transition: all 0.3s ease-in-out;
}

.testimonial-slider-active .slick-dots li.slick-active button {
  background: #000;
  height: 9px;
  width: 9px;
}

.ht-testimonial--four {
  background: #fff none repeat scroll 0 0;
  margin-top: 53px;
  position: relative;
  text-align: center;
  margin: 10px;
}

.ht-testimonial--four .ht-testimonial-thumb {
  margin-top: 52px;
}

.ht-testimonial--four .ht-testimonial-thumb img {
  border-radius: 50%;
  box-sizing: content-box;
  left: 50%;
  max-width: 100px;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
}

.ht-testimonial--four .ht-testimonial-content {
  margin-top: 0;
  padding: 60px 30px 30px 30px;
}

.ht-testimonial--four .ht-testimonial-content .ht-testimonial-text {
  margin-bottom: 20px;
}

.ht-testimonial--four .ht-testimonial-content .ht-testimonial-text p {
  font-size: 14px;
  line-height: 1.75;
  margin: 0;
  color: #000;
}

.ht-testimonial--four .ht-testimonial-info {
  position: relative;
}

.ht-testimonial--four .ht-testimonial-info::before {
  content: "";
  width: 25px;
  display: block;
  height: 3px;
  margin: 25px auto 15px;
  background-image: linear-gradient(90deg, #d1ecff 0%, #efcdcd 100%);
}

.ht-testimonial--four .ht-testimonial-position {
  font-size: 14px;
}

.testimonial-gradient-bg {
  background: linear-gradient(left, #ffeebb 0%, #95ffe3 98%);
}

/*======================================
=         14. Brand Css            =
=======================================*/
.single-brand-item {
  justify-content: center;
  display: flex;
}

.single-brand-item a {
  display: block;
}

.single-brand-item a img {
  opacity: .35;
  -webkit-transition: .5s;
  transition: .5s;
}

.single-brand-item a:hover img {
  opacity: 1;
}

.brand-slider-active .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -10px;
  z-index: 3;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  transition: all 0.3s ease-in-out;
}

.brand-slider-active .slick-arrow.arrow-next {
  right: -10px;
  left: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .brand-slider-active .slick-arrow {
    left: 0;
  }
  .brand-slider-active .slick-arrow.arrow-next {
    right: 0px;
    left: auto;
  }
}

/*======================================
=         15. Countdown Css            =
=======================================*/
.offer-product-image {
  position: relative;
}

.offer-product-image .product-content {
  position: absolute;
  right: 5%;
  top: 25%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.offer-product-image .product-content .onsale {
  background-color: #d3b48c;
  width: 67px;
  line-height: 67px;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  font-size: 13px;
  position: static;
  display: block;
  float: right;
  margin-right: 20px;
  color: #fff;
}

.offer-product-image .product-content .onsale-two {
  background-color: #dacb50;
  width: 67px;
  line-height: 67px;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  font-size: 13px;
  position: static;
  display: block;
  float: right;
  margin-right: 20px;
  color: #fff;
}

.offer-product-image .product-content.onsale-position {
  right: 5%;
  top: 0%;
}

.offer-product-image .product-content .product-price {
  display: flex;
  align-items: flex-end;
}

.offer-product-image .product-content .product-price .old-price {
  font-size: 18px;
  color: #8a8a8a;
  text-decoration: line-through;
}

.offer-product-image .product-content .product-price .new-price {
  font-size: 36px;
  color: #36393e;
  letter-spacing: -2px;
}

.countdown-deals {
  display: flex;
  justify-content: center;
}

.countdown-deals.counter-style--one .single-countdown {
  margin: 10px;
  position: relative;
  width: 76px;
  height: 76px;
  border: 1px solid rgba(69, 69, 69, 0.2);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .countdown-deals.counter-style--one .single-countdown {
    margin: 5px;
    width: 60px;
    height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .countdown-deals.counter-style--one .single-countdown {
    margin: 5px;
    width: 60px;
    height: 60px;
  }
}

.countdown-deals.counter-style--one .single-countdown .single-countdown__time {
  display: block;
  line-height: 1;
  font-size: 18px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .countdown-deals.counter-style--one .single-countdown .single-countdown__time {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .countdown-deals.counter-style--one .single-countdown .single-countdown__time {
    font-size: 16px;
  }
}

.countdown-deals.counter-style--one .single-countdown .single-countdown__text {
  margin-top: 5px;
  display: block;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  font-style: italic;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .countdown-deals.counter-style--one .single-countdown .single-countdown__text {
    font-size: 14px;
    margin-top: 0px;
  }
}

.countdown-deals.counter-style--two .single-countdown {
  margin: 10px;
  position: relative;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .countdown-deals.counter-style--two .single-countdown {
    margin: 5px;
    width: 60px;
    height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .countdown-deals.counter-style--two .single-countdown {
    margin: 5px;
    width: 60px;
    height: 60px;
  }
}

.countdown-deals.counter-style--two .single-countdown .single-countdown__time {
  display: block;
  line-height: 1;
  font-size: 18px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .countdown-deals.counter-style--two .single-countdown .single-countdown__time {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .countdown-deals.counter-style--two .single-countdown .single-countdown__time {
    font-size: 16px;
  }
}

.countdown-deals.counter-style--two .single-countdown .single-countdown__text {
  margin-top: 5px;
  display: block;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  font-style: italic;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .countdown-deals.counter-style--two .single-countdown .single-countdown__text {
    font-size: 14px;
    margin-top: 0px;
  }
}

.countdown-deals.counter-style--three .single-countdown {
  margin: 10px;
  position: relative;
  width: 25%;
  height: 76px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .countdown-deals.counter-style--three .single-countdown {
    margin: 5px;
    width: 60px;
    height: 60px;
  }
}

.countdown-deals.counter-style--three .single-countdown .single-countdown__time {
  display: block;
  font-size: 36px;
  font-weight: 400;
  line-height: 1.3;
  font-family: "Playfair Display", serif;
  color: #111;
  margin-bottom: 10px;
}

.countdown-deals.counter-style--three .single-countdown .single-countdown__text {
  margin-top: 5px;
  display: block;
  padding: 0;
  font-size: 14px;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .countdown-deals.counter-style--three .single-countdown .single-countdown__text {
    font-size: 14px;
    margin-top: 0px;
  }
}

.coming-soon-content-area {
  height: 950px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .coming-soon-content-area {
    height: 750px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-content-area {
    height: 650px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-content-area {
    height: 650px;
  }
  .coming-soon-content-area .coming-soon-text {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-content-area {
    height: 550px;
  }
  .coming-soon-content-area .coming-soon-text {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .coming-soon-content-area {
    height: 450px;
  }
  .coming-soon-content-area .coming-soon-text {
    margin-top: 50px;
  }
}

.error-defaut-bg {
  background: #fbece6;
}

/*======================================
=         16. Choose Css            =
=======================================*/
.single-choose-us-item {
  display: flex;
}

.single-choose-us-item .image {
  flex: 0 0 auto;
  width: 180px;
}

@media only screen and (max-width: 575px) {
  .single-choose-us-item .image {
    width: 85px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-choose-us-item .image {
    width: 85px;
  }
}

.single-choose-us-item .choose-content {
  margin-left: 20px;
}

.single-choose-us-item .choose-content .title {
  margin-bottom: 20px;
}

.single-choose-us-item .choose-content p {
  font-size: 12px;
}

/*======================================
=         17. Team Css            =
=======================================*/
.single-team-item {
  position: relative;
}

.single-team-item .team-image {
  position: relative;
  display: block;
}

.single-team-item .team-image img {
  width: 100%;
}

.single-team-item .team-image.filter-image img {
  filter: grayscale(100%);
}

.single-team-item .team-info {
  text-align: center;
}

.single-team-item .team-info .team-info-title {
  margin-top: 20px;
}

.single-team-item .team-info .team-info-title .team-role {
  font-size: 12px;
}

.single-team-item .team-info .item--social {
  font-size: inherit;
  position: absolute;
  bottom: auto;
  width: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 250ms ease-out 50ms;
  transform: translate(0, 30px);
}

.single-team-item .team-info .item--social a {
  display: inline-block;
  padding: 0 10px;
}

.single-team-item .entry-excerpt {
  padding-top: 10px;
  font-size: 12px;
  line-height: 2;
  min-height: 30px;
  transition: all 250ms ease-out 50ms;
}

.single-team-item:hover .item--social {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 10px);
  -webkit-transform: translate(0, 10px);
}

.single-team-item:hover .entry-excerpt {
  transform: translate(0, -20px);
  -webkit-transform: translate(0, -20px);
  opacity: 0;
  visibility: hidden;
}

.single-team-item:hover .filter-image img {
  filter: grayscale(0%);
}

/*======================================
=         18. Fun Factor Css            =
=======================================*/
.fun-fact--one {
  display: flex;
  margin-top: 30px;
}

.fun-fact--one .fun-fact-content {
  max-width: 300px;
  margin-left: 50px;
}

@media only screen and (max-width: 575px) {
  .fun-fact--one .fun-fact-content {
    margin-left: 10px;
  }
}

.fun-fact--two p {
  font-size: 14px;
}

.fun-fact--three {
  margin-top: 30px;
}

.fun-fact--three .fun-fact-content {
  text-align: center;
}

.bakery-banner-component {
  height: 100%;
}

.openig-time {
  text-align: center;
  padding: 60px 30px;
}

.openig-time .time-list {
  color: #ffffff;
}

.openig-time .bakery-contact {
  margin-top: 30px;
}

.openig-time .bakery-contact .compt_item {
  color: #ffffff;
  margin: 10px;
  display: inline-block;
}

.openig-time .bakery-contact .compt_item span {
  margin-left: 10px;
}

/*======================================
=         19. Bakery Item Css            =
=======================================*/
.bakery-item-box {
  background: rgba(255, 255, 255, 0.9);
  padding: 2%;
  position: relative;
}

.bakery-item-box::before {
  content: "";
  background-image: url(//helas.la-studioweb.com/wp-content/uploads/2018/01/m14-corner-1.png), url(//helas.la-studioweb.com/wp-content/uploads/2018/01/m14-corner-2.png), url(//helas.la-studioweb.com/wp-content/uploads/2018/01/m14-corner-3.png), url(//helas.la-studioweb.com/wp-content/uploads/2018/01/m14-corner-4.png);
  background-position: left top,right top,right bottom,left bottom;
  position: absolute;
  left: 20px;
  top: 20px;
  right: 20px;
  bottom: 20px;
  display: block;
  background-repeat: no-repeat;
}

.bakery-single-item {
  padding: 60px 30px 30px;
  text-align: center;
}

.bakery-single-item .title-img {
  margin-bottom: 30px;
}

.bakery-item {
  display: flex;
  padding: 10px;
  margin-bottom: 20px;
}

.bakery-item .box-icon {
  margin-right: 15px;
  width: 170px;
}

.bakery-item .box-contents {
  text-align: center;
}

.bakery-item .box-contents .price-size {
  color: #333;
  font-size: 18px;
}

/*=============================================
=            20. Accordions Css           =
=============================================*/
.faq-wrapper .panel.panel-default {
  border: 1px solid #ebebeb;
  margin-bottom: 20px;
}

.faq-wrapper .card, .faq-wrapper .card-header {
  border-radius: 0;
}

.faq-wrapper .card {
  border-bottom: none;
  margin-bottom: 20px;
  background: transparent;
  border: none;
}

.faq-wrapper .card:last-child {
  margin-bottom: 0;
}

.faq-wrapper .card-header {
  padding: 0;
  border-bottom: 0;
  background-color: transparent;
}

.faq-wrapper .card-header h5 button {
  border: none;
  width: 100%;
  text-align: left;
  color: #000000;
  padding: 0px 0px 8px;
  position: relative;
  overflow-wrap: break-word;
  white-space: normal;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  border-radius: 5px;
  background: transparent;
  border-bottom: 1px solid #ddd;
  text-transform: uppercase;
}

.faq-wrapper .card-header h5 button span {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 479px) {
  .faq-wrapper .card-header h5 button span {
    right: 15px;
  }
}

.faq-wrapper .card-header h5 button span i {
  font-size: 14px;
  visibility: hidden;
  opacity: 0;
  display: none;
}

.faq-wrapper .card-header h5 button:hover, .faq-wrapper .card-header h5 button:focus {
  text-decoration: none;
  color: #000000;
}

.faq-wrapper .card-header h5 button[aria-expanded="true"] {
  background: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid transparent;
}

.faq-wrapper .card-header h5 button[aria-expanded="true"] span i:nth-child(2) {
  visibility: visible;
  opacity: 1;
  display: inline;
}

.faq-wrapper .card-header h5 button[aria-expanded="true"]:before {
  height: 100%;
}

.faq-wrapper .card-header h5 button[aria-expanded="false"] span i:nth-child(1) {
  visibility: visible;
  opacity: 1;
  display: inline;
}

.faq-wrapper .card-body {
  padding: 5px 0px 20px;
}

.frequently-item ul li.has-sub, .frequently-item ul li.children {
  border-bottom: 1px solid #e1e2e4;
  padding: 20px 0;
}

.frequently-item ul li.has-sub:last-child, .frequently-item ul li.children:last-child {
  border-bottom: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .frequently-item ul li.has-sub, .frequently-item ul li.children {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .frequently-item ul li.has-sub:not(:last-child), .frequently-item ul li.children:not(:last-child) {
    margin-bottom: 20px;
  }
}

.frequently-item ul li.has-sub > a, .frequently-item ul li.children > a {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  font-weight: 500;
  cursor: pointer;
}

.frequently-item ul li.has-sub > a i, .frequently-item ul li.children > a i {
  margin-right: 10px;
}

.frequently-item ul li.has-sub > ul.frequently-body, .frequently-item ul li.children > ul.frequently-body {
  display: none;
  padding-top: 20px;
}

.frequently-item ul li.has-sub > ul.frequently-body li, .frequently-item ul li.children > ul.frequently-body li {
  font-size: 14px;
  line-height: 2;
  color: #000000;
  padding: 0 22px;
}

.frequently-item ul li.has-sub.open a, .frequently-item ul li.children.open a {
  color: #000000;
}

.frequently-item ul li.has-sub.open a i:before, .frequently-item ul li.children.open a i:before {
  content: "\ea68";
}

/*=============================================
=            21. Counterup Css           =
=============================================*/
.bg-counterup {
  background: #f3e9df;
}

.single-service-wrap {
  text-align: center;
  margin-top: 30px;
}

.single-service-wrap .icon {
  margin-bottom: 15px;
}

.single-service-wrap .content .title {
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 600;
  margin-bottom: 10px;
}

/*=============================================
=            22. Video Bnanre Css           =
=============================================*/
.lg-bnner-title {
  font-size: 86px;
  line-height: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lg-bnner-title {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lg-bnner-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .lg-bnner-title {
    font-size: 34px;
  }
}

/*=======================================
=          23. Hero Slider Css           =
=========================================*/
.bg-img {
  background-color: #eee;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.single-hero-slider-one, .hero-slider-three {
  height: 900px;
  display: flex !important;
  align-items: center;
  background-size: cover;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-hero-slider-one, .hero-slider-three {
    height: 800px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-one, .hero-slider-three {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-one, .hero-slider-three {
    height: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-one, .hero-slider-three {
    height: 400px;
  }
}

@media only screen and (max-width: 575px) {
  .single-hero-slider-one, .hero-slider-three {
    height: 400px;
  }
}

.single-hero-slider-one .slider-inner-images, .hero-slider-three .slider-inner-images {
  margin-top: 150px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-hero-slider-one .slider-inner-images, .hero-slider-three .slider-inner-images {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-one .slider-inner-images, .hero-slider-three .slider-inner-images {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-one .slider-inner-images, .hero-slider-three .slider-inner-images {
    margin-top: 135px;
  }
}

@media only screen and (max-width: 575px) {
  .single-hero-slider-one .slider-inner-images img, .hero-slider-three .slider-inner-images img {
    width: 200px;
  }
}

.single-hero-slider-one .hero-slider-content, .hero-slider-three .hero-slider-content {
  margin-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-one .hero-slider-content, .hero-slider-three .hero-slider-content {
    margin-bottom: 120px;
  }
}

@media only screen and (max-width: 575px) {
  .single-hero-slider-one .hero-slider-content, .hero-slider-three .hero-slider-content {
    position: absolute;
    top: 50%;
    right: 30px;
  }
}

.single-hero-slider-one .hero-slider-content .hero-title, .hero-slider-three .hero-slider-content .hero-title {
  font-size: 30px;
}

.single-hero-slider-8 {
  height: 900px;
  display: flex !important;
  align-items: center;
  background-size: cover;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-hero-slider-8 {
    height: 800px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-8 {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-8 {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-8 {
    height: 500px;
  }
}

@media only screen and (max-width: 575px) {
  .single-hero-slider-8 {
    height: 520px;
  }
}

.single-hero-slider-two {
  height: 900px;
  display: flex !important;
  align-items: center;
  background-size: cover;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-hero-slider-two {
    height: 800px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-two {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-two {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-two {
    height: 400px;
  }
}

@media only screen and (max-width: 575px) {
  .single-hero-slider-two {
    height: 400px;
  }
}

.single-hero-slider-two .hero-slider-content-two {
  position: relative;
}

.single-hero-slider-two .hero-slider-content-two .hero-images-01 {
  margin: auto;
  text-align: center;
  max-width: 600px;
  z-index: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-two .hero-slider-content-two .hero-images-01 {
    max-width: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-two .hero-slider-content-two .hero-images-01 {
    max-width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-two .hero-slider-content-two .hero-images-01 {
    max-width: 400px;
  }
}

.single-hero-slider-two .hero-slider-content-two .hero-slider-text-two {
  bottom: 50px;
  z-index: 3;
  position: absolute;
  text-align: center;
  width: 100%;
}

.single-hero-slider-two .hero-slider-content-two .hero-slider-text-two p {
  letter-spacing: 5px;
  margin-bottom: 30px;
}

.single-hero-slider-two .hero-slider-content-two--2 {
  text-align: center;
}

.single-hero-slider-two .hero-slider-content-two--2 .hero-images-02_1 {
  margin: auto;
  margin-bottom: 10px;
}

.single-hero-slider-two .hero-slider-content-two--2 .hero-images-02_2 {
  margin: auto;
  margin-bottom: -60px;
  z-index: 9;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-two .hero-slider-content-two--2 .hero-images-02_2 {
    max-width: 300px;
    margin-bottom: -20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-two .hero-slider-content-two--2 .hero-images-02_2 {
    max-width: 200px;
    margin-bottom: -20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-two .hero-slider-content-two--2 .hero-images-02_2 {
    max-width: 200px;
    margin-bottom: -20px;
  }
}

.single-hero-slider-two .hero-slider-content-two--2 .hero-images-02_3 {
  margin: auto;
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-two .hero-slider-content-two--2 .hero-images-02_3 {
    max-width: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-two .hero-slider-content-two--2 .hero-images-02_3 {
    max-width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-two .hero-slider-content-two--2 .hero-images-02_3 {
    max-width: 200px;
  }
}

.single-hero-slider-two .hero-slider-content-two--2 .hero-images-02_4 {
  margin: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-two .hero-slider-content-two--2 .hero-images-02_4 {
    max-width: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-two .hero-slider-content-two--2 .hero-images-02_4 {
    max-width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-two .hero-slider-content-two--2 .hero-images-02_4 {
    max-width: 200px;
  }
}

.hero-slider-one .slick-dots {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 767px) {
  .hero-slider-one .slick-dots {
    bottom: 2px;
  }
}

.hero-slider-one .slick-dots li {
  display: inline-block;
  margin: 10px;
}

.hero-slider-one .slick-dots li button {
  font-size: 0;
  border: none;
  height: 12px;
  width: 12px;
  background: #ddd;
  border-radius: 100%;
}

.hero-slider-one .slick-dots li.slick-active button {
  background: #d3b48c;
}

.hero-slider-three .slick-arrow, .hero-slider-four .slick-arrow, .hero-slider-five .slick-arrow {
  position: absolute;
  left: 30px;
  right: auto;
  top: 50%;
  transform: translateX(-50%);
  height: 40px;
  width: 40px;
  line-height: 40px;
  background: #fff;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ddd;
  z-index: 1;
}

.hero-slider-three .slick-arrow.arrow-next, .hero-slider-four .slick-arrow.arrow-next, .hero-slider-five .slick-arrow.arrow-next {
  right: 0px;
  left: auto;
}

.hero-slider-three .slick-arrow:hover, .hero-slider-four .slick-arrow:hover, .hero-slider-five .slick-arrow:hover {
  background: #d3b48c;
}

@media only screen and (max-width: 575px) {
  .hero-slider-three .slick-arrow, .hero-slider-four .slick-arrow, .hero-slider-five .slick-arrow {
    opacity: 0;
  }
}

.hero-slider-three:hover .slick-arrow, .hero-slider-four:hover .slick-arrow, .hero-slider-five:hover .slick-arrow {
  opacity: 1;
}

.single-hero-slider-five {
  height: 100vh;
  align-items: center;
  display: flex !important;
  justify-content: center;
}

.single-hero-slider-five .hero-slider-content-three, .single-hero-slider-five .hero-slider-content-three--2 {
  position: relative;
  z-index: 3;
}

@media only screen and (max-width: 575px) {
  .single-hero-slider-five .inner-images img {
    width: 200px;
  }
}

@media only screen and (max-width: 575px) {
  .single-hero-slider-five {
    height: 460px;
  }
  .single-hero-slider-five .inner-images {
    position: absolute;
    bottom: 0;
    z-index: 0;
  }
}

.hero-fixd-slider-five .slick-arrow {
  position: absolute;
  left: 30px;
  right: auto;
  top: 85%;
  height: 40px;
  width: 40px;
  line-height: 40px;
  background: #fff;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ddd;
  z-index: 1;
}

.hero-fixd-slider-five .slick-arrow.arrow-next {
  left: 80px;
  z-index: 1;
}

.hero-fixd-slider-five .slick-arrow:hover {
  background: #d3b48c;
}

.hero-slider-content-three h1 {
  line-height: 40px;
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 30px;
}

.slick-current .hero-slider-content h1,
.slick-current .single-hero-slider-one h1,
.slick-current .hero-slider-content-three h1,
.slick-current .single-hero-slider-five h1,
.slick-current .hero-slider-content-16 h1 {
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
}

.slick-current .hero-slider-content p,
.slick-current .single-hero-slider-one p,
.slick-current .hero-slider-content-three p,
.slick-current .single-hero-slider-five p,
.slick-current .hero-slider-content-16 p {
  animation-name: fadeInLeft;
  animation-duration: 0.7s;
}

.slick-current .hero-slider-content .button-box,
.slick-current .single-hero-slider-one .button-box,
.slick-current .hero-slider-content-three .button-box,
.slick-current .single-hero-slider-five .button-box,
.slick-current .hero-slider-content-16 .button-box {
  animation-name: fadeInBottom;
  animation-duration: 0.9s;
}

.slick-current .hero-slider-content .slider-inner-images, .slick-current .hero-slider-content .inner-images,
.slick-current .single-hero-slider-one .slider-inner-images,
.slick-current .single-hero-slider-one .inner-images,
.slick-current .hero-slider-content-three .slider-inner-images,
.slick-current .hero-slider-content-three .inner-images,
.slick-current .single-hero-slider-five .slider-inner-images,
.slick-current .single-hero-slider-five .inner-images,
.slick-current .hero-slider-content-16 .slider-inner-images,
.slick-current .hero-slider-content-16 .inner-images {
  animation-name: bounceInUp;
  animation-duration: 1.9s;
}

.slick-current .hero-slider-content .button-box,
.slick-current .single-hero-slider-one .button-box,
.slick-current .hero-slider-content-three .button-box,
.slick-current .single-hero-slider-five .button-box,
.slick-current .hero-slider-content-16 .button-box {
  animation-name: bounceInUp;
  animation-duration: 1.4s;
}

.slick-current .single-hero-slider-two .dic {
  animation-name: bounceInUp;
  animation-duration: 0.9s;
}

.slick-current .single-hero-slider-two .hero-images-02_1 {
  animation-name: bounceInUp;
  animation-duration: 0.9s;
}

.slick-current .single-hero-slider-two .hero-images-02_2 {
  animation-name: bounceInUp;
  animation-duration: 1.0s;
}

.slick-current .single-hero-slider-two .hero-images-02_3 {
  animation-name: bounceInUp;
  animation-duration: 1.10s;
}

.slick-current .single-hero-slider-two .hero-images-02_4 {
  animation-name: bounceInUp;
  animation-duration: 1.20s;
}

.slick-current .single-hero-slider-two .button-box {
  animation-name: bounceInUp;
  animation-duration: 1.60s;
}

.slick-current .hero-slider-content-six h1 {
  animation-name: bounceInDown;
  animation-duration: .9s;
}

.slick-current .hero-slider-content-six p {
  animation-name: bounceInDown;
  animation-duration: 1.5s;
}

.slick-current .hero-slider-content-14 .image {
  animation-name: bounceInUp;
  animation-duration: 0.9s;
}

.hero-slider-content-six .title {
  font-size: 120px;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-six .title {
    font-size: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-six .title {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-six .title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slider-content-six .title {
    font-size: 36px;
  }
}

.hero-slider-content-seven .title {
  font-size: 72px;
  line-height: 1;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-seven .title {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-seven .title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slider-content-seven .title {
    font-size: 30px;
  }
}

.hero-slider-content-seven .dic {
  max-width: 650px;
  margin: auto;
}

.hero-slider-content-ten h1 {
  line-height: 30px;
  letter-spacing: 9px;
  font-weight: 700;
  font-size: 24px;
}

.font-family--slider {
  font-family: Satisfy;
}

.hero-slider-content-12_one .title {
  font-size: 50px;
  margin-bottom: 20px;
  letter-spacing: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-12_one .title {
    font-size: 38px;
    letter-spacing: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-12_one .title {
    font-size: 30px;
    letter-spacing: 5px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slider-content-12_one .title {
    font-size: 26px;
    letter-spacing: 5px;
  }
}

.hero-slider-content-12_one .dec_text {
  border-top: 2px solid #333;
  padding-top: 20px;
  max-width: 650px;
  margin: auto;
}

.hero-slider-content-12_two .images {
  max-width: 300px;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-12_two .images {
    max-width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-12_two .images {
    max-width: 200px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slider-content-12_two .images {
    max-width: 150px;
  }
}

.hero-slider-content-12_two .title {
  border-top: 1px solid #333;
  padding-top: 20px;
  max-width: 650px;
  margin: auto;
  letter-spacing: 10px;
}

.hero-slider-content-13 .title {
  line-height: 200px;
  letter-spacing: -10px;
  font-weight: 700;
  font-size: 200px;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 0.8;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-slider-content-13 .title {
    font-size: 150px;
    letter-spacing: -5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-13 .title {
    font-size: 90px;
    letter-spacing: -5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-13 .title {
    font-size: 90px;
    letter-spacing: -5px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-13 .title {
    font-size: 80px;
    letter-spacing: -2px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slider-content-13 .title {
    font-size: 50px;
    letter-spacing: -2px;
  }
}

.gradient-text {
  z-index: 2;
  color: #4cdfbe;
  background-image: linear-gradient(90deg, #4cdfbe 0%, #9fe346 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  border-color: #4cdfbe;
  transform-origin: 50% 50% 0px;
}

.hero-slider-content-14 .image {
  display: inline-block;
  margin: auto;
  max-width: 100%;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-14 .image img {
    width: 180px;
  }
}

.hero-slider-content-14 .button-box {
  display: block;
}

.hero-slider-content-14--3 .sub-title {
  font-weight: 400;
  font-size: 72px;
}

.hero-slider-content-14--3 .title {
  line-height: 180px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-14--3 .sub-title {
    font-size: 52px;
  }
  .hero-slider-content-14--3 .title {
    line-height: 80px;
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-14--3 .sub-title {
    font-size: 52px;
  }
  .hero-slider-content-14--3 .title {
    line-height: 80px;
    font-size: 60px;
  }
}

.single-hero-slider-four {
  height: 800px;
  display: flex !important;
  align-items: center;
  background-size: cover;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-four {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-four {
    height: 540px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-four {
    height: 500px;
  }
}

@media only screen and (max-width: 575px) {
  .single-hero-slider-four {
    height: 400px;
  }
}

.hero-slider-content-15 {
  margin-top: 100px;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-15 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slider-content-15 {
    margin-top: 50px;
  }
}

.hero-slider-content-15 .title {
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 8px;
  z-index: 1;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-15 .title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slider-content-15 .title {
    font-size: 16px;
  }
}

.hero-slider-content-15 .image-one {
  max-width: 380px;
  position: absolute;
  text-align: center;
  top: 100px;
  z-index: 3;
  left: 10%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-15 .image-one {
    max-width: 277px;
    left: 12%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-15 .image-one {
    max-width: 277px;
    left: 2%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-15 .image-one {
    max-width: 177px;
    left: 2%;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slider-content-15 .image-one {
    max-width: 147px;
    left: 2%;
    top: 40%;
  }
}

.hero-slider-content-15 .image-two {
  padding-top: 50px;
  max-width: 900px;
  margin: auto;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-15 .image-two {
    max-width: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-15 .image-two {
    max-width: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-15 .image-two {
    max-width: 400px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slider-content-15 .image-two {
    max-width: 260px;
  }
}

.hero-slider-content-15 .center-image {
  position: relative;
}

.hero-slider-content-15 .center-image .image-three {
  position: absolute;
  top: 15%;
  right: 22%;
  max-width: 180px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-15 .center-image .image-three {
    max-width: 127px;
    right: 28%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-15 .center-image .image-three {
    max-width: 115px;
    right: 20%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-15 .center-image .image-three {
    max-width: 80px;
    right: 25%;
    top: 25%;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slider-content-15 .center-image .image-three {
    max-width: 60px;
    right: 15%;
    top: 20%;
  }
}

.hero-slider-content-15 .image-four {
  position: absolute;
  right: 6%;
  top: 30%;
  width: 300px;
  z-index: 3;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-15 .image-four {
    max-width: 250px;
    top: 40%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-15 .image-four {
    max-width: 170px;
    top: 38%;
    right: 0%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-15 .image-four {
    max-width: 140px;
    top: 38%;
    right: 0%;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slider-content-15 .image-four {
    max-width: 70px;
    top: 58%;
    right: 0%;
  }
}

.hero-slider-content-15 .image-five {
  position: absolute;
  right: 18%;
  top: 14%;
  z-index: 1;
  width: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-15 .image-five {
    top: 15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-15 .image-five {
    max-width: 60px;
    right: 12%;
    top: 15%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-15 .image-five {
    max-width: 60px;
    right: 12%;
    top: 15%;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slider-content-15 .image-five {
    max-width: 60px;
    right: 12%;
    top: 25%;
  }
}

.hero-slider-content-15 .image-six {
  position: absolute;
  right: 10%;
  top: 23%;
  width: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-15 .image-six {
    top: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-15 .image-six {
    max-width: 60px;
    right: 2%;
    top: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-15 .image-six {
    max-width: 60px;
    right: 2%;
    top: 25%;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slider-content-15 .image-six {
    max-width: 40px;
    right: 2%;
    top: 35%;
  }
}

.slider-click-button {
  position: absolute;
  z-index: 9;
}

.slider-click-button.btn-1 {
  left: 8%;
  top: 4%;
}

.slider-click-button.btn-2 {
  left: 40%;
  top: 0%;
}

.slider-click-button.btn-3 {
  left: 40%;
  top: -27%;
}

.slider-click-button.btn-4 {
  left: 70%;
  top: -25%;
}

@media only screen and (max-width: 767px) {
  .slider-click-button.btn-4 {
    left: 30%;
    top: 5%;
  }
}

.slider-click-button.btn-5 {
  left: 40%;
  top: -55%;
}

@media only screen and (max-width: 767px) {
  .slider-click-button.btn-5 {
    left: 10%;
    top: -25%;
  }
}

.slider-click-button.btn-6 {
  left: 40%;
  top: 100%;
}

@media only screen and (max-width: 767px) {
  .slider-click-button.btn-6 {
    left: 10%;
    top: -34px;
  }
}

.slider-click-button .lamp-price {
  background: #4cdfbe;
  margin: 0px;
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50%;
  z-index: 9;
}

.slider-click-button .s_price {
  display: block;
  z-index: 22;
  background-color: #4cdfbe;
  text-align: center;
  line-height: 20px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 12px;
  border-color: rgba(255, 255, 255, 0);
  border-style: none;
  margin: 0px;
  border-radius: 50px;
  padding: 20px 0px;
  color: white;
  text-decoration: none;
  white-space: nowrap;
  width: 60px;
  height: 60px;
  opacity: 0;
  visibility: hidden;
  transform-origin: 50% 50% 0px;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  left: -27px;
  position: relative;
  top: -8px;
}

.slider-click-button .s_price::after {
  content: "";
  border-top: 15px solid #4cdfbe;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  bottom: -10px;
  left: 70%;
  transform: rotate(-30deg);
}

.slider-click-button .s_price.active {
  opacity: 1;
  visibility: visible;
}

.single-hero-slider-16 {
  height: 660px;
  display: flex !important;
  align-items: center;
  background-size: cover;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-16 {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-16 {
    height: 480px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider-16 {
    height: 420px;
  }
}

@media only screen and (max-width: 575px) {
  .single-hero-slider-16 {
    height: 400px;
  }
}

/*=======================================
=        24. Product Details Css           =
=========================================*/
.product-details-thumbs-2 {
  float: left;
  width: 105px;
}

.product-details-thumbs-2 .sm-image {
  margin-bottom: 5px;
}

.product-details-thumbs-2 .sm-image:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .product-details-thumbs-2 {
    float: inherit;
    width: 100%;
  }
  .product-details-thumbs-2 .sm-image {
    padding: 5px;
  }
}

.product-details-thumbs-2 .sm-image img {
  border: 1px solid transparent;
  opacity: 0.3;
}

@media only screen and (max-width: 575px) {
  .product-details-thumbs-2 .sm-image img {
    width: 100%;
  }
}

.product-details-thumbs-2 .slick-current .sm-image img {
  border: 1px solid #d3b48c;
  opacity: 1;
}

.product-details-images-2 {
  float: right;
  width: calc(100% - 110px);
  padding-left: 10px;
}

.product-details-images-2 .product-lg-image a {
  display: block;
}

.product-details-images-2 .product-lg-image a img {
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .product-details-images-2 {
    float: inherit;
    width: calc(100% - 0px);
    padding-left: 0px;
  }
}

.product-details-content .price {
  color: #999;
  font-weight: 400;
  font-size: 30px;
}

.product-details-content .on-sale-price {
  font-weight: 400;
}

.product-details-content .on-sale-price .new-price {
  color: #df0303;
  font-size: 30px;
  margin-right: 18px;
}

.product-details-content .on-sale-price .old-price {
  font-size: 20px;
  text-decoration: line-through;
  color: #999;
  margin-top: 5px;
}

.product-details-content .stock {
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0 15px;
}

.product-details-content .stock p {
  color: #000000;
}

.product-details-content .stock.in-stock p span {
  color: #3bc604;
}

.product-details-content .stock.out-of-stock p span {
  color: #df0303;
}

.variable-size-selector {
  max-width: 400px;
}

.product-details-scroll-images .lg-image {
  margin-bottom: 30px;
}

.product-details-scroll-images .lg-image:last-child {
  margin-bottom: 0;
}

.product-details-scroll-images .lg-image a {
  display: block;
}

.product-details-scroll-images .lg-image a img {
  width: 100%;
}

.content-center .product-variants {
  display: flex;
  justify-content: center;
}

.content-center .cart-quantity {
  display: flex;
  align-items: end;
  justify-content: center;
}

.group-cart-table .table td {
  text-align: center;
}

.group-cart-table .quantity-field label {
  margin-right: 5px;
}

.group-cart-table .quantity-field input {
  width: 50px;
  text-align: center;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .group-cart-table .table td {
    min-width: 200px;
  }
}

.product-details-tab .nav {
  justify-content: center;
  margin: 0 -10px;
}

.product-details-tab .nav li {
  margin: 0px 0px;
  position: relative;
  margin-bottom: 20px;
  margin: 10px 10px;
  flex-grow: 1;
  flex-basis: 0;
}

.product-details-tab .nav li a {
  font-weight: 500;
  font-size: 12px;
  display: block;
  text-align: center;
  border: 1px solid #ddd;
  padding: 20px 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-tab .nav li a {
    padding: 14px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-tab .nav li a {
    padding: 10px 40px;
  }
}

@media only screen and (max-width: 575px) {
  .product-details-tab .nav li a {
    padding: 10px 40px;
  }
}

.product-details-tab .nav li:hover > a {
  width: 100%;
}

.product_details_container {
  margin: -5px -10px;
}

.single-product-gallery {
  float: left;
  padding: 5px 10px;
  width: 50%;
}

.comments-reply-area .comment-input .comment-form-author, .comments-reply-area .comment-input .comment-form-email {
  width: 50%;
}

@media only screen and (max-width: 575px) {
  .comments-reply-area .comment-input .comment-form-author, .comments-reply-area .comment-input .comment-form-email {
    width: 100%;
  }
}

.feature_list li {
  margin-bottom: 5px;
}

.feature_list li i {
  margin-right: 10px;
  text-align: center;
  font-size: 16px;
}

.comment-form-comment .comment-notes {
  width: 100%;
  padding: 10px;
  border: 1px solid #cfcfcf;
  height: 140px;
}

.comment-form-comment label {
  display: block;
  margin-bottom: 1px;
}

.comment-form-comment label span {
  color: #d3b48c;
}

.comment-form-author, .comment-form-email, .comment-form-url {
  float: left;
  padding: 0 10px;
  width: 33.3333%;
}

@media only screen and (max-width: 575px) {
  .comment-form-author, .comment-form-email, .comment-form-url {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .comment-form-author, .comment-form-email, .comment-form-url {
    width: 100%;
  }
}

.comment-input {
  margin: 0 -10px;
  overflow: hidden;
}

.comment-input label {
  display: block;
  margin-bottom: 1px;
}

.comment-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #cfcfcf;
  height: 40px;
}

.comment-form-submit .comment-submit {
  background: #d3b48c;
  color: #ffffff;
  border: none;
  padding: 8px 15px;
  margin-top: 20px;
}

.comment-form-submit .comment-submit:hover {
  background: #000000;
}

.comments-reply-area .comment-input .comment-form-author, .comments-reply-area .comment-input .comment-form-email {
  width: 50%;
}

@media only screen and (max-width: 575px) {
  .comments-reply-area .comment-input .comment-form-author, .comments-reply-area .comment-input .comment-form-email {
    width: 100%;
  }
}

.comment-form-submit .comment-submit {
  background: #000000;
  color: #ffffff;
  border: none;
  padding: 4px 28px;
  margin-top: 20px;
}

.comment-form-submit .comment-submit:hover {
  background: #444;
}

.blog-wrap-col-3 .blog-contend h3 {
  font-size: 18px;
}

.blog-wrap-col-3 .blog-contend .blog-date-categori {
  margin-bottom: 10px;
}

.blog-wrap-col-3 .blog-contend .blog-date-categori ul li {
  display: inline-block;
}

.blog-wrap-col-3 .blog-contend .blog-date-categori ul li a {
  font-size: 14px;
  margin-right: 10px;
  font-weight: 400;
}

.blog-wrap-col-3 .blog-contend .blog-date-categori ul li a i {
  font-size: 14px;
  margin-right: 5px;
}

.blog-wrap-col-3 .blog-btn {
  display: inline-block;
  padding: 5px 15px;
}

.product-rating {
  display: flex;
  font-size: 14px;
  display: flex;
  margin-right: 8px;
  color: #ddd;
}

.product-rating .yellow {
  color: #F5A623;
}

.product-rating i {
  margin: 0;
}

.pro_review {
  display: flex;
}

.pro_review.ans {
  margin-bottom: 50px;
  margin-left: 50px;
  margin-top: 50px;
}

@media only screen and (max-width: 575px) {
  .pro_review.ans {
    margin-bottom: 30px;
    margin-left: 20px;
    margin-top: 30px;
  }
}

.review_thumb {
  margin-right: 20px;
  min-width: 8%;
}

@media only screen and (max-width: 767px) {
  .review_thumb {
    min-width: 25%;
  }
}

.review_info h4 {
  font-weight: 500;
}

.rating_send {
  border: 1px solid #dddddd;
  display: block;
  float: right;
  font-size: 20px;
  margin-top: -55px;
  padding: 2px 13px;
}

.rating_send a {
  font-size: 14px;
}

.review_date {
  margin-top: 5px;
}

.review_details p {
  margin-top: 5px;
}

.product-color-wrapper, .product-size-wrapper {
  color: #666;
  font-weight: 500;
}

.product-color-wrapper label, .product-size-wrapper label {
  color: #000000;
}

.product-size-menu li {
  margin-right: 20px;
}

.product-size-menu li a {
  padding: 0;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background: #f1f1f1;
  border-radius: 50%;
  border: 1px solid transparent;
  text-align: center;
  color: #333;
}

.product-size-menu li a.active {
  border: 1px solid #bfbfbf;
}

.product-color-menu li {
  margin-right: 20px;
}

.product-color-menu li a {
  padding: 0;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background: #f1f1f1;
  border-radius: 50%;
  border: 1px solid transparent;
  text-align: center;
  color: #333;
  position: relative;
}

.product-color-menu li a.active {
  background: #333;
}

.product-color-menu li a.active::after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  width: 6px;
  height: 10px;
  display: block;
  border: solid #eee;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 8px;
  left: 11px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quickview-button .btn--lg {
    padding: 0 22px;
  }
}

@media only screen and (max-width: 767px) {
  .quickview-button .btn--lg {
    padding: 0 22px;
  }
}

@media only screen and (max-width: 479px) {
  .quickview-button {
    margin-top: 10px;
  }
}

.variations select {
  width: 100%;
  height: 45px;
  line-height: 45px;
  border-color: #bfbfbf;
  border-radius: 0;
  padding: 0 15px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #000;
  cursor: pointer;
  background: #fff url("../images/icons/selector-icon.png") no-repeat center right 20px;
}

.variations select:focus {
  background: #f8f8f8 url("../images/icons/selector-icon.png") no-repeat center right 20px !important;
}

.groupped-table {
  width: 100%;
}

.groupped-table thead th {
  background-color: #f4f5f7;
  border: none;
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  padding: 12px 15px;
  width: 50%;
}

.groupped-table .product-quantity {
  text-align: right;
}

.groupped-table .product-groupped-item .product-title {
  font-size: 14px;
  width: max-content;
  font-weight: 500;
  color: #000;
  display: block;
}

.groupped-table .product-groupped-item .price {
  color: #999;
  font-size: 16px;
  line-height: 1;
  margin-top: 5px;
}

.groupped-table tr {
  border: 0;
  border-bottom: 1px solid #eaeaea;
}

.groupped-table .single-groupped-item th {
  padding: 15px 15px 14px;
  color: #666;
  width: inherit;
  text-align: left;
  border: none;
}

.groupped-table .single-groupped-item .quickview-quality {
  text-align: right;
}

.groupped-table .single-groupped-item input.input-text {
  width: 45px;
  border: 1px solid #ddd;
  padding: 2px 5px;
}

/*=======================================
=             25. Blog Css              =
=========================================*/
.lates-blog-item {
  display: flex;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .lates-blog-item {
    flex-direction: column;
  }
}

.lates-blog-item .lates-blog-thumbnail {
  min-width: 50%;
  margin-right: 20px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .lates-blog-item .lates-blog-thumbnail {
    min-width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

.lates-blog-item .lates-blog-thumbnail a {
  display: block;
  position: relative;
}

.lates-blog-item .lates-blog-thumbnail a img {
  width: 100%;
}

.lates-blog-item .lates-blog-thumbnail a::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: all  0.3s ease-in-out;
}

.lates-blog-item .lastes-blog-content .sub-title {
  font-size: 12px;
  text-transform: uppercase;
}

.lates-blog-item .lastes-blog-content .blog-meta {
  font-size: 12px;
}

.lates-blog-item .lastes-blog-content .read-more-btn {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
}

.lates-blog-item .lastes-blog-content .read-more-btn:hover {
  color: #d3b48c;
}

.lates-blog-item:hover .lates-blog-thumbnail a img {
  transform: scale(1.1);
}

.lates-blog-item:hover .lates-blog-thumbnail a::after {
  opacity: 1;
  visibility: visible;
}

.lates-blog-item-two {
  margin-top: 30px;
  position: relative;
}

.lates-blog-item-two::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
}

.lates-blog-item-two .bg-img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
}

.lates-blog-item-two .lastes-blog-content {
  padding: 30px;
  border: 1px solid #ddd;
  position: relative;
  transition: all 0s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 3;
}

.lates-blog-item-two .lastes-blog-content .sub-title {
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 3px;
  transition: all 0s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.lates-blog-item-two .lastes-blog-content a {
  transition: all 0s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.lates-blog-item-two .lastes-blog-content .blog-meta {
  font-size: 12px;
}

.lates-blog-item-two .lastes-blog-content .blog-meta,
.lates-blog-item-two .lastes-blog-content .dec {
  transition: all 0s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.lates-blog-item-two .lastes-blog-content .blog-meta a,
.lates-blog-item-two .lastes-blog-content .dec a {
  transition: all 0s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.lates-blog-item-two .lastes-blog-content .read-more-btn {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  transition: all 0s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.lates-blog-item-two .lastes-blog-content .read-more-btn:hover {
  color: #d3b48c;
}

.lates-blog-item-two:hover {
  background-size: cover;
}

.lates-blog-item-two:hover .bg-img {
  opacity: 1;
}

.lates-blog-item-two:hover::after {
  opacity: 1;
}

.lates-blog-item-two:hover .lastes-blog-content {
  opacity: 1;
}

.lates-blog-item-two:hover .lastes-blog-content .sub-title, .lates-blog-item-two:hover .lastes-blog-content h5, .lates-blog-item-two:hover .lastes-blog-content .blog-meta, .lates-blog-item-two:hover .lastes-blog-content .dec, .lates-blog-item-two:hover .lastes-blog-content .read-more-btn {
  color: #ffffff;
}

.single-blog-item-three {
  position: relative;
  margin-top: 30px;
}

.single-blog-item-three .blog-thumbnail {
  overflow: hidden;
  position: relative;
}

.single-blog-item-three .blog-thumbnail .blog-image {
  display: block;
  position: relative;
}

.single-blog-item-three .blog-thumbnail .blog-image img {
  width: 100%;
}

.single-blog-item-three .blog-thumbnail .blog-image::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: all  0.3s ease-in-out;
}

.single-blog-item-three .blog-thumbnail .blog_item--category-link a {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  background: #d3b48c;
  padding: 6px 15px;
  transform: translateX(-50%);
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  z-index: 2;
}

.single-blog-item-three .blog-content {
  margin-top: 20px;
}

.single-blog-item-three .blog-content .blog_item--meta {
  margin-top: 5px;
  font-size: 12px;
}

.single-blog-item-three .blog-content .blog_item--excerpt {
  font-size: 14px;
  margin-top: 10px;
}

.single-blog-item-three:hover .blog-image {
  display: block;
}

.single-blog-item-three:hover .blog-image img {
  transform: scale(1.05);
}

.single-blog-item-three:hover .blog-image::before {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

.single-blog-item-four {
  position: relative;
  margin-top: 30px;
}

.single-blog-item-four .blog-thumbnail {
  overflow: hidden;
  position: relative;
}

.single-blog-item-four .blog-thumbnail .blog-image {
  display: block;
  position: relative;
}

.single-blog-item-four .blog-thumbnail .blog-image img {
  width: 100%;
}

.single-blog-item-four .blog-thumbnail .blog-image::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: all  0.3s ease-in-out;
}

.single-blog-item-four .blog-thumbnail .blog_item--category-link a {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  background: #d3b48c;
  padding: 6px 15px;
  transform: translateX(-50%);
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  z-index: 2;
}

.single-blog-item-four .blog-content {
  border: 1px solid #ddd;
  padding: 20px 30px;
}

.single-blog-item-four .blog-content .blog_item--meta {
  margin-top: 5px;
  font-size: 12px;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}

.single-blog-item-four .blog-content .blog_item--excerpt {
  font-size: 14px;
  margin-top: 15px;
}

.single-blog-item-four .blog-content .blog-read-more {
  margin-top: 20px;
}

.single-blog-item-four .blog-content .blog-read-more .link-text {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 600;
}

.single-blog-item-four:hover .blog-image {
  display: block;
}

.single-blog-item-four:hover .blog-image img {
  transform: scale(1.05);
}

.single-blog-item-four:hover .blog-image::before {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

.single-blog-large .blog-thumbnail {
  position: relative;
  display: block;
}

.single-blog-large .blog-thumbnail .blog-inner-icon {
  transition: all 0.15s ease-in-out;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  line-height: 45px;
  text-align: center;
  z-index: 4;
  font-size: 14px;
  background-color: #fff;
  color: #232324;
}

.single-blog-large .blog-thumbnail .blog-inner-icon:hover {
  color: #fff;
  background: #333;
}

.single-blog-large .blog-thumbnail img {
  width: 100%;
}

.single-blog-large .blog-thumbnail .blog-inner-video {
  transition: all 0.15s ease-in-out;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  z-index: 4;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #ddd;
  color: #ffffff;
}

.single-blog-large .blog-thumbnail .blog-inner-video:hover {
  color: #fff;
}

.single-blog-large .blog-thumbnail:hover img {
  transform: scale(1.04);
}

.single-blog-large .blog-thumbnail:hover .blog-overlay, .single-blog-large .blog-thumbnail:hover .blog-inner-icon {
  visibility: visible;
  opacity: 1;
}

.single-blog-large .blog-thumbnail .blog-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
  transition: opacity 0.25s ease-in-out;
  background-color: rgba(35, 35, 36, 0.5);
}

.single-blog-large .blog-content {
  max-width: 600px;
}

.single-blog-large.border-box {
  border: 1px solid #ddd;
  transition: all 0.3s ease-in-out;
  padding-bottom: 30px;
}

.single-blog-large.border-box .blog-content {
  padding: 0 30px;
}

.single-blog-large.border-box:hover {
  box-shadow: 0 0 38px 0 rgba(82, 109, 249, 0.33);
}

.blog-content-getro .blog-meta {
  font-size: 14px;
}

.single-blog-slick-active .slick-arrow {
  position: absolute;
  bottom: 40px;
  left: 50%;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background: #ddd;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  z-index: 1;
}

.single-blog-slick-active .slick-arrow:hover {
  background: #ffffff;
}

.single-blog-slick-active .slick-arrow.arrow-next {
  margin-right: -55px;
}

.single-blog-slick-active .slick-arrow.arrow-prv {
  margin-left: -55px;
}

.blog-post-feature .thumbnail {
  display: block;
  width: 100%;
}

.blog-post-feature .post-quote-text {
  padding: 0 50px;
  max-width: 800px;
  text-align: center;
  margin: auto;
}

@media only screen and (max-width: 575px) {
  .blog-post-feature .post-quote-text {
    padding: 0 20px;
  }
}

.blog-post-footer {
  margin-top: 40px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 575px) {
  .blog-post-footer {
    flex-direction: column;
  }
}

.tags-list {
  margin-top: 10px;
}

.sharing-single-posts {
  margin-top: 10px;
  position: relative;
  display: inline-block;
}

.sharing-single-posts .social--sharing {
  position: absolute;
  right: 0;
  top: -30px;
  opacity: 0;
}

.sharing-single-posts:hover .social--sharing {
  opacity: 1;
}

.post-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  align-items: center;
}

.post-navigation .nav-previous {
  width: 50%;
  padding-left: 30px;
  background: url(../images/icons/arrows-stre-left.png) no-repeat left center;
}

.post-navigation .nav-previous .post-title {
  font-family: "Playfair Display", serif;
  display: block;
}

.post-navigation .nav-next {
  width: 50%;
  padding-right: 30px;
  text-align: right;
  background: url(../images/icons/arrows-stre-right.png) no-repeat right center;
}

.post-navigation .nav-next .post-title {
  font-family: "Playfair Display", serif;
  display: block;
}

.author-info--blog {
  padding-left: 35px;
  position: relative;
  margin-bottom: 50px;
}

.author-info--blog .author-info--inner {
  background-color: #f7faff;
  padding-left: 50px;
}

.author-info--blog .author-info__avatar-inner {
  width: 70px;
  position: absolute;
  left: 0;
  top: 30px;
}

.author-info--blog img.avatar {
  width: 100%;
  border-radius: 50%;
}

.author-info--blog .author-info__description {
  padding: 30px;
}

.author-info--blog .author-info__description .social-media-link.style-default a {
  margin: 0 9px;
  font-size: 12px;
}

.upcoming-event-content .title {
  letter-spacing: 5px;
}

/*=======================================
=          26. About us Css            =
=========================================*/
.about-us-content h2 {
  font-family: "Mr Dafoe", cursive;
}

.banner-text-right {
  max-width: 570px;
  margin-left: auto;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-text-right {
    margin-top: 40px;
  }
}

.about-features-bg {
  background: #fff8f7;
}

.about-us-features-area .about-inner-banner-image {
  margin-top: -40px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-features-area .about-inner-banner-image {
    margin-top: 60px;
  }
}

.about-features-item {
  margin-top: 50px;
  max-width: 500px;
}

.about-features-item .about-features-top {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.about-features-item .about-features-top .icon {
  flex-basis: 0 0 auto 0;
  margin-right: 15px;
}

.about-features-item .about-features-top .title {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 2px;
}

/*==========================================
=           27. Shop style Css                =       
===========================================*/
.shop-top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
}

@media only screen and (max-width: 575px) {
  .shop-top-bar {
    display: block;
  }
}

.shop-top-bar .shop-top-bar-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .shop-top-bar .shop-top-bar-left {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left {
    display: flex;
  }
}

.shop-top-bar .shop-top-bar-left .shop-top-show {
  line-height: 1;
}

.shop-top-bar .shop-top-bar-left .shop-top-show span {
  font-weight: 700;
  line-height: 24px;
  display: block;
  line-height: 1;
}

.shop-top-bar .shop-top-bar-left .shop-page-list {
  margin-left: 45px;
  line-height: 1;
}

@media only screen and (max-width: 575px) {
  .shop-top-bar .shop-top-bar-left .shop-page-list {
    margin-left: 0px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left .shop-page-list {
    margin-left: 20px;
    margin-top: 0px;
  }
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li {
  display: inline-block;
  color: #777777;
  margin-right: 11px;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li:last-child {
  margin-right: 0px;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li.active {
  border-bottom: 1px solid #262626;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li.active a {
  color: #262626;
}

.shop-top-bar .shop-top-bar-left .shop-page-list ul li a {
  color: #777777;
}

.shop-top-bar .shop-top-bar-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop-top-bar .shop-top-bar-right .shop-filter {
  line-height: 1;
  position: relative;
}

.shop-top-bar .shop-top-bar-right .shop-filter a {
  color: #282828;
  font-weight: 700;
}

.shop-top-bar .shop-top-bar-right .shop-filter a i {
  color: #282828;
  font-weight: 700;
  margin-left: 3px;
  transition: all .4s ease 0s;
}

.shop-top-bar .shop-top-bar-right .shop-filter a i.angle-up {
  position: absolute;
  right: 1px;
  top: 0px;
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-filter a.active i.angle-down {
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-filter a.active i.angle-up {
  opacity: 1;
  visibility: visible;
}

.shop-top-bar .shop-top-bar-right .shop-short-by {
  position: relative;
  padding: 10px 0;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-right .shop-short-by {
    padding: 30px 0;
  }
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span {
  color: #282828;
  font-weight: 700;
  position: relative;
  cursor: pointer;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span i {
  color: #282828;
  font-weight: 700;
  margin-left: 3px;
  transition: all .4s ease 0s;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span i.angle-up {
  position: absolute;
  right: 3px;
  top: 1px;
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul {
  right: 0;
  top: 100%;
  position: absolute;
  background-color: #fff;
  padding: 7px;
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.2);
  transition: all 250ms ease-out;
  margin-top: 30px;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li {
  display: block;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li a {
  padding: 5px 10px;
  white-space: pre;
  display: block;
  border-radius: 4px;
  font-size: 0.92em;
  color: #777777;
  line-height: 24px;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li a:hover {
  color: #222;
  background-color: #ddd;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li.active a {
  color: #222;
  background-color: #ddd;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover span i.angle-down {
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover span i.angle-up {
  opacity: 1;
  visibility: visible;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover ul {
  opacity: 1;
  visibility: visible;
  margin-top: -5px;
}

.shop-top-bar .shop-top-bar-right .shop-tab a {
  color: #777777;
  font-size: 18px;
  margin-right: 20px;
}

.shop-top-bar .shop-top-bar-right .shop-tab a:last-child {
  margin-right: 0;
}

.shop-top-bar .shop-top-bar-right .shop-tab a.active {
  color: #262626;
}

.product-filter-wrapper {
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  font-size: 12px;
  padding: 40px 55px 34px;
  display: none;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper {
    padding: 40px 25px 34px;
  }
}

@media only screen and (max-width: 575px) {
  .product-filter-wrapper {
    padding: 40px 25px 34px;
  }
}

.product-filter-wrapper .product-filter h5 {
  font-size: 16px;
  margin: 0 0 17px;
  text-transform: capitalize;
  font-weight: 700;
}

@media only screen and (max-width: 575px) {
  .product-filter-wrapper .product-filter h5 {
    margin: 0 0 10px;
  }
}

.product-filter-wrapper .product-filter .price-filter ul li {
  margin-bottom: 5px;
  display: block;
}

.product-filter-wrapper .product-filter .price-filter ul li:last-child {
  margin-bottom: 0;
}

.product-filter-wrapper .product-filter .price-filter ul li a {
  color: #535353;
  font-size: 12px;
}

.product-filter-wrapper .product-filter .price-filter ul li a:hover {
  color: #d3b48c;
}

.product-filter-wrapper .product-filter .widget-nav-list li {
  position: relative;
  margin-bottom: 11px;
  padding: 1px 0;
  width: 49%;
  display: inline-block;
  font-size: 12px;
}

.product-filter-wrapper .product-filter .price-filter ul li {
  margin-bottom: 10px;
}

.product-filter-wrapper .product-filter .product-tags ul {
  width: 100%;
}

.product-filter-wrapper .product-filter .product-tags ul li {
  width: 50%;
  display: inline-block;
  float: left;
}

.product-filter-wrapper .product-filter .fliter-size ul li {
  display: inline-block;
  margin-right: 9px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper .product-filter .fliter-size ul li {
    margin-right: 2px;
  }
}

.product-filter-wrapper .product-filter .fliter-size ul li:last-child {
  margin-right: 0px;
}

.product-filter-wrapper .product-filter .fliter-size ul li a {
  color: #535353;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 9px 16px;
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper .product-filter .fliter-size ul li a {
    padding: 3px 8px;
  }
}

.product-filter-wrapper .product-filter .fliter-size ul li a:hover {
  color: #fff;
  border: 1px solid #d3b48c;
  background-color: #d3b48c;
}

.product-filter-wrapper .filter-close-wrap a {
  font-size: 16px;
  color: #d3b48c;
}

.product-filter-wrapper .filter-close-wrap a i {
  position: relative;
  top: 3px;
  font-size: 20px;
  margin-right: 3px;
}

/*==========================================
=        28. My account Page Css           =       
===========================================*/
.myaccount-box-wrapper {
  background: #fff;
  width: 100%;
}

.myaccount-box-wrapper .helendo-tabs ul .tab__item {
  width: 50%;
  text-align: center;
}

.myaccount-box-wrapper .helendo-tabs ul .tab__item a {
  color: #666;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 0;
  background-color: #ededed;
}

.myaccount-box-wrapper .helendo-tabs ul .tab__item a.active {
  background: #fff;
  color: #000000;
}

.myaccount-box-wrapper .content-modal-box {
  padding: 40px;
}

.myaccount-box-wrapper .content-modal-box .account-form-box .single-input {
  margin-top: 30px;
}

.myaccount-box-wrapper .content-modal-box .account-form-box .single-input input {
  padding: 10px 20px;
  text-align: left;
  width: 100%;
  color: #666;
  border: 1px solid #ccc;
}

.myaccount-box-wrapper .content-modal-box .checkbox-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myaccount-box-wrapper .content-modal-box .checkbox-wrap label {
  margin-bottom: .0rem;
}

@media only screen and (max-width: 479px) {
  .myaccount-box-wrapper .content-modal-box .checkbox-wrap {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.myaccount-box-wrapper .content-modal-box .checkbox-wrap a {
  font-size: 14px;
}

.myaccount-box-wrapper .content-modal-box .input-checkbox {
  opacity: 0;
  line-height: 1;
  height: inherit;
  display: none;
}

.myaccount-box-wrapper .content-modal-box span {
  display: inline-block;
  text-transform: inherit;
  position: relative;
  line-height: 1;
  font-size: 14px;
  cursor: pointer;
  padding: 0 0 0 30px;
  font-weight: 400;
}

.myaccount-box-wrapper .content-modal-box span::before {
  font-family: FontAwesome;
  top: -2px;
  left: 0px;
  transition: all 0.5s ease 0s;
  content: "";
  width: 19px;
  height: 19px;
  border: 2px solid;
  position: absolute;
  left: 0px;
  color: #e4e4e4;
}

.myaccount-box-wrapper .content-modal-box .input-checkbox:checked + span::before {
  content: "\f00c";
}

.myaccount-box-wrapper .helendo-tabs ul {
  justify-content: center;
}

.myaccount-box-wrapper .helendo-tabs ul .tab__item {
  width: auto;
  text-align: center;
  font-size: 24px;
  padding: 0 22px;
}

.myaccount-box-wrapper .helendo-tabs ul .tab__item a {
  color: #666;
  font-size: 24px;
  font-weight: 600;
  padding: 15px 0;
  background-color: transparent;
}

.myaccount-box-wrapper .helendo-tabs ul .tab__item a.active {
  background: transparent;
  color: #000000;
}

/*==============================================
=         29. Cart & Checkout Pages Css          =
================================================*/
h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 15px;
}

.header-color-gray.cart-table-content table thead > tr th {
  padding: 10px 0;
}

.header-color-gray.cart-table-content table tbody > tr td {
  padding: 20px 0px 20px;
}

.cart-table-content table {
  width: 100%;
}

.cart-table-content table thead > tr th {
  border-top: medium none;
  font-size: 14px;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
  color: #000000;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 10px 0 10px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content table thead > tr th {
    padding: 0 0 33px 0;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table thead > tr th {
    padding: 0 20px 33px 20px;
  }
}

.cart-table-content table thead > tr th .product-name {
  max-width: 500px;
}

.cart-table-content table thead > tr th .product-price {
  max-width: 300px;
}

.cart-table-content table tbody > tr td {
  font-size: 14px;
  padding: 40px 0px 40px;
  border-bottom: 1px solid #ddd;
}

.cart-table-content table tbody > tr td.product-remove {
  min-width: 40px;
}

.cart-table-content table tbody > tr td.product-remove a {
  color: #777777;
}

.cart-table-content table tbody > tr td.product-img {
  min-width: 150px;
}

.cart-table-content table tbody > tr td.product-name {
  width: 470px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.product-name {
    min-width: 300px;
  }
}

@media only screen and (max-width: 575px) {
  .cart-table-content table tbody > tr td.product-name {
    min-width: 250px;
  }
}

.cart-table-content table tbody > tr td.product-name a {
  font-size: 14px;
  font-weight: 500;
}

.cart-table-content table tbody > tr td.product-name a:hover {
  color: #d3b48c;
}

.cart-table-content table tbody > tr td.cart-quality {
  min-width: 180px;
}

.cart-table-content table tbody > tr td.product-price {
  min-width: 150px;
}

.cart-table-content table tbody > tr td.product-price span {
  color: #1f2226;
  font-weight: 500;
}

.cart-table-content table tbody > tr td.product-stock-status {
  min-width: 180px;
  font-weight: 500;
}

.cart-table-content table tbody > tr td.product-stock-status p {
  color: #690;
  font-size: 16px;
}

.cart-table-content table tbody > tr td.product-total {
  min-width: 70px;
}

.cart-table-content table tbody > tr td.product-total span {
  font-weight: 600;
}

.cart-table-content table tbody > tr td.price-total {
  min-width: 70px;
}

.cart-table-content table tbody > tr td.price-total span {
  font-weight: 600;
}

.cart-table-content table tbody > tr td.product-wishlist-cart {
  text-align: right;
  min-width: 250px;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a {
  background-color: #232323;
  color: #fff;
  font-size: 16px;
  line-height: 1;
  padding: 14px 25px;
  text-transform: capitalize;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a:hover {
  background-color: #d3b48c;
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #CDCDCD;
  padding: 18px 0 0;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: block;
  }
}

.cart-shiping-update-wrapper .discount-code {
  position: relative;
  flex: 0 0 50%;
}

.cart-shiping-update-wrapper .discount-code input {
  padding: 0 115px 0 0;
  border: none;
  height: 34px;
  font-size: 12px;
  color: #282828;
  background-color: transparent;
  border-bottom: 1px solid #D8D8D8;
}

.cart-shiping-update-wrapper .discount-code button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
}

.cart-shiping-update-wrapper .cart-clear a {
  font-weight: 600;
  display: inline-block;
  margin: 3px 0 0 7px;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-clear {
    margin-top: 20px;
  }
}

.grand-total-wrap {
  border: 1px solid #bfbfbf;
  background: #F9F9F9;
  padding: 0px 30px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}

.grand-total-wrap h4 {
  font-weight: 800;
  font-size: 14px;
  margin: 0;
}

.grand-total-wrap .grand-total-content {
  padding: 30px 0 30px;
}

.grand-total-wrap .grand-total-content ul li {
  font-weight: bold;
  border-bottom: 1px solid #CDCDCD;
  padding-bottom: 16px;
  margin-bottom: 17px;
}

.grand-total-wrap .grand-total-content ul li:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.grand-total-wrap .grand-total-content ul li span {
  float: right;
  font-weight: 400;
}

.grand-total-wrap .grand-btn {
  margin-left: -30px;
  margin-right: -30px;
}

.grand-total-wrap .grand-btn a {
  background-color: #262626;
  color: #fff;
  text-align: center;
  font-size: 14px;
  display: block;
  padding: 23px 10px 20px;
}

.grand-total-wrap .grand-btn a:hover {
  background-color: #d3b48c;
}

.discount-code {
  max-width: 400px;
}

.discount-code input {
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #ccc;
  color: #000;
  border-radius: 0;
  padding: 15px 15px 13px;
}

.cart_totals {
  max-width: 400px;
}

/*-------- 26. Checkout style ---------*/
.customer-zone > p {
  position: relative;
  padding: 14px 30px 14px 60px;
  background-color: #f4f5f7;
  margin: 0;
}

.customer-zone > p::before {
  content: "\f058";
  color: #6dde6d;
  display: inline-block;
  position: absolute;
  font-family: fontawesome;
  left: 30px;
}

.checkout-login-info {
  border: 1px solid #ddd;
  padding: 30px;
  display: none;
  margin-top: 30px;
}

.checkout-login-info p {
  color: #777;
  margin: 0 0 22px;
}

.checkout-login-info .single-input input {
  border: 1px solid #CDCDCD;
  height: 50px;
  background-color: transparent;
  width: 100%;
  color: #777;
  font-size: 14px;
  padding: 0 20px;
}

.checkout-login-info .single-input input:focus {
  border: 1px solid #262626;
}

.checkout-login-info .single-input label {
  display: block;
  margin: 0 0 10px;
}

.checkout-login-info .single-input label span {
  color: red;
}

.checkout-coupon-info {
  border: 1px solid #ddd;
  padding: 30px;
  display: none;
  margin-top: 30px;
}

.checkout-coupon-info p {
  color: #777;
  margin: 0 0 22px;
}

.checkout-coupon-info form input[type="text"] {
  border: 1px solid #CDCDCD;
  height: 50px;
  background-color: transparent;
  width: 100%;
  color: #777;
  font-size: 14px;
  padding: 0 20px;
}

.checkout-coupon-info form input[type="text"]:focus {
  border: 1px solid #262626;
}

.checkout-coupon-info form input[type="submit"] {
  background: #262626 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 50px;
  margin-top: 15px;
  padding: 5px 30px 7px;
  text-transform: capitalize;
  font-weight: 400;
  width: inherit;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-coupon-info form input[type="submit"] {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-coupon-info form input[type="submit"] {
    padding: 5px 16px;
    margin-left: 5px;
  }
}

.checkout-coupon-info form input[type="submit"]:hover {
  background-color: #d3b48c;
}

.billing-info-wrap .billing-info label {
  display: block;
  margin: 0 0 10px;
}

.billing-info-wrap .billing-info label span {
  color: red;
}

.billing-info-wrap .billing-info input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #777;
  width: 100%;
}

.billing-info-wrap .billing-info input:focus {
  border: 1px solid #262626;
}

.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 20px;
}

.billing-info-wrap .billing-select label {
  display: block;
  margin: 0 0 10px;
}

.billing-info-wrap .billing-select label span {
  color: red;
}

.billing-info-wrap .billing-select .select-active {
  border: 1px solid #e6e6e6;
  color: #262626;
  border-radius: 0;
  background: transparent url(../images/icons/selector-icon.png) no-repeat center right 20px !important;
}

.billing-info-wrap .billing-select .select-active:focus {
  background: transparent;
}

.billing-info-wrap .billing-select .select2-container {
  display: block;
}

.billing-info-wrap .billing-select .select2-container .select2-choice {
  border: 1px solid #e6e6e6;
  background: transparent;
  -moz-appearance: none;
  height: 50px;
  padding: 2px 20px;
  color: #262626;
  cursor: pointer;
}

.billing-info-wrap .billing-select .select2-container .select2-choice .select2-chosen {
  height: 50px;
}

.billing-info-wrap .additional-info-wrap {
  margin: 3px 0 0;
}

.billing-info-wrap .additional-info-wrap label {
  display: block;
  margin: 0 0 10px;
  padding: 5px 0 0;
}

.billing-info-wrap .additional-info-wrap textarea {
  min-height: 120px;
  background-color: transparent;
  border: 1px solid #e8e8e8;
  padding: 20px;
  color: #8a8a8a;
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .billing-info-wrap.mr-100 {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .billing-info-wrap.mr-100 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .billing-info-wrap.mr-100 {
    margin-right: 0px;
  }
}

.select2-container--default .select2-selection--single {
  border: 1px solid #e6e6e6;
  border-radius: 0px;
  cursor: pointer;
  display: block;
  height: 50px;
  user-select: none;
  -webkit-user-select: none;
  line-height: 50px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  height: 50px;
  color: #262626;
  line-height: 50px;
  padding: 0px 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 50px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  height: 34px;
  background-color: transparent;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #0073aa;
  color: #fff;
}

.your-order-area {
  padding: 40px 45px 50px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  border: 1px solid #bfbfbf;
  background-color: #f6f6f6;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .your-order-area {
    padding: 40px 25px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .your-order-area {
    padding: 40px 12px 50px;
    margin-top: 30px;
  }
}

.your-order-area h3 {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin: 0 0 45px;
}

.your-order-area h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -14px;
  background-color: #000;
  width: 50px;
  height: 2px;
}

.your-order-area .your-order-wrap .your-order-info {
  border-bottom: 1px solid #CDCDCD;
}

.your-order-area .your-order-wrap .your-order-info ul li {
  padding: 0 0 7px;
}

.your-order-area .your-order-wrap .your-order-info ul li span {
  float: right;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-info.order-total {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-total ul li span {
  font-size: 18px;
}

.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #CDCDCD;
  padding: 24px 0 23px;
}

.your-order-area .your-order-wrap .your-order-middle ul li {
  font-size: 14px;
  margin: 0 0 11px;
}

.your-order-area .your-order-wrap .your-order-middle ul li:last-child {
  margin-bottom: 0;
}

.your-order-area .your-order-wrap .your-order-middle ul li span {
  float: right;
  font-size: 16px;
}

.your-order-area .your-order-wrap .payment-method {
  margin: 25px 0 0;
}

.your-order-area .your-order-wrap .payment-method h5 {
  text-transform: uppercase;
}

.your-order-area .your-order-wrap .payment-method p {
  color: #777;
  font-size: 12px;
  margin: 14px 0 0;
}

.your-order-area .your-order-wrap .condition-wrap {
  margin: 40px 0 0;
}

.your-order-area .your-order-wrap .condition-wrap p {
  color: #777;
  line-height: 1.88;
  margin: 0;
}

.your-order-area .your-order-wrap .condition-wrap p a {
  color: #777;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form input {
  border: 1px solid #9fa0a2;
  display: inline-block;
  height: 10px;
  width: 10px;
  position: relative;
  top: 6px;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form span {
  color: #262626;
  font-weight: 400;
  margin: 0 0 0 5px;
}

.your-order-area .your-order-wrap .condition-wrap .condition-form span span.star {
  color: red;
}

.your-order-area .Place-order a {
  background-color: #262626;
  display: block;
  cursor: pointer;
  padding: 22px 50px 21px;
  width: 100%;
  color: #fff;
  text-align: center;
}

.your-order-area .Place-order a:hover {
  background-color: #d3b48c;
}

.order-tracking-form-box input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #777;
  width: 100%;
  margin-top: 10px;
}

.order-tracking-form-box input:focus {
  border: 1px solid #262626;
}

.order-tracking-form-box input.billing-address {
  margin-bottom: 20px;
}

/*==============================================
=         30. Contact Us Pages Css          =
================================================*/
.googleMap-2 {
  height: 450px;
}

.contact-us-page-info-list .contact-info-list {
  margin-top: 20px;
  color: #000000;
}

.contact-us-page-info-list .contact-info-list .icon {
  margin-right: 10px;
}

.contact-us-box .single-input.your-name {
  position: relative;
}

.contact-us-box .single-input.your-name::before {
  content: "\f2c0";
  font-family: FontAwesome;
  position: absolute;
  right: 1.2em;
  top: 10px;
}

.contact-us-box .single-input.your-email {
  position: relative;
}

.contact-us-box .single-input.your-email::before {
  content: "\f003";
  font-family: FontAwesome;
  position: absolute;
  right: 1.2em;
  top: 10px;
}

.contact-us-box .single-input input, .contact-us-box .single-input textarea {
  width: 100%;
  border: 1px solid #ddd;
  margin-bottom: 30px;
  padding: 20px;
}

.contact-us-box .single-input input {
  padding: 10px 50px 10px 20px;
}

.contact-us-box .single-input textarea {
  height: 200px;
}

/*========================================
    31. Compare Page Css
=========================================*/
.compare-table .table tbody tr:first-child {
  border-top: 1px solid #eeeeee;
}

.compare-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 30px;
  vertical-align: middle;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

.compare-table .table tbody tr td:last-child {
  border-right: 1px solid #eeeeee;
}

.compare-table .table tbody tr td.first-column {
  min-width: 180px;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin: 0;
  line-height: 1;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
  padding-top: 70px;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 40px;
}

.compare-table .table tbody tr td.product-image-title .image img {
  max-width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category {
  clear: both;
  color: #454545;
  float: left;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 15px;
  margin-bottom: 7px;
  margin-top: 15px;
  text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  margin-bottom: 10px;
}

.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #d3b48c;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td.pro-price {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.compare-table .table tbody tr td.pro-color {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.compare-table .table tbody tr td.pro-stock {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart {
  position: relative;
  background-color: #f6f6f6;
  color: #000000;
  border-radius: 50px;
  display: inline-block;
  width: 170px;
  padding: 10px 6px;
  transition: all 0.7s;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart span {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 21px;
  overflow: hidden;
  display: block;
  text-align: center;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart:hover {
  background: #d3b48c;
  color: #ffffff;
}

.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.compare-table .table tbody tr td.pro-remove button i {
  font-size: 20px;
}

.compare-table .table tbody tr td.pro-remove button:hover {
  color: #d3b48c;
}

.compare-table .table tbody tr td.pro-ratting i {
  color: #000000;
  font-size: 14px;
}

/*========================================
    32. Preview Page Css
=========================================*/
.preview-header {
  background: url(../images/preview/landing-bg1.jpg);
  background-size: cover;
  position: relative;
}

.header-top {
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top {
    height: 380px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-top {
    height: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .header-top {
    height: 260px;
  }
}

.header-top .container {
  position: relative;
  text-align: center;
  padding: 200px 0;
}

.header-top .container:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../images/preview/landing-bg1-mockup.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  left: 0%;
  top: 0%;
}

.bg-head {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 53px;
  background: url(../images/preview/bg-head.png);
  background-position: center;
}

.preview-hero-area {
  text-align: center;
}

.site-branding {
  display: inline-block;
  position: relative;
}

.site-branding img {
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .site-branding img {
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .site-branding img {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .site-branding img {
    width: 300px;
  }
}

.site-branding:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../images/preview/helas2.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  left: 2.5%;
  top: 10%;
}

.preview-menu {
  padding-top: 30px;
}

.one-page-nav {
  text-align: center;
  margin-bottom: 20px;
}

.one-page-nav li {
  padding: 5px 0;
  margin: 0 20px;
  display: inline-block;
}

.one-page-nav li a {
  color: #000000;
  font-size: 16px;
}

.one-page-nav li a:hover {
  color: #f2504b;
}

@media only screen and (max-width: 767px) {
  .one-page-nav li {
    margin: 0 10px;
  }
}

.preview-section-title {
  max-width: 600px;
  margin: auto;
  text-align: center;
  margin-bottom: 40px;
}

.preview-section-title .title {
  font-size: 36px;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  letter-spacing: -1px;
  text-align: center;
  background: url(../images/preview/diamond.png) no-repeat bottom center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .preview-section-title .title {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .preview-section-title .title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .preview-section-title .title {
    font-size: 26px;
  }
}

.single-demo-item {
  margin-top: 40px;
}

.single-demo-item a {
  position: relative;
  overflow: hidden;
  display: block;
}

.single-demo-item a img {
  width: 100%;
}

.single-demo-item a:before {
  content: "";
  background: #232324;
  opacity: 0;
  visibility: hidden;
  transition: opacity 250ms ease-out;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.single-demo-item a span {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -5%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  text-align: center;
  color: #ffffff;
}

.single-demo-item a span:after {
  content: "";
  width: 54px;
  height: 54px;
  display: block;
  margin: 25px auto 0;
  background: #f2504b url(../images/preview/tail-right.png) no-repeat center center;
  transition: all .3s;
}

.single-demo-item a:hover:before {
  opacity: 0.5;
  visibility: visible;
}

.single-demo-item a:hover span {
  margin-top: -15%;
  opacity: 1;
  visibility: visible;
}

.landing-shop-bg {
  background: url(../images/preview/landing-bg4.jpg) no-repeat center center;
  background-size: 100% auto;
  margin-top: 120px;
  padding-top: 35px;
  padding-bottom: 50px;
}

.feature-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.feature-item {
  background-color: #ffffff;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 45px 15px;
  text-align: center;
  margin-bottom: 30px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.feature-item h4 {
  margin-top: 15px;
  font-family: "Playfair Display", serif;
  font-size: 14px;
  font-weight: 600;
}

.preview-inner-page-bg {
  background: url(../images/preview/landing-bg5.jpg) no-repeat 0 0;
  background-size: cover;
  text-align: center;
}

.landing-footer-bg {
  background: url(../images/preview/landing-bg3.jpg) no-repeat 0 0;
  background-size: cover;
  text-align: center;
  padding-top: 90px;
}

.preview-footer-title {
  font-weight: 600;
}

.preview-copyright-text p {
  font-size: 12px;
  letter-spacing: 3px;
}

.preview-copyright-text p a:hover {
  color: currentColor;
}

.preview-inner-page-area {
  overflow: hidden;
}

.element-preview-display-area {
  height: 530px;
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-end;
}

@media only screen and (max-width: 767px) {
  .element-preview-display-area {
    flex-direction: column;
    height: auto;
  }
}

.element-preview-display-area .overlay {
  display: block;
  position: relative;
  opacity: inherit;
  visibility: visible;
}

.element-preview-display-area .overlay {
  position: relative;
  overflow: hidden;
}

.element-preview-display-area .overlay:before {
  content: "";
  background: #232324;
  opacity: 0;
  visibility: hidden;
  transition: opacity 250ms ease-out;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.element-preview-display-area .overlay span {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -5%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  text-align: center;
  color: #ffffff;
}

.element-preview-display-area .overlay span:after {
  content: "";
  width: 54px;
  height: 54px;
  display: block;
  margin: 25px auto 0;
  background: #f2504b url(../images/preview/tail-right.png) no-repeat center center;
  transition: all .3s;
}

.element-preview-display-area .overlay:hover:before {
  opacity: 0.5;
  visibility: visible;
}

.element-preview-display-area .overlay:hover span {
  margin-top: -15%;
  opacity: 1;
  visibility: visible;
}

.element-preview-display-area .overlay img {
  width: 380px;
}

.element-preview-display-area .single-inner-pages {
  position: relative;
}

.element-preview-display-area .inner-pages-layout-1 {
  text-align: center;
  z-index: 2;
  bottom: 0;
  position: absolute;
}

.element-preview-display-area .inner-pages-layout-1:hover {
  z-index: 99;
}

.element-preview-display-area .inner-pages-layout-1 img {
  width: 280px;
}

.element-preview-display-area .inner-pages-layout-2 {
  z-index: 1;
  bottom: 0;
  left: 8%;
  position: absolute;
}

.element-preview-display-area .inner-pages-layout-2:hover {
  z-index: 99;
}

.element-preview-display-area .inner-pages-layout-2 img {
  width: 480px;
}

.element-preview-display-area .inner-pages-layout-3 {
  z-index: 1;
  bottom: 0;
  left: 27%;
  position: absolute;
}

.element-preview-display-area .inner-pages-layout-3:hover {
  z-index: 991;
}

.element-preview-display-area .inner-pages-layout-3 img {
  width: 435px;
}

.element-preview-display-area .inner-pages-layout-4 {
  z-index: 1;
  bottom: 0;
  left: 48%;
}

.element-preview-display-area .inner-pages-layout-4:hover {
  z-index: 99;
}

.element-preview-display-area .inner-pages-layout-4 img {
  width: 500px;
}

.element-preview-display-area .inner-pages-layout-5 {
  z-index: 1;
  bottom: 30%;
  left: 80%;
  position: absolute;
}

.element-preview-display-area .inner-pages-layout-5:hover {
  z-index: 99;
}

.element-preview-display-area .inner-pages-layout-8 {
  bottom: 0;
  left: 72%;
  position: absolute;
  z-index: 6;
}

.element-preview-display-area .inner-pages-layout-8:hover {
  z-index: 99;
}

.element-preview-display-area .inner-pages-layout-8 img {
  width: 400px;
}
