/*==========================================
=           27. Shop style Css                =       
===========================================*/

.shop-top-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    @media #{$small-mobile} {
        display: block;
    }
    .shop-top-bar-left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media #{$small-mobile} {
            display: block;
        }
        @media #{$large-mobile} {
            display: flex;
        }
        .shop-top-show {
            line-height: 1;
            span {
                font-weight: 700;
                line-height: 24px;
                display: block;
                line-height: 1;
            }
        }
        .shop-page-list {
            margin-left: 45px;
            line-height: 1;
            @media #{$small-mobile} {
                margin-left: 0px;
                margin-top: 20px;
            }
            @media #{$large-mobile} {
                margin-left: 20px;
                margin-top: 0px;
            }
            ul {
                li {
                    display: inline-block;
                    color: #777777;
                    margin-right: 11px;
                    &:last-child {
                        margin-right: 0px;
                    }
                    &.active {
                        border-bottom: 1px solid #262626;
                        a {
                            color: #262626;
                        }
                    }
                    a {
                        color: #777777;
                    }
                }
            }
        }
    }
    .shop-top-bar-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .shop-filter {
            line-height: 1;
            position: relative;
            @media #{$large-mobile}{
                
            }
            a {
                color: #282828;
                font-weight: 700;
                i {
                    color: #282828;
                    font-weight: 700;
                    margin-left: 3px;
                    transition: all .4s ease 0s;
                    
                    &.angle-up {
                        position: absolute;
                        right: 1px;
                        top: 0px;
                        opacity: 0;
                        visibility: hidden
                    }
                }
                &.active {
                    i {
                        &.angle-down {
                            opacity: 0;
                            visibility: hidden
                        }
                        &.angle-up {
                            opacity: 1;
                            visibility: visible
                        }
                    }
                }
            }
        }
        .shop-short-by {
            position: relative;
            padding: 10px 0;
            line-height: 1;
            @media #{$large-mobile}{
                padding: 30px 0;
            }
            > span {
                color: #282828;
                font-weight: 700;
                position: relative;
                cursor: pointer;
                i {
                    color: #282828;
                    font-weight: 700;
                    margin-left: 3px;
                    transition: all .4s ease 0s;
                    
                    &.angle-up {
                        position: absolute;
                        right: 3px;
                        top: 1px;
                        opacity: 0;
                        visibility: hidden
                    }
                }
            }
            ul {
                right: 0;
                top: 100%;
                position: absolute;
                background-color: #fff;
                padding: 7px;
                z-index: 11;
                visibility: hidden;
                opacity: 0;
                box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.2);
                transition: all 250ms ease-out;
                margin-top: 30px;
                li {
                    display: block;
                    a {
                        padding: 5px 10px;
                        white-space: pre;
                        display: block;
                        border-radius: 4px;
                        font-size: 0.92em;
                        color: #777777;
                        line-height: 24px;
                        &:hover {
                            color: #222;
                            background-color: #ddd;
                        }
                    }
                    &.active {
                        a {
                            color: #222;
                            background-color: #ddd;
                        }
                    }
                }
            }
            &:hover {
                span i {
                    &.angle-down {
                        opacity: 0;
                        visibility: hidden
                    }
                    &.angle-up {
                        opacity: 1;
                        visibility: visible
                    }
                }
            }
            &:hover ul {
                opacity: 1;
                visibility: visible;
                margin-top: -5px;
            }
        }
        .shop-tab {
            a {
                color: #777777;
                font-size: 18px;
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
                &.active {
                    color: #262626;
                }
            }
        }
    }
}

.product-filter-wrapper {
	box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.05);
	margin-bottom: 30px;
	font-size: 12px;
    padding: 40px 55px 34px;
    display: none;
    width: 100%;
    @media #{$tablet-device} {
        padding: 40px 25px 34px;
    }
    @media #{$small-mobile} {
        padding: 40px 25px 34px;
    }
    .product-filter {
        h5 {
            font-size: 16px;
            margin: 0 0 17px;
            text-transform: capitalize;
            font-weight: 700;
            @media #{$small-mobile} {
                margin: 0 0 10px;
            }
        }
        .price-filter  {
            ul {
                li {
                    margin-bottom: 5px;
                    display: block;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        color: #535353;
                        font-size: 12px;
                        &:hover {
                            color: $theme-color--default;
                        }
                    }
                }
            }
        }
        .widget-nav-list {
             li {
                position: relative;
                margin-bottom: 11px;
                padding: 1px 0;
                width: 49%;
                display: inline-block;
                font-size: 12px;
            }
        }
        .price-filter {
            ul {
                li {
                    margin-bottom: 10px;
                }
            }
        }
        .product-tags {
            ul {
                width: 100%;
                li {
                    width: 50%;
                    display: inline-block;
                    float: left;
                }
            }
        }
        .fliter-size {
            ul {
                li {
                    display: inline-block;
                    margin-right: 9px;
                    margin-bottom: 10px;
                    @media #{$tablet-device} {
                        margin-right: 2px;
                    }
                    &:last-child {
                        margin-right: 0px;
                    }
                    a {
                        color: #535353;
                        font-size: 12px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        padding: 9px 16px;
                        display: block;
                        @media #{$tablet-device} {
                            padding: 3px 8px;
                        }
                        &:hover {
                            color: #fff;
                            border: 1px solid $theme-color--default;
                            background-color: $theme-color--default;
                        }
                    }
                }
            }
        }
    }
    .filter-close-wrap {
        a {
            font-size: 16px;
            color: $theme-color--default;
            i {
                position: relative;
                top: 3px;
                font-size: 20px;
                margin-right: 3px;
            }
        }
    }
}


