/*=============================================
=            21. Counterup Css           =
=============================================*/
.bg-counterup {
    background: #f3e9df;
}
.single-service-wrap {
    text-align: center;
    margin-top: 30px;
    .icon {
        margin-bottom: 15px;
    }
    .content {
        .title {
            font-size: 14px;
            letter-spacing: 2px;
            font-weight: 600;
            margin-bottom: 10px;
        }
    }
    
}