/*=======================================
=            06. Sidebar Css           =
=========================================*/


.shop-widget-title {
    font-weight: 600; 
}

.search-widget-box {
    .search-form-action {
        position: relative;
        & input {
            padding: 14px 40px 14px 14px;
            line-height: 20px;
            width: 100%;
            border: 1px solid #ddd;
        }
        .search-button {
            right: 15px;
            font-size: 14px;
            margin-top: 2px;
            position: absolute;
            border: none;
            background: transparent;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.shop-categories-list {
    li {
        margin-top: 20px;
        text-transform: uppercase;
        font-size: 12px;
    }
}

.widget-nav-color {
    li {
        display: inline-block;
        .swatch-color {
            position: relative;
            border-color: transparent;
            cursor: pointer;
            border-radius: 50%;
            height: 15px;
            width: 15px;
            display: inline-block;
            margin-top: 20px;
            margin-right: 12px;

            background: #b9afa1;
            &.black {
                background: $black; 
            }
            &.blue {
                background: #4b59a3; 
            }
            &.bronze {
                background: #b9afa1; 
            }
            &.green {
                background: #61c58d;
            }
            &.pink {
                background: #f6b7cf; 
            }
        }
    }
}

.widget-list-size {
    li {
        margin-top: 15px;
    }
    input[type="checkbox"]   {
        padding: 0;
        margin: 0;
        margin-right: 10px;
    }
}


.ui-widget-content {
    background:#eee; 
    border: medium none;
    border-radius: 0px;
    height: 3px;
    margin: 35px 0 25px;
    & .ui-state-default {
        background: #e9595e;
        border: 3px solid #e9595e;
        border-radius: 25px;
        box-shadow: none;
        color: #ffffff;
        cursor: ew-resize;
        font-weight: normal;
        height: 10px;
        width: 10px;
        top: -3px;
        &:hover {
            background: #e9595e;
        }
    }
    & .ui-slider-range {
        background: #111;
    }
}
.filter-price-cont {
    display: flex;
    flex-direction: row;
    font-size: 13px;
    margin-bottom: 10px;
}
.filter-price-cont { 
    font-size: 14px;
    & .input-type {
        & input {
            border: medium none;
            text-align: center;
            width: 40px; 
            background: transparent;
        }
        &:last-child {
            padding-left: 8px;
        }
    } 
    
}

.shop-tag {
    a {
        font-size: 12px;
        text-transform: uppercase;

        &:hover {
            text-decoration: underline;
        }
    }
}

.single-widget-product-list-item {
    display: flex;
    margin-top: 20px;
    .thumnil {
        flex-basis: 0 0 auto 0;
        img {
            max-width: 70px;
            margin-right: 20px;
        }
    }
    .content {
        .title {
            font-size: 15px;
            margin-bottom: 10px;
        }
        .price-box-wrap {
            display: flex;
            .reting-star {
                display: flex;
                margin-right: 15px;
                a {
                    color: rgb(245, 178, 35);
                    font-size: 14px;
                }
            }
        }
    }
}


.blog-widget {
    .search-form-action {
        position: relative;
        & input {
            padding: 14px 40px 14px 14px;
            line-height: 20px;
            width: 100%;
            border: 1px solid #ddd;
        }
        .search-button {
            right: 15px;
            font-size: 14px;
            margin-top: 2px;
            position: absolute;
            border: none;
            background: transparent;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.category-sub-menu {
    & ul {
        & li {
            margin-bottom: 15px;
            font-size: 12px;
            &.has-sub {
                & > a {
                    color: #363f4d;
                    cursor: pointer;
                    display: block;
                    line-height: 15px;
                    position: relative;
                    &:after {
                        color: #333333;
                        content: "\f067";
                        font-family: "FontAwesome";
                        font-size: 10px;
                        position: absolute;
                        right: 5px;
                    }
                }
                &.open {
                    display: block;
                    width: auto;
                    & > a {
                        text-decoration: underline;
                        &:after {
                          content: "\f068";
                        }
                    }
                }
                & li {
                    
                    & a {
                        border: 0 none;
                        border-bottom-color: currentcolor;
                        border-bottom-style: none;
                        border-bottom-width: 0px;
                        display: block;
                        line-height: 14px;
                        margin: 0 0px 10px 0;
                        padding: 0 10px 5px;
                        color: #333;
                        &:hover {
                            color: $theme-color--default;
                        }
                    }
                    &:last-child {
                        & a {
                            border: none;
                            margin-bottom: 10px;
                        }  
                    }
                }
            }
            & ul {
                display: none;
                margin-top: 15px;
            }
        }
    }
}
.single-widget-popular-post-list-item {
    display: flex;
    margin-top: 20px;
    .thumnil {
        flex-basis: 0 0 auto 0;
        img {
            max-width: 100px;
            margin-right: 15px;
        }
    }
    .content {
        .title {
            font-size: 15px;
            margin-bottom: 10px;
            font-weight: 600;
        }
        .widget-meta {
            font-size: 12px;
        }
    }
}

.widget-blog-comments {
    .recentcomments {
        padding-left: 30px;
        position: relative;
        margin-bottom: 20px;
        color: #8a8a8a;
        &:before {
            content: "\f0e6";
            font-family: "FontAwesome";
            position: absolute;
            left: 0;
            top: 0;
            font-size: 18px;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
        a {
            display: inline;
            padding: 0;
            margin: 0;
            color: #000;
        }    
    }
}
.blog-tag {
    a {
        margin-top: 10px;
    }
}



/*=====  End of Sidebar  ======*/