/*=============================================
=              04. Header Css               =
=============================================*/

.header-area{
    & .is-sticky{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999;
        transition: $transition--default;

    }
}

// - Sticky Css 

.header-sticky {
    z-index: 9999;
    &.scroll-down {
        transform: translate3d(0,-100%,0);
    }
    &.is-sticky {
        background-color: #fff;
        position: fixed;
    }
    .scroll-up {
        -o-transform: none;
        transform: none;
    }
    
}

.header-area--black {
        
    .header-sticky {
        &.is-sticky {
            background-color: #111;
            position: fixed;
            
        }
        
    }
    .dl-menuwrapper .dl-trigger::after {
        background-color: #fff;
        box-shadow: 0 8px 0 #fff, 0 16px 0 #fff;
    }
    .header-icon {
        color: $white;
    }
}


.header-absolute {
    position: absolute;
    z-index: 1;
    width: 100%;
}
.header-height--120 {
    height: 120px;
    align-items: center;
    display: flex;
    width: 100%;
    @media #{$tablet-device}{
        height: 100px;
    }
    @media #{$large-mobile}{
        height: 80px;
    }
}
.header{
    display: flex;
    align-items: center;
    
    /* - logo */
    
    &__logo{
        flex-basis: 15%;
        padding: 14px 0;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        &.top-logo {
            padding: 25px 0;
        }
        @media #{$laptop-device}{
            flex-basis: 15%;
        }
        @media #{$desktop-device}{
            flex-basis: 15%;
        }
        @media #{$tablet-device}{
            flex-basis: 30%;
            padding: 20px 0;
        }
        @media #{$large-mobile}{
            flex-basis: 40%;
            padding: 20px 0;
            &.top-logo {
                padding: 15px 0;
            }
        }
        & > a{
            img{
                width: 160px;
            }
        }
        & .dark-logo {
            display: none;
        }
        & .light-logo {
            display: inherit;
        }
        @media #{$tablet-device, $large-mobile}{
            & .dark-logo {
                display: inherit;
            }
            & .light-logo {
                display: none;
            }
        }
        @media #{$large-mobile}{
            &.mt-20 {
                margin-top: 0;
            }
            &.mb-20 {
                margin-bottom: 0;
            }
        }
    }
}

.header-right {
    flex-grow: 1;
    align-self: stretch;
    align-items: center;
    display: flex;
    justify-content: flex-end;
}


.menu-header-center {
    flex-grow: 1;
    align-self: stretch;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
}
.header-menu__right {
    display: flex;
    align-items: center;
    flex-basis: 15%;
    justify-content: flex-end;
    & a {
        font-size: 14px;
        margin-left: 30px;
    }

    @media #{$tablet-device} {
        margin-right: 40px;
    }
    @media #{$large-mobile} {
        margin-right: 50px;
    }
    .minicart-btn {
        position: relative;
        .item-counter {
            background-color: $theme-color--default;
            position: absolute;
            font-size: 10px;
            height: 15px;
            line-height: 15px;
            color: #fff;
            display: block;
            top: -10px;
            right: -10px;
            min-width: 15px;
            text-align: center;
            padding: 0;
            border-radius: 50%;
        }
    }
    &_sport {
        & a {
            &:hover {
                color: #54dfb2;
            }
        }
        .minicart-btn {
            position: relative;
            .item-counter {
                background-color: #54dfb2;
            }
        }
    }
    &_white {
        & a {
            color: #fff;
            &:hover {
                color: #fff;
            }
        }
        .minicart-btn {
            position: relative;
            .item-counter {
                background-color: $theme-color--default;
            }
        }
    }
    &.header-menu__sidebar {
        flex-basis: 100%;
        justify-content: flex-start;
    }
}

.header-col__right {
    display: flex;
    align-items: center;
    color: $white;
    & a {
        font-size: 30px;
        margin-left: 25px;
        &.search-icon {
            font-size: 20px;
        }
        @media #{$large-mobile}{
             margin-left: 13px;
             font-size: 24px;
        }
    }
    .header-icon {
        font-size: 20px;
    }
}
.minicart-count_area {
    position: relative;
}
span.cart-item_count {
    position: absolute;
    background: #333;
    color: #fff;
    font-size: 10px;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    text-align: center;
    margin-left: -50%;
    transform: translateX(-50%);
}

.header-left-info {
    flex-basis: 45%;
}
.header-logo-center {
    flex-grow: 1;
    align-self: stretch;
    align-items: center;
    display: flex;
    justify-content: center;
    @media #{$large-mobile,$tablet-device} {
        justify-content: flex-start;
    }
    & .logo{
        padding: 30px 0;
        @media #{$large-mobile,$tablet-device}{
            padding: 20px 0;
        }

    }
}
.header-right_info {
    flex-basis: 45%;
    justify-content: flex-end;
}
.header-info-text {
    display: flex;
    .header-info-item {
        font-size: 12px;
        margin-right: 15px;
        &:last-child {
            margin-right: 0;
        }
        i {
            margin-right: 5px;
        }
    }
}

/*============================== 
    - Navigation Menu Css
===============================*/

.navigation-menu{
    & > ul{
        display: flex;
        & > li{
            margin: 0 20px;
            padding: 40px 0;
            @media #{$xl-device}{
                margin: 0 20px;
            }
            @media #{$laptop-device}{
                margin: 0 10px;
            }
            @media #{$desktop-device}{
                margin: 0 10px;
            }
            & > a {
                display: block;
                color: $black;
                padding: 0px 0px;
                position: relative;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.18;
                &:before{
                    content: '';
                    width: 0;
                    height: 2px;
                    bottom: 0;
                    position: absolute;
                    left: 0;
                    background-color: $theme-color--default;
                    transition: $transition--default;
                }

            }

            &.has-children{
                & > a{
                    position: relative;
                }

                &--multilevel-submenu{
                    position: relative;
                }

                &:hover{
                    .megamenu{
                        transform: translateY(0);
                        visibility: visible;
                        opacity: 1;
                        &--home-variation{
                            &__item{
                                visibility: visible;
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }

                    }
                    & > .submenu{
                        transform: translateY(0);
                        visibility: visible;
                        opacity: 1;

                    }
                }
                
            }

            &:hover, &.active{
                & > a{
                    &:after{
                        color: $theme-color--default;
                    }
                    & span {
                        color: $theme-color--default;
                    }
                    &:before{
                        width: 100%;
                        left: 0;
                    }
                }
            }
        }
    }
    &.nav-style-one {
        > ul{
            & > li{
                & > a {
                    color: #fff;
                }
                &:hover, &.active{
                    & > a{
                        &:after{
                            color: $theme-color--default;
                        }
                        & span {
                            color: $theme-color--default;
                        }
                    }
                }
            }
        }
    }
    &.nav-text-white {
        > ul{
            & > li{
                & > a {
                    color: #fff;
                    &:before{
                        background-color: $white;
                    }
                }
                &:hover, &.active{
                    & > a{
                        &:after{
                            color: $white;
                        }
                        & span {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    &.nav-sport {
        > ul{
            & > li{
                & > a {
                    color: #333;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-family: $font-family-roboto;
                    &:before{
                        background-color: #54dfb2;
                    }
                }
                &:hover, &.active{
                    & > a{
                        &:after{
                            color: #54dfb2;
                        }
                        & span {
                            color: #54dfb2;
                        }
                    }
                }
            }
        }
    }
    &.left-sidebar-menu {
        & > ul{
            flex-direction: column;
            & > li {
                padding: 20px 0;
                margin: 5px 0;
                padding-top: 0;
                position: relative;
                
                & > a {
                    
                    font-family: $font-family-two;
                    &:before {
                        width: 0%;
                    }
                }
                > .megamenu--mega {
                    left: 100%;
                    position: absolute;
                    top: -100%;
                    width: 1000px;
                }
                
                .submenu {
                    left: 100%;
                    top: -50%;
                }
                &.active > a:before {
                    width: 0%;
                }
            }

        }
    }
}


/*===================================
    - Multilevel Submenu Css
====================================*/

.submenu {
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
    background-color: $white;
    transform: translateY(50px);
    transition: $transition--default;
    transition-delay: 0.2s;
    transition-duration: 0.4s;
    visibility: hidden;
    opacity: 0;
    min-width: 240px;
    padding: 20px 0;
    z-index: 9;
    border-bottom: 2px solid #d3b48c;
    & > li {
        position: relative;
        & > a{
            display: block;
            padding: 5px 30px;
            color: #444;
            font-weight: 400;
            transition: 0s;
            font-size: 14px;
            & > span{
                position: relative;
                &:after{
                    content: '';
                    width: 0;
                    height: 1px;
                    bottom: 0;
                    position: absolute;
                    left: auto;
                    right: 0;
                    z-index: -1;
                    background-color: $theme-color--default;
                    transition: 0.3s;
                }
            }
            &:hover{
                color: $theme-color--default;
                & > span{
                    &:after{
                        width: 100%;
                        left: 0;
                        right: auto;
                    }
                }
            }
        }
        &:hover {
            & > .submenu{
                transform: translateY(0);
                visibility: visible;
                opacity: 1;
                z-index: 9;

            }
        }
        
        &.active {
            & > a{
                color: $theme-color--default;
            }
        }
        
        &.has-children{
            & > a{
                position: relative;
                transition: $transition--default; 
                &:after{
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    content: "\ea12";
                    font-size: 12px;
                    vertical-align: middle;
                    color: #ababab;
                    font-family: 'LaStudioIcons';
                }

                

                &:hover{
                    &:after{
                        color: $theme-color--default;
                    }
                }
            }
            &.active {
                & > a{
                    color: $theme-color--default;
                }
            }
        }
    }

    & .submenu{
        top: 0px;
        left: 100%;
        right: auto;

        &.left{
            right: 100%;
            left: auto;
        }
       
        & .submenu{
            top: 0;
            left: 100%;
            right: auto;
            &.left{
                right: 100%;
                left: auto;
            }
            & .submenu{
                top: 0;
                left: 100%;
                right: auto;
                &.left{
                    right: 100%;
                    left: auto;
                }
                & .submenu{
                    top: 0;
                    left: 100%;
                    right: auto;
                    &.left{
                        right: 100%;
                        left: auto;
                    }
                }
            }
        }
    }  
}

/*=========================================
    - Mega Menu Css
===========================================*/

.megamenu{
    position: absolute;
    top: 100%;
    box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
    background-color: $white;
    transform: translateY(50px);
    transition: $transition--default;
    transition-delay: 0.2s;
    transition-duration: 0.4s;
    visibility: hidden;
    opacity: 0;
    z-index: 9;
    border-bottom: 2px solid #d3b48c;

    &--mega{
        width: 1170px;
        padding: 40px 30px;
        display: flex;
        justify-content: space-around;
        left: auto;
        right: 200px;
        z-index: 9;
        border-bottom: 2px solid #d3b48c;
        &__03 {
            width: 970px;
        }
        &__04 {
            width: 100%;
            right: 0;
            background-image: url(../images/bg/menu-bg.jpg);
            background-repeat: no-repeat;
            background-position: center center;
            max-width: none;
            background-color: #fff;
            border-bottom: 2px solid #d3b48c;
            padding-bottom: 20px;
            & > .center-menu {
                display: flex;
                justify-content: flex-start;
                min-width: 600px;
                & > li{
                    
                    flex-basis: 40%;
                    padding-left: 15px;
                    padding-right: 15px;
                    .page-list-title{
                        font-size: 12px;
                        margin-bottom: 20px;
                        color: $theme-color--black;
                        font-weight: 600;
                    }
        
                    & > ul{
                        & > li{
                            & > a{
                                padding: 10px 0;
                                color: #333;
                                line-height: 1.2;
                                transition: 0.1s; 
                                font-size: 14px;
                                &:hover{
                                    color: $theme-color--default;
                                    span{
                                        &:after{
                                            width: 100%;
                                            left: 0;
                                            right: auto;
                                        }
                                    }
                                }
                                & > span{
                                    position: relative;
                                    &:after{
                                        content: '';
                                        width: 0;
                                        height: 0.8px;
                                        bottom: 0;
                                        position: absolute;
                                        left: auto;
                                        right: 0;
                                        z-index: -1;
                                        background-color: $theme-color--default;
                                        transition: 0.3s;
                                    }
                                }
                            }
                            &.active {
                                & > a{
                                    color: $theme-color--default;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media #{$laptop-device}{
            width: 970px;
            &__04 {
                width: 100%;
            }
        }
        @media #{$desktop-device}{
            width: 100%;
            left: 0;
            right: 0;
        }
        
        & > li{
            flex-basis: 22%;
            padding-left: 15px;
            padding-right: 15px;
            .page-list-title{
                font-size: 12px;
                margin-bottom: 20px;
                color: $theme-color--black;
                font-weight: 600;
            }

            & > ul{
                & > li{
                    & > a{
                        padding: 10px 0;
                        color: #333;
                        line-height: 1.2;
                        transition: 0.1s; 
                        font-size: 14px;
                        &:hover{
                            color: $theme-color--default;
                            span{
                                &:after{
                                    width: 100%;
                                    left: 0;
                                    right: auto;
                                }
                            }
                        }
                        & > span{
                            position: relative;
                            &:after{
                                content: '';
                                width: 0;
                                height: 0.8px;
                                bottom: 0;
                                position: absolute;
                                left: auto;
                                right: 0;
                                z-index: -1;
                                background-color: $theme-color--default;
                                transition: 0.3s;
                            }
                        }
                    }
                    &.active {
                        & > a{
                            color: $theme-color--default;
                        }
                    }
                }
            }
        }
    }
}

/*======================================
=       - Offcanvas Minicart Css   =
=======================================*/
.offcanvas-minicart_wrapper {
	.offcanvas-menu-inner {
		padding: 60px;
		width: 450px;
		box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
		background-color: $white;
		@media #{$laptop-device}{
			width: 100%;
			padding: 53px 50px 45px;
		}
		.close-btn-box {
			text-align: left;
			margin-bottom: 30px;
		}
        .remove-button-box {
            width: 100%;
            text-align: right;
        }
		.btn-close {
			background: transparent;
			color: #333;
			top: 0;
			right: 0;
			left: auto;
			text-align: right;
			font-size: 26px;
            display: inline-block;
			&:hover{
                color: $theme-color--default;
                transform: rotate(90deg);
			}
		}
		.minicart-content {
            .minicart-heading {
                margin-bottom: 20px;
                margin-top: 20px;
                .title {
                    font-weight: 600;
                }
            }
			.minicart-list {
				max-height: 300px;
				position: relative;
				overflow: auto;
				> li {
					padding-bottom: 20px;
					&:last-child {
						padding-bottom: 0;
					}
					&.minicart-product {
						display: flex;
						border-bottom: 1px solid #ddd;
						margin-bottom: 20px;
                        &:last-child {
                            border: none;
                            margin-bottom: 0px;
                        }
                        & .product-item_img {
                            img {
                                width: 80px;
                            }
                        }
						> a {
							&.product-item_remove {
								> i{
									position: absolute;
									font-size: 14px;
									top: auto;
									right: 15px;
								}
							}
						}
						.product-item_content {
							flex-basis: calc(100% - 70px);
							max-width: calc(100% - 70px);
							padding-left: 20px;
							padding-right: 10px;
							a {
								&.product-item_title {
									color: $black;
									line-height: 1.4;
									font-size: 15px;
									font-weight: 500;
									margin-bottom: 5px;
									width: 90%;
									display: block;
									&:hover{
										color: #333;
									}
								}
							}
							label {
								font-weight: 500;
								display: block;
								margin-bottom: 0;
								margin-top: 0;
								font-size: 15px;
								span {
									color: #666;
									padding-left: 10px;
								}
							}
							.product-item_quantity {
								display: block;
								font-size: 13px;
								color: #333;
							}
						}
					}
				}
			}
		}
		.minicart-item_total {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 35px 0;
			font-size: 16px;
			font-weight: 500;
		}
		.minicart-btn_area {
			padding-bottom: 15px;
			.hiraola-btn {
				height: 60px;
				line-height: 60px;
				color: $white !important;
			}
		}
	}
    &.open {
		.offcanvas-menu-inner {
			visibility: visible;
			left: auto;
			right: 0;
			opacity: 1;
			z-index: 999;
		}
	}
}

/* ---Offcanvas--- */
.offcanvas-minicart_wrapper {
	.offcanvas-menu-inner {
		background-color: #fff;
		position: fixed;
		top: 0;
		right: -440px;
		width: 440px;
		height: 100%;
		z-index: 9999;
		visibility: hidden;
		overflow-y: auto;
		opacity: 0;
		transition: all 0.3s ease-in-out;
		.offcanvas-inner_logo{
			padding: 30px 0;
			text-align: center;
		}
		@media #{$large-mobile}{
			width: 300px;
			padding: 60px 20px 0 !important;
		}
	}
	
}

.global-overlay {
    background-color: rgba(33, 30, 28, 0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    visibility: hidden;
    &.overlay-open {
        visibility: visible;
        opacity: 1;
    }

}
   
/*========================================
=	    - Offcanvas Menu Css        =
==========================================*/
.offcanvas-menu_wrapper {
	.offcanvas-menu-inner {
		padding: 60px 60px 0;
		position: fixed;
		top: 0;
		right: -285px;
		width: 470px;
		height: 100%;
		background: #fff;
		z-index: 9999;
		overflow-y: auto;
		opacity: 0;
		visibility: hidden;
		text-align: left;
		transition: all 0.3s ease-in-out;
		@media #{$large-mobile}{
			width: 300px;
			padding: 60px 20px 0;
		}
        .remove-button-box {
            width: 100%;
            text-align: right;
        }
		.btn-close {
			top: 45px;
			left: 25px;
			width: auto;
			line-height: 1;
			height: auto;
			color: $black;
			font-size: 30px;
			display: block;
			background-color: transparent;
			&:hover {
				background: transparent;
			}
		}
		
		.offcanvas-inner_banner{
			padding-top: 40px;
			.inner-img{
				position: relative;
				cursor: pointer;
				width: 100%;
				a {
					display: block;
				}
				img{
					width: 100%;
					height: 100%;
					display: block;
					overflow: hidden;
				}
			}
		}

        .social-list {
            li {
                display: inline-block;
                margin-right: 10px;
            }
        }
        .offcanvas-info-list {
            margin-top: 30px;
            margin-bottom: 30px;
            li {
                margin-bottom: 10px;
                i {
                    margin-right: 5px;
                }
            }
        }
		.offcanvas-copyright {
            span {
                font-size: 12px;
            }
            margin-bottom: 40px;
        }
	}
	&.open {
		.offcanvas-menu-inner {
			left: auto;
			right: 0;
			opacity: 1;
			visibility: visible;
		}
	}
	
}


/*========================================
    - Offcanvas Search Css  
======================================== */
.offcanvas-search_wrapper {
	.offcanvas-menu-inner {
		background-color: rgba(255,255,255,.9);
		opacity: 0.97;
		width: 100%;
		right: 0;
		transform: all 0.4s ease-in-out;
		transform: scale(0);
        display: flex;
        align-items: center;
		visibility: visible;
		opacity: 0;
		.btn-close {
			background: transparent;
			color: $white;
            top: 100%;
			right: 30px;
			left: auto;
			font-size: 30px;
            color: $black;
            position: relative;
            transition:  all 0.3s ease-in-out;
			&:hover{
                i {
                    color: $theme-color--default;
                }
			}
		}
		.offcanvas-search {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;
			transform: scale(0.5);
			> span{
				&.searchbox-info{
					color: $black;
					font-size: 20px;
				}
			}
			.hm-searchbox {
				position: relative;
				transform-origin: top bottom;
                width: calc(100% - 10%);
                margin-top: 20px;
				> input {
					background-color: transparent;
					border: 0;
					border-bottom: 2px solid $black;
					height: 76px;
					line-height: 100px;
					width: 100%;
					padding: 0;
					color: $black;
					transform-origin: top bottom;
					text-align: center;
					font-size: 30px;
					padding-right: 40px;
					@media #{$small-mobile}{
						font-size: 16px;
						height: 60px;
						line-height: 60px
					}
					&::placeholder {
						color: $black;
						text-align: center;
					}
				}
				> .search_btn {
					position: absolute;
					font-size: 30px;
					top: 50%;
					transform: translateY(-50%);
					right: 0;
					border: 0;
					background: transparent;
					color: $black;
					@media #{$small-mobile}{
						font-size: 16px;
					}
					&:hover{
                        > i{
                            color: $theme-color--default;
                        }
                    }
				}
			}
		}
	}
	&.open {
		.offcanvas-menu-inner {
			left: auto;
			right: 0;
			padding: 0;
			transform: scale(1);
			visibility: visible;
			opacity: 1;
			.offcanvas-search{
				transform: scale(1);
			}
		}
	}
}




.offcanvas-search_wrapper {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    visibility: hidden;
    opacity: 0;

    a.btn-close {
        left: 0;
        width: 100%;
        text-align: right;
        margin-bottom: 80px;
    }

    .offcanvas-menu-inner {
        width: 100%;
        height: 100%;
    }


    &.open {
        opacity: 1;
        visibility: visible;
    }

    
}

// -Mobile menu css


.overlay.open-canvas, .overlay.open-cart {
    opacity: 1;
    visibility: visible;
    z-index: 99;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(35,35,36,.7);
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: all .3s linear;
}



.dl-menuwrapper button:hover, .dl-menuwrapper button.dl-active, .dl-menuwrapper ul {
    background: transparent;
}
.dl-menuwrapper .dl-trigger {
    float: right;
    background: 0 0;
    width: 28px;
    height: 28px;
    margin-right: 15px;
    margin-top: -45px
}

.dl-menuwrapper .dl-trigger::after {
    width: 80%;
    height: 2px;
    top: 0;
    left: 0;
    background-color: #232324;
    box-shadow: 0 8px 0 #232324, 0 16px 0 #232324;
}


.dl-menuwrapper button:after {
    content: '';
    position: absolute;
    width: 68%;
    height: 5px;
    background: #fff;
    top: 10px;
    left: 16%;
    box-shadow: 0 8px 0 #fff, 0 18px 0 #fff;
}

.dl-menuwrapper li a {
    font-size: 14px;
    background-color: #fff !important;
    color: #232324;
    padding: 12px 30px;
    text-transform: uppercase;
    border-top: 1px solid rgba(35,35,36,.15);
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -ms-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
}
.dl-menuwrapper {
    float: none;
    margin-right: 0;
    position: relative;
    max-width: inherit;
    z-index: 999;
}

.dl-menuwrapper .dl-menu {
    margin-top: 0;
    top: 100%;
}

.dl-menuwrapper li .dl-submenu .dl-back::after {
    left: 30px;
    color: #232324;
}


.dl-menuwrapper li .dl-submenu .dl-back {
    text-align: right;
}
.dl-menuwrapper li a::after {
    color: #232324;
    font-size: 14px;
    right: 30px;
    line-height: 44px;
}
.header-white-content {
    .dl-menuwrapper .dl-trigger::after {
        background-color: #fff;
        box-shadow: 0 8px 0 #fff, 0 18px 0 #fff;
    }
    .header-icon {
        color: $white;
    }
    @media #{$tablet-device} {
        .dl-menuwrapper .dl-trigger {
            margin-top: -64px;
        }
    }
    @media #{$large-mobile} {
        .dl-menuwrapper .dl-trigger {
            margin-top: -64px;
        }
    }
    @media #{$small-mobile} {
        .dl-menuwrapper .dl-trigger {
            margin-top: -50px;
        }
    }
    
}

// Cavas menu Css



.off-canvas-wrapper .off-canvas-navbar {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    max-width: 90%;
}

.off-canvas-wrapper .off-canvas-navbar .menu-close {
    position: absolute;
    top: 20%;
    right: 20%;
    line-height: 40px;
    transition: all .3s linear;
    font-size: 40px;
    color: rgba(35,35,36,.6);
}




.off-canvas-wrapper.open {
    opacity: 1;
    visibility: visible;
}
.off-canvas-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(255,255,255,.9);
    overflow: auto;
    text-align: center;
    transition: all .3s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
}

.off-canvas-wrapper.open .off-canvas-navbar .canvas-menu .main-menu>li {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
.off-canvas-wrapper .off-canvas-navbar .canvas-menu .main-menu>li:nth-of-type(2) {
    -webkit-transition-delay: .3s;
    -moz-transition-delay: .3s;
    -ms-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
}


.off-canvas-wrapper .off-canvas-navbar .canvas-menu .main-menu>li .sub-mega-menu li a {
    font-size: 16px;
    font-weight: 700;
    line-height: 2;
}
.off-canvas-wrapper .off-canvas-navbar .canvas-menu .main-menu>li a {
    font-size: 60px;
    font-weight: 700;
    color: #232324;
    line-height: 1.6;
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -ms-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    @media #{$laptop-device} {
        font-size: 50px;
    }
    @media #{$desktop-device} {
        font-size: 30px;
    }
}
.off-canvas-wrapper .off-canvas-navbar .canvas-menu .main-menu>li .sub-mega-menu li .sub-menu {
    padding: 10px 0;
}
.off-canvas-wrapper .off-canvas-navbar .canvas-menu .main-menu>li .sub-mega-menu li .sub-menu li a {
    font-size: 14px;
    font-weight: 400;
}
.off-canvas-wrapper .off-canvas-navbar .canvas-menu .main-menu>li .sub-menu li a {
    font-size: 16px;
    font-weight: 700;
    line-height: 2;
}


// header select

.header-select-item {
    display: flex;
    width: 280px;
    border: 1px solid #ddd;
    .select-top-inner {
        width: auto;
        select {
            border: transparent;
            width: 100%;
            height: 34px;
            padding: 0 20px 0 10px;
            font-size: 14px;
            background: transparent url("../images/icons/selector-icon.png") no-repeat center right 5px;
            &:focus {
                background: transparent url("../images/icons/selector-icon.png") no-repeat center right 5px !important;
            }
        }
    }
    & input {
        border: transparent;
        width: 100%;
        font-size: 14px;
    }
    .search-btn {
        border: none;
        background: transparent;
    }
}
.container-box {
    @media #{$tablet-device} {
        .header-menu__right {
            margin-right: 68px;
        }
    }
    @media #{$large-mobile} {
        .header-menu__right {
            margin-right: 68px;
        }
    }
}

.sidebar-left-wrapper {
    background: #f1f1f1 none repeat scroll 0 0;
    height: 100%;
    position: fixed;
    top: 0;
    transition: all 0.3s ease 0s;
    width: 300px;
    z-index: 7;
    padding: 40px 0 40px 40px;
    z-index: 999;
    .header-menu__right .header-icon:first-child  a {
        margin-left: 0;
    }
    .copy-right-text {
        p {
            font-size: 12px;
        }
    }
}
.sidebar-right-wrapper {
    margin-left: 300px;
    @media #{$desktop-device} {
        margin-left: 0;
    }
    @media #{$tablet-device} {
        margin-left: 0;
    }
    @media #{$large-mobile} {
        margin-left: 0;
    }
}









/*=====  End of Header  ======*/