
/*==============================================
=         30. Contact Us Pages Css          =
================================================*/


.googleMap-2 {
    height: 450px;
}

.contact-us-page-info-list {
    .contact-info-list {
        margin-top: 20px;
        color: $black;
        .icon {
            margin-right: 10px;
        }
    }
}

.contact-us-box {
    .single-input {
        
        &.your-name {
            position: relative;
            &::before {
                content: "\f2c0";
                font-family: FontAwesome;
                position: absolute;
                right: 1.2em;
                top: 10px;
            }
        }
        &.your-email {
            position: relative;
            &::before {
                content: "\f003";
                font-family: FontAwesome;
                position: absolute;
                right: 1.2em;
                top: 10px;
            }
        }
        input,textarea {
            width: 100%;
            border: 1px solid #ddd;
            margin-bottom: 30px;
            padding: 20px;
            
        }
        input {
            padding: 10px 50px 10px 20px;
        }
        textarea {
            height: 200px;
        }
    }
}