/*======================================
=         15. Countdown Css            =
=======================================*/
.offer-product-image {
     position: relative;
     .product-content {
          position: absolute;
          right: 5%;
          top: 25%;
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          .onsale {
               background-color: #d3b48c;
               width: 67px;
               line-height: 67px;
               border-radius: 50%;
               padding: 0;
               text-align: center;
               font-size: 13px;
               position: static;
               display: block;
               float: right;
               margin-right: 20px;
               color: #fff;
               &-two {
                    background-color: #dacb50;
                    width: 67px;
                    line-height: 67px;
                    border-radius: 50%;
                    padding: 0;
                    text-align: center;
                    font-size: 13px;
                    position: static;
                    display: block;
                    float: right;
                    margin-right: 20px;
                    color: #fff;
               }
          }
          &.onsale-position {
               right: 5%;
               top: 0%;
          }
          .product-price {
               display: flex;
               align-items: flex-end;
               .old-price {
                    font-size: 18px;
                    color: #8a8a8a;
                    text-decoration: line-through;
               }
               .new-price {
                    font-size: 36px;
                    color: #36393e;
                    letter-spacing: -2px;
               }
          }
     }
}

.countdown-deals{
     display: flex;
     justify-content: center;
     &.counter-style {
          &--one {
               & .single-countdown {
                    margin: 10px;
                    position: relative;
                    width: 76px;
                    height: 76px;
                    border: 1px solid rgba(69,69,69,.2);
                    border-radius: 50%;
                    // padding: 21px 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    @media #{$tablet-device}{
                         margin: 5px;
                         width: 60px;
                         height: 60px;
                    }
                    @media #{$large-mobile}{
                         margin: 5px;
                         width: 60px;
                         height: 60px;
                    }
                    
                    & .single-countdown__time {
                         display: block;
                         line-height: 1;
                         font-size: 18px;
                         font-weight: 700;
                         @media #{$tablet-device}{
                              font-size: 16px;
                         }
                         @media #{$large-mobile}{
                              font-size: 16px;
                         }

                    }
                    & .single-countdown__text {
                         margin-top: 5px;
                         display: block;
                         padding: 0;
                         font-size: 14px;
                         line-height: 1;
                         font-style: italic;
                         font-family: $font-family-two;
                         @media #{$tablet-device,$large-mobile}{
                              font-size: 14px;
                              margin-top: 0px;
                         }
                    }
               }
          }  
          &--two {
               & .single-countdown {
                    margin: 10px;
                    position: relative;
                    width: 76px;
                    height: 76px;
                    border-radius: 50%;
                    // padding: 21px 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    @media #{$tablet-device}{
                         margin: 5px;
                         width: 60px;
                         height: 60px;
                    }
                    @media #{$large-mobile}{
                         margin: 5px;
                         width: 60px;
                         height: 60px;
                    }
                    
                    & .single-countdown__time {
                         display: block;
                         line-height: 1;
                         font-size: 18px;
                         font-weight: 700;
                         @media #{$tablet-device}{
                              font-size: 16px;
                         }
                         @media #{$large-mobile}{
                              font-size: 16px;
                         }

                    }
                    & .single-countdown__text {
                         margin-top: 5px;
                         display: block;
                         padding: 0;
                         font-size: 14px;
                         line-height: 1;
                         font-style: italic;
                         font-family: $font-family-two;
                         @media #{$tablet-device,$large-mobile}{
                              font-size: 14px;
                              margin-top: 0px;
                         }
                    }
               }
          }  
          &--three {
               & .single-countdown {
                    margin: 10px;
                    position: relative;
                    width: 25%;
                    height: 76px;
                    border-radius: 50%;
                    // padding: 21px 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    @media #{$large-mobile}{
                         margin: 5px;
                         width: 60px;
                         height: 60px;
                    }
                    
                    & .single-countdown__time {
                         display: block;
                         font-size: 36px;
                         font-weight: 400;
                         line-height: 1.3;
                         font-family: $font-family-two;
                         color: #111;
                         margin-bottom: 10px;

                    }
                    & .single-countdown__text {
                         margin-top: 5px;
                         display: block;
                         padding: 0;
                         font-size: 14px;
                         line-height: 1;
                         @media #{$tablet-device,$large-mobile}{
                              font-size: 14px;
                              margin-top: 0px;
                         }
                    }
               }
          }  
     }
}
.coming-soon-content-area {
     height: 950px; 
     display: flex;
     align-items: center;
     @media #{$laptop-device}{
          height: 750px;
     }
     @media #{$desktop-device}{
          height: 650px;
     }
     @media #{$tablet-device}{
          height: 650px;
          .coming-soon-text {
               margin-top: 50px;
          }
     }
     @media #{$large-mobile}{
          height: 550px;
          .coming-soon-text {
               margin-top: 50px;
          }
     }
     @media #{$small-mobile}{
          height: 450px;
          .coming-soon-text {
               margin-top: 50px;
          }
     }
}
.error-defaut-bg {
     background: #fbece6;
     
}