
/*=========================================
=               05. Footer Css            =
===========================================*/

.section-radius {
    background-image: url(../images/radius/bg-white-radius.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: scroll;
    background-color: #f7f7f7;
    padding-top: 60px;
    padding-bottom: 0;
}


.reveal-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;

    @media #{ $tablet-device, $large-mobile}{
        position: static;
    }
}

.footer-widget-wrapper{
    margin-bottom: -35px;
}
.footer-widget{
    margin-bottom: 30px;
    &__title {
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
    }
    &__inline_list{
        li{
            margin-bottom: 10px;
            display: inline-block;
            margin: 10px 25px;
            color: #000;
            &:last-child{
                margin-right: 0;
            }
            &:first-child{
                margin-left: 0;
            }
            @media #{$large-mobile} {
                margin: 10px 10px;
            }
        }
    }
    &__list {
        li {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &.center-content {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }
}
.footer-widget-two {
    .footer-widget{
        margin-bottom: 30px;
        &__title {
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
            color: #fff;
        }
        &__inline_list{
            li{
                margin-bottom: 10px;
                display: inline-block;
                margin: 10px 25px;
                color: $white;
                &:last-child{
                    margin-right: 0;
                }
                &:first-child{
                    margin-left: 0;
                }
            }
        }
        &__list {
            li {
                font-size: 12px;
                margin-bottom: 20px;
                color: #eeeeee;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &.center-content {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            &.sm-center {
                @media #{$large-mobile}{
                    align-items: center;
                }
            }
        }
    }
}
.social-group__list {
    & .ht-social-networks.tilte-style  {
        .link-text {
            color: #000;
            &::before {
                background: rgba(255,255,255,0.2);
            }
            &:hover {
                color: $white;
            }
        }
        & .social-link {
            &::before {
                color: #ccc;
            }
        }
    }
}

.googleMap-1 {
    height: 200px;
}
.copy-text {
    p {
        font-size: 12px;
    }
}
.copyright-text{
    display: inline-block;
    letter-spacing: 2px;
    font-size: 14px;
}


.footer-handheld-footer-bar {
    display: none;
    @media #{$small-mobile}{
        display: block;
    }
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 6px rgba(0,0,0,.7);
    z-index: 999;
    transition: all .3s ease;
     .footer-handheld__inner {
        display: flex;
        background: #232324;
        position: static;
            
        .single-footer-bar {
            flex-basis: 100%;
            box-sizing: border-box;
            margin: 0;
            padding: 8px 0;
            color: #fff;
            border-left: 1px solid rgba(255,255,255,.1);
            text-align: center;
             .component-target {
                width: 40px;
                display: block;
                text-align: center;
                font-size: 16px;
                line-height: 40px;
                height: 40px;
                margin: auto;
            }
            .minicart-btn {
                position: relative;
                .item-counter {
                    background-color: #d3b48c;
                    position: absolute;
                    font-size: 10px;
                    height: 15px;
                    line-height: 15px;
                    color: #fff;
                    display: block;
                    top: 0px;
                    right: 0px;
                    min-width: 15px;
                    text-align: center;
                    padding: 0;
                    border-radius: 50%;
                }
            }
        }
    }
}

.account-user-statc {
    position: static;
}
.menu-footer-profile {
    background-color: #232324;
    width: 100%;
    bottom: 100%;
    top: auto;
    position: absolute;
    padding: 10px 0;
    opacity: 0;
    visibility: hidden;
    &.open {
        opacity: 1;
        visibility: visible;
    }
    
    // z-index: 11;
    // top: 100%;
    // width: 200px;
    // background-color: #fff;
    // padding: 15px;
    // box-shadow: 0 2px 12px 2px rgba(0,0,0,.1);
    // transition: all 250ms ease-out;
    .menu-item {
        text-align: left;
        a{
            display: block;
            padding: 8px 30px;
        }
    }
}

/*=====  End of footer ======*/
