
/*=======================================
=          23. Hero Slider Css           =
=========================================*/

.bg-img {
    background-color: #eee;
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
}
.single-hero-slider-one,.hero-slider-three {
    height: 900px;
    display: flex !important;
    align-items: center;
    background-size: cover;
    position: relative;
    @media #{$laptop-device}{
        height: 800px;  
    }
    @media #{$desktop-device}{
        height: 600px;
    }
    @media #{$tablet-device}{
        height: 400px;
    }
    @media #{$large-mobile}{
        height: 400px;
    }
    @media #{$small-mobile}{
        height: 400px; 
    }
    .slider-inner-images{
        margin-top: 150px;  
        @media #{$laptop-device}{
            margin-top: 40px;  
        }
        @media #{$desktop-device}{
            margin-top: 40px;
        }
        @media #{$large-mobile}{
            margin-top: 135px
        }
        @media #{$small-mobile}{
            img {
                width: 200px;
            }
        }
    }
    .hero-slider-content {
        margin-bottom: 80px;
        @media #{$tablet-device}{
            margin-bottom: 120px;
        }
        @media #{$small-mobile}{
            position: absolute; 
            top: 50%;
            right: 30px;

        }
        .hero-title {
            font-size: 30px;
        }
    }
}

.single-hero-slider-8 {
    height: 900px;
    display: flex !important;
    align-items: center;
    background-size: cover;
    position: relative;
    @media #{$laptop-device}{
        height: 800px;  
    }
    @media #{$desktop-device}{
        height: 600px;
    }
    @media #{$tablet-device}{
        height: 600px;
    }
    @media #{$large-mobile}{
        height: 500px;
    }
    @media #{$small-mobile}{
        height: 520px; 
    }
}
.single-hero-slider-two {
    height: 900px;
    display: flex !important;
    align-items: center;
    background-size: cover;
    position: relative;
    @media #{$laptop-device}{
        height: 800px;  
    }
    @media #{$desktop-device}{
        height: 600px;
    }
    @media #{$tablet-device}{
        height: 500px;
    }
    @media #{$large-mobile}{
        height: 400px;
    }
    @media #{$small-mobile}{
        height: 400px;
    }
    .hero-slider-content-two {
        position: relative;
        .hero-images-01 {
            margin: auto;
            text-align: center;
            max-width: 600px;
            z-index: 0;
            @media #{$desktop-device}{
                max-width: 450px;
            }
            @media #{$large-mobile}{
                max-width: 400px;
            }
            @media #{$tablet-device}{
                max-width: 400px;
            }
        }
        
        .hero-slider-text-two {
            bottom: 50px;
            z-index: 3;
            position: absolute;
            text-align: center;
            width: 100%;
            p {
                letter-spacing: 5px;
                margin-bottom: 30px;
            }
        }
        
    }
    .hero-slider-content-two--2 {
        text-align: center;
        .hero-images-02 {
            &_1 {
                margin: auto;
                margin-bottom: 10px;
            }
            &_2 {
                margin: auto;
                margin-bottom: -60px;
                z-index: 9;
                position: relative;
                @media #{$desktop-device}{
                    max-width: 300px;
                    margin-bottom: -20px;
                }
                @media #{$tablet-device}{
                    max-width: 200px;
                    margin-bottom: -20px;
                }
                @media #{$large-mobile}{
                    max-width: 200px;
                    margin-bottom: -20px;
                }
                
            }
            &_3 {
                margin: auto;
                margin-bottom: 20px;
                @media #{$desktop-device}{
                    max-width: 300px;
                }
                @media #{$tablet-device}{
                    max-width: 200px;
                }
                @media #{$large-mobile}{
                    max-width: 200px;
                }
                
            }
            &_4 {
                margin: auto;
                @media #{$desktop-device}{
                    max-width: 300px;
                }
                @media #{$tablet-device}{
                    max-width: 200px;
                }
                @media #{$large-mobile}{
                    max-width: 200px;
                }
                
            }
        }
    }
}
.hero-slider-one {
    .slick-dots {
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        @media #{$large-mobile}{
            bottom: 2px;
        }
        li {
            display: inline-block;
            margin: 10px;
            button {
                font-size: 0;
                border: none;
                height: 12px;
                width: 12px;
                background: #ddd;
                border-radius: 100%;
            }
            &.slick-active {
                button {
                    background: $theme-color--default;
                }
            }
        }
    }
}
.hero-slider-three,.hero-slider-four,.hero-slider-five {
    .slick-arrow {
        position: absolute;
        left: 30px;
        right: auto;
        top: 50%;
        transform: translateX(-50%);
        height: 40px;
        width: 40px;
        line-height: 40px;
        background: #fff;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        border: 1px solid #ddd;
            z-index: 1;
        &.arrow-next {
            right: 0px;
            left: auto;
        }
        &:hover {
            background: $theme-color--default;
        }
        @media #{$small-mobile} {
            opacity: 0;
            
        }
    }
    &:hover {
        .slick-arrow {
            opacity: 1;
        }
    }
}

.single-hero-slider-five {
    height: 100vh;
    align-items: center;
    display: flex !important;
    justify-content: center;
    .hero-slider-content-three, .hero-slider-content-three--2 {
        position: relative;
        z-index: 3;
    }
    @media #{$small-mobile} {
        .inner-images {
            img {
                width: 200px;
            }
        }
    }
    
    @media #{$small-mobile} {
        height: 460px;
        .inner-images {
            position: absolute;
            bottom: 0;
            z-index: 0;
        }

    }

}
.hero-fixd-slider-five {
    .slick-arrow {
        position: absolute;
        left: 30px;
        right: auto;
        top: 85%;
        height: 40px;
        width: 40px;
        line-height: 40px;
        background: #fff;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        border: 1px solid #ddd;
        z-index: 1;
        &.arrow-next {
            left: 80px;
            z-index: 1;
        }
        &:hover {
            background: $theme-color--default;
        }
    }
}




.hero-slider-content-three {
    h1 {
        line-height: 40px;
        letter-spacing: 0px;
        font-weight: 700;
        font-size: 30px;
    }
}

.slick-current  {
    & .hero-slider-content, 
    & .single-hero-slider-one,
    & .hero-slider-content-three,
    & .single-hero-slider-five,
    & .hero-slider-content-16
    {
        h1 {
            animation-name: fadeInLeft;
            animation-duration: 0.6s;
        }
        p {
            animation-name: fadeInLeft;
            animation-duration: 0.7s;
        }
        .button-box {
            animation-name: fadeInBottom;
            animation-duration: 0.9s;
        }
        .slider-inner-images,.inner-images {
            animation-name: bounceInUp;
            animation-duration: 1.9s;
        }
        .button-box {
            animation-name: bounceInUp;
            animation-duration: 1.4s;
        }
    }
    .single-hero-slider-two {
        .dic {
            animation-name: bounceInUp;
            animation-duration: 0.9s;
        }
        .hero-images-02_1 {
            animation-name: bounceInUp;
            animation-duration: 0.9s;
        }
        .hero-images-02_2 {
            animation-name: bounceInUp;
            animation-duration: 1.0s;
        }
        .hero-images-02_3 {
            animation-name: bounceInUp;
            animation-duration: 1.10s;
        }
        .hero-images-02_4 {
            animation-name: bounceInUp;
            animation-duration: 1.20s;
        }
        .button-box {
            animation-name: bounceInUp;
            animation-duration: 1.60s;
        }
    }
    & .hero-slider-content-six  {
        h1 {
            animation-name: bounceInDown;
            animation-duration: .9s;
        }
        p {
            animation-name: bounceInDown;
            animation-duration: 1.5s;
        }
    }
    & .hero-slider-content-14 {
        .image {
            animation-name: bounceInUp;
            animation-duration: 0.9s;
        }
    }
}

.hero-slider-content {
    &-six {
        .title {
            font-size: 120px;
            line-height: 1;
            @media #{$desktop-device} {
                font-size: 90px;
            }
            @media #{$tablet-device} {
                font-size: 60px;
            }
            @media #{$large-mobile} {
                font-size: 50px;
            }
            @media #{$small-mobile} {
                font-size: 36px;
            }
        }
    }
    &-seven {
        .title {
            font-size: 72px;
            line-height: 1;
            margin-bottom: 20px;
            @media #{$tablet-device} {
                font-size: 60px;
            }
            @media #{$large-mobile} {
                font-size: 50px;
            }
            @media #{$small-mobile} {
                font-size: 30px;
            }
        }
        .dic {
            max-width: 650px;
            margin: auto;
        }
    }
}

.hero-slider-content-ten {
    h1 {
        line-height: 30px;
        letter-spacing: 9px;
        font-weight: 700;
        font-size: 24px;
    }
}
.font-family--slider {
    font-family: Satisfy;
}
.hero-slider-content-12_one {
    .title {
        font-size: 50px;
        margin-bottom: 20px;
        letter-spacing: 10px;
        @media #{$tablet-device} {
            font-size: 38px;
            letter-spacing: 5px;
        }
        @media #{$large-mobile} {
            font-size: 30px;
            letter-spacing: 5px;
        }
        @media #{$small-mobile} {
            font-size: 26px;
            letter-spacing: 5px;
        }
    }
    .dec_text {
        border-top: 2px solid #333;
        padding-top: 20px;
        max-width: 650px;
        margin: auto;
    }
}
.hero-slider-content-12_two  {
    .images {
        max-width: 300px;
        margin: auto;
        text-align: center;
        margin-bottom: 20px;
        @media #{$tablet-device}{
            max-width: 200px;
        }
        @media #{$large-mobile}{
            max-width: 200px;
        }
        @media #{$small-mobile}{
            max-width: 150px;
        }
    }
    .title {
        border-top: 1px solid #333;
        padding-top: 20px;
        max-width: 650px;
        margin: auto;
        letter-spacing: 10px;
    }
}
.hero-slider-content-13 {
    .title {
        line-height: 200px;
        letter-spacing: -10px;
        font-weight: 700;
        font-size: 200px;
        font-family: $font-family-roboto;
        line-height: 0.8;
        @media #{$laptop-device} {
            font-size: 150px;
            letter-spacing: -5px;
        }
        @media #{$desktop-device} {
            font-size: 90px;
            letter-spacing: -5px;
        }
        @media #{$tablet-device} {
            font-size: 90px;
            letter-spacing: -5px;
        }
        @media #{$large-mobile} {
            font-size: 80px;
            letter-spacing: -2px;
        }
        @media #{$small-mobile} {
            font-size: 50px;
            letter-spacing: -2px;
        }
    }
}
.gradient-text {
    z-index: 2;
    color: rgb(76, 223, 190);
    background-image: linear-gradient(90deg, rgb(76, 223, 190) 0%, rgb(159, 227, 70) 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-color: rgb(76, 223, 190);
    transform-origin: 50% 50% 0px;
}

.hero-slider-content-14 {
    .image {
        display: inline-block;
        margin: auto;
        max-width: 100%;
        img {
            @media #{$large-mobile,$tablet-device} {
                width: 180px;
            }
        }
    }
    .button-box {
        display: block;
    }
    &--3 {
        .sub-title{
            font-weight: 400;
            font-size: 72px;
        }
        .title{
            line-height: 180px;
            letter-spacing: 0px;
            font-weight: 400;
            font-size: 150px;
        }
        @media #{$tablet-device}{
            .sub-title{
                font-size: 52px;
            }
            .title{
                line-height: 80px;
                font-size: 60px;
            }
        }
        @media #{$large-mobile}{
            .sub-title{
                font-size: 52px;
            }
            .title{
                line-height: 80px;
                font-size: 60px;
            }
        }
    }
}


.single-hero-slider-four {
    height: 800px;
    display: flex !important;
    align-items: center;
    background-size: cover;
    position: relative;
    @media #{$desktop-device}{
        height: 600px;
    }
    @media #{$tablet-device}{
        height: 540px;
    }
    @media #{$large-mobile}{
        height: 500px;
    }
    @media #{$small-mobile}{
        height: 400px;
    }
   
}


.hero-slider-content-15 {
    margin-top: 100px;
    @media #{$large-mobile}{
        margin-top: 50px;
    }
    @media #{$small-mobile}{
        margin-top: 50px;
    }
    .title {
        font-size: 26px;
        font-weight: 600;
        letter-spacing: 8px;
        z-index: 1;
        position: relative;
        @media #{$large-mobile}{
            font-size: 16px;
        }
        @media #{$small-mobile}{
            font-size: 16px;
        }
    }
    .image-one {
        max-width: 380px;
        position: absolute;
        text-align: center;
        top: 100px;
        z-index: 3;
        left: 10%;
        @media #{$desktop-device}{
            max-width: 277px;
            left: 12%;
        }
        @media #{$tablet-device}{
            max-width: 277px;
            left: 2%;
        }
        @media #{$large-mobile}{
            max-width: 177px;
            left: 2%;
        }
        @media #{$small-mobile}{
            max-width: 147px;
            left: 2%;
            top: 40%;
        }
        
    }
    .image-two {
        padding-top: 50px;
        max-width: 900px;
        margin: auto;
        text-align: center;
        @media #{$desktop-device}{
            max-width: 600px;
        }
        @media #{$tablet-device}{
            max-width: 600px;
        }
        @media #{$large-mobile}{
            max-width: 400px;
        }
        @media #{$small-mobile}{
            max-width: 260px;
        }
    }
    .center-image {
        position: relative;
        .image-three {
            position: absolute;
            top: 15%;
            right: 22%;
            max-width: 180px;
            @media #{$desktop-device}{
                max-width: 127px; 
                right: 28%;
            }
            @media #{$tablet-device}{
                max-width: 115px;
                right: 20%;
            }
            @media #{$large-mobile}{
                max-width: 80px;
                right: 25%;
                top: 25%;
            }
            @media #{$small-mobile}{
                max-width: 60px;
                right: 15%;
                top: 20%;
            }
        }
    }
    .image-four {
        position: absolute;
        right: 6%;
        top: 30%;
        width: 300px;
        z-index: 3;
        @media #{$desktop-device}{
            max-width: 250px;
            top: 40%;
        }
        @media #{$tablet-device}{
            max-width: 170px;
            top: 38%;
            right: 0%;
        }
        @media #{$large-mobile}{
            max-width: 140px;
            top: 38%;
            right: 0%;
        }
        @media #{$small-mobile}{
            max-width: 70px;
            top: 58%;
            right: 0%;
        }
    }
    .image-five {
        position: absolute;
        right: 18%;
        top: 14%;
        z-index: 1;
        width: 100px;
        @media #{$desktop-device}{
            top: 15%;
        }
        @media #{$tablet-device}{
            max-width: 60px;
            right: 12%;
            top: 15%;
        }
        @media #{$large-mobile}{
            max-width: 60px;
            right: 12%;
            top: 15%;
        }
        @media #{$small-mobile}{
            max-width: 60px;
            right: 12%;
            top: 25%;
        }
    }
    .image-six {
        position: absolute;
        right: 10%;
        top: 23%;
        width: 100px;
        @media #{$desktop-device}{
            top: 25%;
        }
        @media #{$tablet-device}{
            max-width: 60px;
            right: 2%;
            top: 25%;
        }
        @media #{$large-mobile}{
            max-width: 60px;
            right: 2%;
            top: 25%;
        }
        @media #{$small-mobile}{
            max-width: 40px;
            right: 2%;
            top: 35%;
        }
    }
}
.slider-click-button {
    position: absolute;
    z-index: 9;
    &.btn-1 {
        left: 8%;
        top: 4%;
    }
    &.btn-2 {
        left: 40%;
        top: 0%;
    }
    &.btn-3 {
        left: 40%;
        top: -27%;
    }
    &.btn-4 {
        left: 70%;
        top: -25%;
        @media #{$large-mobile} {
            left: 30%;
            top: 5%;
        }
    }
    &.btn-5 {
        left: 40%;
        top: -55%;
        @media #{$large-mobile} {
            left: 10%;
            top: -25%;
        }
    }
    &.btn-6 {
        left: 40%;
        top: 100%;
        @media #{$large-mobile} {
            left: 10%;
            top: -34px;
        }
    }
    .lamp-price {
        background: #4cdfbe;
        margin: 0px;
        width: 14px;
        height: 14px;
        display: inline-block;
        border-radius: 50%;
        z-index: 9;
    }
    .s_price {
        display: block;
        z-index: 22;
        background-color: rgb(76, 223, 190);
        text-align: center;
        line-height: 20px;
        letter-spacing: 0px;
        font-weight: 400;
        font-size: 12px;
        border-color: rgba(255, 255, 255, 0);
        border-style: none;
        margin: 0px;
        border-radius: 50px;
        padding: 20px 0px;
        color: rgb(255, 255, 255);
        text-decoration: none;
        white-space: nowrap;
        width: 60px;
        height: 60px;
        opacity: 0;
        visibility: hidden;
        transform-origin: 50% 50% 0px;
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        left: -27px;
        position: relative;
        top: -8px;  
        &::after {
            content: "";
            border-top: 15px solid rgb(76, 223, 190);
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            height: 0;
            width: 0;
            position: absolute;
            bottom: -10px;
            left: 70%;
            transform: rotate(-30deg);
        } 
        &.active {
            opacity: 1;
            visibility: visible;
        }
    }

}

.single-hero-slider-16 {
    height: 660px;
    display: flex !important;
    align-items: center;
    background-size: cover;
    position: relative;
    @media #{$desktop-device}{
        height: 500px;
    }
    @media #{$tablet-device}{
        height: 480px;
    }
    @media #{$large-mobile}{
        height: 420px;
    }
    @media #{$small-mobile}{
        height: 400px;
    }
}




