/*======================================
=        07. Button Css
=======================================*/

.btn {
     border-radius: 0;
     font-size: 15px;
     font-weight: 500;
     height: 50px;
     line-height: 38px;
     border: transparent;
     transition: $transition--default;
     border: transparent;
     
     &--text-s {
          font-size: 12px;
          font-weight: 600;
     }
     &--sm {
          line-height: 38px;
          padding: 0 22px;
          height: 40px;
     }
     &--md {
          line-height: 38px;
          padding: 0 32px;
          height: 40px;
          display: inline-block;
     }
     &--lg {
          line-height: 44px;
          padding: 0 42px;
          height: 46px;
          display: inline-block;
     }
     &--black {
          background: $black;
          color: $white;
          &:hover , 
          &:focus {
               background: $theme-color--default;
               color: $white;
          }
     }
     &--white {
          background: $white;
          color: $black;
          font-size: 12px;
          font-weight: 600;
          &:hover , 
          &:focus {
               background: $theme-color--default;
               color: $white;
          }
     }
     &--border_1 {
          background: transparent;
          border: 1px solid #222;
          &:hover {
               border: 1px solid $theme-color--default;
               background: $theme-color--default;
               color: $white;
          }
     }
     &--border_2 {
          background: transparent;
          border: 2px solid #222;
          &:hover {
               border: 2px solid $theme-color--default;
               background: $theme-color--default;
               color: $white;
          }
     }
     &--border-white_2 {
          background: transparent;
          border: 2px solid #fff;
          &:hover {
               border: 2px solid $theme-color--default;
               background: $theme-color--default;
               color: $white;
          }
     }
     &--border-white_1 {
          background: transparent;
          border: 1px solid #fff;
          &:hover {
               border: 1px solid $theme-color--default;
               background: $theme-color--default;
               color: $white;
          }
     }
     &--full {
          width: 100%;
     }
     &--icon {
          border: 1px solid #222;
          text-align: center;
          height: 46px;
          line-height: 44px;
          padding: 0 22px;
          &:hover {
               border: 1px solid $theme-color--default;
               background: $theme-color--default;
               color: $white;
          }
     }
     &--text-icon {
          font-size: 18px;
          font-weight: 500;
          & i {
               margin-right: 10px;
          }
     }
     &--text-small {
          font-size: 12px;
     }
     &--text-md {
          font-size: 14px;
     }
     &--border-bottom {
          font-size: 18px;
          text-decoration: underline;
          font-weight: 500;
          line-height: 1;
          &:hover {
               text-decoration: underline;
          }
     }
     &--default {
          background: $theme-color--default;
          color: $white;
          
          &:hover {
               background: #222;
               color: $white;
          }
     }
}
