
/*======================================
=         16. Choose Css            =
=======================================*/

.single-choose-us-item {
     display: flex;
     .image {
          flex: 0 0 auto;
          width: 180px;
          @media #{$small-mobile} {
               width: 85px;
          }
          @media #{$desktop-device}{
               width: 85px;
          }
     }
     .choose-content {
          margin-left: 20px;
          .title {
               margin-bottom: 20px;
          }
          p {
               font-size: 12px;
          }
     }
}






