/*========================================
    32. Preview Page Css
=========================================*/

.preview-header {
    background: url(../images/preview/landing-bg1.jpg);
    background-size: cover;
    position: relative;

}
.header-top {
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$desktop-device} {
        height: 380px;
    }
    @media #{$tablet-device} {
        height: 320px;
    }
    @media #{$large-mobile} {
        height: 260px;
    }
    .container  {
        position: relative;
        text-align: center;
        padding: 200px 0;
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url(../images/preview/landing-bg1-mockup.png);
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: contain;
            left: 0%;
            top: 0%;
        }
    }
}
.bg-head {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 53px;
    background: url(../images/preview/bg-head.png);
    background-position: center;
}
.preview-hero-area {
    text-align: center;
}
.site-branding {
    display: inline-block;
    position: relative;
    img {
        position: relative;
        @media #{$desktop-device} {
            width: 400px;
        }
        @media #{$tablet-device} {
            width: 300px;
        }
        @media #{$large-mobile} {
            width: 300px;
        }
    }
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url(../images/preview/helas2.png);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: contain;
        left: 2.5%;
        top: 10%;
    }
}
.preview-menu {
    padding-top: 30px;
}
.one-page-nav {
    text-align: center;
    margin-bottom: 20px;
    li {
        padding: 5px 0;
        margin: 0 20px;
        display: inline-block;
        a {
            color: $black;
            font-size: 16px;
            &:hover {
                color: #f2504b;
            }
        }
        @media #{$large-mobile} {
            margin: 0 10px;
        }
    }
}

.preview-section-title {
    max-width: 600px;
    margin: auto;
    text-align: center;
    margin-bottom: 40px;
    .title {
        font-size: 36px;
        font-family: 'Playfair Display', serif;
        font-weight: bold;
        margin: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        letter-spacing: -1px;
        text-align: center;
        background: url(../images/preview/diamond.png) no-repeat bottom center;
        @media #{$desktop-device} {
            font-size: 30px;
        }
        @media #{$tablet-device} {
            font-size: 26px;
        }
        @media #{$large-mobile} {
            font-size: 26px;
        }
    }
}



.single-demo-item {
    margin-top: 40px;
    a {
        position: relative;
        overflow: hidden;
        display: block;
        img {
            width: 100%;
        }
        &:before {
            content: "";
            background: #232324;
            opacity: 0;
            visibility: hidden;
            transition: opacity 250ms ease-out;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
        & span {
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -5%;
            width: 100%;
            opacity: 0;
            visibility: hidden;
            transition: all .3s;
            text-align: center;
            color: $white;
            &:after {
                content: "";
                width: 54px;
                height: 54px;
                display: block;
                margin: 25px auto 0;
                background: #f2504b url(../images/preview/tail-right.png) no-repeat center center;
                transition: all .3s;
            }
        }
        &:hover {
            &:before {
                opacity: 0.5;
                visibility: visible;
            }
            & span {
                margin-top: -15%;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}


.landing-shop-bg {
    background: url(../images/preview/landing-bg4.jpg)no-repeat center center;
    background-size: 100% auto;
    margin-top: 120px;
    padding-top: 35px;
    padding-bottom: 50px;
}

.feature-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.feature-item {
    background-color: #ffffff;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 45px 15px;
    text-align: center;
    margin-bottom: 30px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    h4 {
        margin-top: 15px;
        font-family: $font-family-two;
        font-size: 14px;
        font-weight: 600;
    }
}

.preview-inner-page-bg {
    background: url(../images/preview/landing-bg5.jpg) no-repeat 0 0;
    background-size: cover;
    text-align: center;
}



.landing-footer-bg {
   background: url(../images/preview/landing-bg3.jpg) no-repeat 0 0;
   background-size: cover;
   text-align: center;
   padding-top: 90px;
}
.preview-footer-title {
    font-weight: 600;

}
.preview-copyright-text {
    p {
        font-size: 12px;
        letter-spacing: 3px;
        a {
            &:hover {
                color: currentColor;
            }
        }
    }
}

.preview-inner-page-area {
    overflow: hidden;
  }
  
  .element-preview-display-area {
    height: 530px;
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-end;

    @media #{$large-mobile} {
        flex-direction: column; 
        height: auto;
    }

  }
  
  .element-preview-display-area .overlay {
    display: block;
    position: relative;
    opacity: inherit;
    visibility: visible;
  }
  
  .element-preview-display-area .overlay {
    position: relative;
    overflow: hidden;
    &:before {
        content: "";
        background: #232324;
        opacity: 0;
        visibility: hidden;
        transition: opacity 250ms ease-out;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
    & span {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -5%;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transition: all .3s;
        text-align: center;
        color: $white;
        &:after {
            content: "";
            width: 54px;
            height: 54px;
            display: block;
            margin: 25px auto 0;
            background: #f2504b url(../images/preview/tail-right.png) no-repeat center center;
            transition: all .3s;
        }
    }
    &:hover {
        &:before {
            opacity: 0.5;
            visibility: visible;
        }
        & span {
            margin-top: -15%;
            opacity: 1;
            visibility: visible;
        }
    }
}
  

  
  
.element-preview-display-area {
    .overlay img {
        width: 380px;
    }
    .single-inner-pages {
        position: relative;
      }
    .inner-pages-layout-1 {
        text-align: center;
        z-index: 2;
        bottom: 0;
        position: absolute;

        &:hover {
            z-index: 99;
        }

        img {
            width: 280px;
        }
    }
    .inner-pages-layout-2 {
        z-index: 1;
        bottom: 0;
        left: 8%;
        position: absolute;
        &:hover {
            z-index: 99;
        }   
        img {
            width: 480px;
        }
    }
    .inner-pages-layout-3 {
        z-index: 1;
        bottom: 0;
        left: 27%;
        position: absolute;
        &:hover {
            z-index: 991;
        }
        img {
            width: 435px;
        }
    }
    .inner-pages-layout-4 {
        z-index: 1;
        bottom: 0;
        left: 48%;
        &:hover {
            z-index: 99;
        }
        img {
            width: 500px;
        }
    }
    .inner-pages-layout-5 {
        z-index: 1;
        bottom: 30%;
        left: 80%;
        position: absolute;
        &:hover {
            z-index: 99;
        }
    }
    .inner-pages-layout-8 {
        bottom: 0;
        left: 72%;
        position: absolute;
        z-index: 6;
        &:hover {
            z-index: 99;
        }
        img {
            width: 400px;
        }
    }
      
  }
