/*======================================
=         19. Bakery Item Css            =
=======================================*/
.bakery-item-box {
     background: rgba(255,255,255,.9);
     padding: 2%;
     position: relative;
     &::before {
          content: "";
          background-image: url(//helas.la-studioweb.com/wp-content/uploads/2018/01/m14-corner-1.png),url(//helas.la-studioweb.com/wp-content/uploads/2018/01/m14-corner-2.png),url(//helas.la-studioweb.com/wp-content/uploads/2018/01/m14-corner-3.png),url(//helas.la-studioweb.com/wp-content/uploads/2018/01/m14-corner-4.png);
          background-position: left top,right top,right bottom,left bottom;
          position: absolute;
          left: 20px;
          top: 20px;
          right: 20px;
          bottom: 20px;
          display: block;
          background-repeat: no-repeat;
     }
}

.bakery-single-item {
     padding: 60px 30px 30px;
     text-align: center;
     .title-img {
          margin-bottom: 30px;
     }
}

.bakery-item {
     display: flex;
     padding: 10px;
     margin-bottom: 20px;
     .box-icon {
          margin-right: 15px;
          width: 170px;
     }
     .box-contents {
          text-align: center;
          .price-size {
               color: #333;
               font-size: 18px;
          }
     }
}






