/*=============================================
=            20. Accordions Css           =
=============================================*/
.faq-wrapper {
    .panel.panel-default {
        border: 1px solid #ebebeb;
        margin-bottom: 20px;
    }
    
    .card, .card-header{
        border-radius: 0;
        
    }

    .card{
        border-bottom: none;
        margin-bottom: 20px;
        background: transparent;
        border: none;
        &:last-child{
            margin-bottom: 0; 
        }
    }
    .card-header{
        padding: 0;
        border-bottom: 0;
        background-color: transparent;

        h5{
            button{
                border: none;
                width: 100%;
                text-align: left;
                color: $black;
                padding: 0px 0px 8px;
                position: relative;
                overflow-wrap: break-word;
                white-space: normal;
                font-size: 16px;
                line-height: 1.2;
                font-weight: 500;
                border-radius: 5px;
                background: transparent;
                border-bottom: 1px solid #ddd;
                text-transform: uppercase;

                span{
                    position: absolute;
                    right: 35px;
                    top: 50%;
                    transform: translateY(-50%);

                    @media #{$extra-small-mobile}{
                        right: 15px;
                    }
                    i{
                        font-size: 14px;
                        visibility: hidden;
                        opacity: 0;
                        display: none;
                    }
                }
                &:hover, &:focus{
                    text-decoration: none;
                    color: $black;
                }

                &[aria-expanded="true"]{
                    background: transparent;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0; 
                    border-bottom: 1px solid transparent;
                    span{
                        i{
                            &:nth-child(2){
                                visibility: visible;
                                opacity: 1;
                                display: inline;
                            }
                        }
                    }
                    &:before{
                        height: 100%;
                    }
                }

                &[aria-expanded="false"]{
                    span{
                        i{
                            &:nth-child(1){
                                visibility: visible;
                                opacity: 1;
                                display: inline;
                            }
                        }
                    }
                }
            }
        }
    }
    .card-body{
        padding: 5px 0px 20px;
       
    }
}

.frequently-item{
    ul{
        li{
            &.has-sub,
            &.children{
                border-bottom: 1px solid #e1e2e4;
                padding: 20px 0;
                &:last-child {
                    border-bottom: none;
                }
                @media #{$desktop-device}{
                    margin-bottom: 20px;
                }
                &:not(:last-child){
                    @media #{$desktop-device}{
                        margin-bottom: 20px;
                    }
                }
                > a {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    line-height: 20px;
                    color: $black;
                    font-weight: 500;
                    cursor: pointer;
                    i {
                        margin-right: 10px;
                    }
                }
                > ul{
                    &.frequently-body{
                        display: none;
                        padding-top: 20px;
                        li{
                            font-size: 14px;
                            line-height: 2;
                            color: $black;
                            padding: 0 22px;
                        }
                    }
                }
                &.open{
                    a{
                        color: $black;
                        i{
                            &:before{
                                content: "\ea68";
                            }
                        }
                    }
                }
            }
        }
    }
}

