

/*=============================================
=            22. Video Bnanre Css           =
=============================================*/

.lg-bnner-title  {
    font-size: 86px;
    line-height: 90px;
    @media #{$desktop-device}{
        font-size: 46px; 
      }
      @media #{$tablet-device}{
        font-size: 40px;
      }
      @media #{$large-mobile}{
        font-size: 34px;
      }
}    