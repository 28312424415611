/*==========================================
=        28. My account Page Css           =       
===========================================*/
.myaccount-box-wrapper {
    background: #fff;
    width: 100%;
    .helendo-tabs {
        ul {
            .tab__item {
                width: 50%;
                text-align: center;
                a {
                    color: #666;
                    font-size: 18px;
                    font-weight: 500;
                    padding: 15px 0;
                    background-color: #ededed;
                    &.active {
                        background: #fff;
                        color: $black;
                    }
                }
            }
        }
    }
    .content-modal-box {
        padding: 40px;

        .account-form-box {
            .single-input {
                margin-top: 30px;
                input {
                    padding: 10px 20px;
                    text-align: left;
                    width: 100%;
                    color: #666;
                    border: 1px solid #ccc;
                }
            }
        }

        .checkbox-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            label {
                margin-bottom: .0rem;
                
            }
            @media #{$extra-small-mobile}{
                flex-direction: column;
                justify-content: flex-start;
            }
            a {
                font-size: 14px;
            }
        }

        .input-checkbox {
            opacity: 0;
            line-height: 1;
            height: inherit;
            display: none;
        }
        span {
            display: inline-block;
            text-transform: inherit;
            position: relative;
            line-height: 1;
            font-size: 14px;
            cursor: pointer;
            padding: 0 0 0 30px;
            font-weight: 400;
            &::before {
                font-family: FontAwesome;
                top: -2px;
                left: 0px;
                transition: all 0.5s ease 0s;
                content: "";
                width: 19px;
                height: 19px;
                border: 2px solid;
                position: absolute;
                left: 0px;
                color: rgb(228, 228, 228);
            }
        }
        .input-checkbox:checked + span::before {
            content: "\f00c";
        }

    }
}
.myaccount-box-wrapper {
    .helendo-tabs {
        ul {
            justify-content: center;
            .tab__item {
                width: auto;
                text-align: center;
                font-size: 24px;
                padding: 0 22px;
                a {
                    color: #666;
                    font-size: 24px;
                    font-weight: 600;
                    padding: 15px 0;
                    background-color: transparent;
                    &.active {
                        background: transparent;
                        color: $black;
                    }
                }
            }
        }
    }
}