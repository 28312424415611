/*=======================================
=          26. About us Css            =
=========================================*/
.about-us-content {
     h2 {
          font-family: $font-family-four;
     }
}
.banner-text-right {
     max-width: 570px;
     margin-left: auto;
     @media #{$large-mobile, $tablet-device} {
          margin-top: 40px;
     }
}
.about-features-bg {
     background: #fff8f7;
}
.about-us-features-area {
     .about-inner-banner-image {
          margin-top: -40px;
          @media #{$large-mobile, $tablet-device} {
               margin-top: 60px;
          }
     }
}
.about-features-item {
     margin-top: 50px;
     max-width: 500px;
     .about-features-top {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          .icon {
               flex-basis: 0 0 auto 0;
               margin-right: 15px;
          }
          .title {
               font-weight: 600;
               font-size: 14px;
               letter-spacing: 2px;
          }
     }
}
