
/*=======================================
=             25. Blog Css              =
=========================================*/

.lates-blog-item {
     display: flex;
     margin-top: 30px;
     @media #{$large-mobile} {
          flex-direction: column;
     }
     
     .lates-blog-thumbnail {
          min-width: 50%;
          margin-right: 20px;
          overflow: hidden;
          @media #{$large-mobile} {
               min-width: 100%;
               margin-right: 0px;
               margin-bottom: 20px;
          }
          & a {
               display: block;
               img {
                    width: 100%;
               }
               position: relative;
               &::after {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background: rgba(0,0,0,0.3);
                    opacity: 0;
                    visibility: hidden;
                    transition: all  0.3s ease-in-out;
               }
          }
     }
     .lastes-blog-content {
          
          .sub-title {
               font-size: 12px;
               text-transform: uppercase;
          }
          .blog-meta {
               font-size: 12px;
          }
          .read-more-btn {
               font-weight: 600;
               font-size: 12px;
               text-transform: uppercase;
               &:hover {
                    color: $theme-color--default;
               }
          }
     }
     &:hover {
          .lates-blog-thumbnail {
               a {
                    img {
                         transform: scale(1.1);
                    }
                    &::after {
                         opacity: 1;
                         visibility: visible;
                    }
               }
          }
     }
}
.lates-blog-item-two {
     margin-top: 30px;
     position: relative;
     // display: flex;
     // flex-direction: column;
     &::after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.3);
          opacity: 0;
     }
     & .bg-img {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-size: cover;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          opacity: 0;
     }
     .lastes-blog-content {
          padding: 30px;
          border: 1px solid #ddd;
          position: relative;
          transition: all 0s cubic-bezier(0.645, 0.045, 0.355, 1);
          z-index: 3;
          .sub-title {
               font-size: 10px;
               text-transform: uppercase;
               margin-bottom: 3px;
               transition: all 0.0s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
          a {
               transition: all 0.0s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
          .blog-meta {
               font-size: 12px;
          }
          .blog-meta,
          .dec {
               a {
                    transition: all 0.0s cubic-bezier(0.645, 0.045, 0.355, 1);
               }
               transition: all 0.0s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
          .read-more-btn {
               font-weight: 600;
               font-size: 12px;
               text-transform: uppercase;
               transition: all 0.0s cubic-bezier(0.645, 0.045, 0.355, 1);
               &:hover {
                    color: $theme-color--default;
               }
          }
     }
     &:hover {
          & .bg-img {
               opacity: 1;
          }
          background-size: cover;
          &::after {
               opacity: 1;
          }
          .lastes-blog-content {
               opacity: 1;
               .sub-title, h5, .blog-meta, .dec, .read-more-btn {
                    color: $white;
               }
               
          }
     }
}
.single-blog-item-three {
     position: relative;
     margin-top: 30px;
     .blog-thumbnail {
          overflow: hidden;
          position: relative;
          .blog-image {
               display: block;
               img {
                    width: 100%;
               }
               position: relative;
               &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background: rgba(0,0,0,0.3);
                    opacity: 0;
                    visibility: hidden;
                    transition: all  0.3s ease-in-out;
               }
          }
          .blog_item--category-link {
               
               a {
                    color: #fff;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    background: #d3b48c;
                    padding: 6px 15px;
                    transform: translateX(-50%);
                    font-size: 10px;
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: 600;
                    z-index: 2;
               }
          }
     }
     .blog-content {
          margin-top: 20px;
          .blog_item--meta {
               margin-top: 5px;
               font-size: 12px;
          }
          .blog_item--excerpt {
               font-size: 14px;
               margin-top: 10px;
          }
     }
     &:hover {
          .blog-image {
               display: block;
               img {
                   transform: scale(1.05);
               }
               &::before {
                    opacity: 1;
                    visibility: visible;
                    z-index: 1;
               }
          }
     }
}

.single-blog-item-four {
     position: relative;
     margin-top: 30px;
     .blog-thumbnail {
          overflow: hidden;
          position: relative;
          .blog-image {
               display: block;
               img {
                    width: 100%;
               }
               position: relative;
               &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background: rgba(0,0,0,0.3);
                    opacity: 0;
                    visibility: hidden;
                    transition: all  0.3s ease-in-out;
               }
          }
          .blog_item--category-link {
               
               a {
                    color: #fff;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    background: #d3b48c;
                    padding: 6px 15px;
                    transform: translateX(-50%);
                    font-size: 10px;
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: 600;
                    z-index: 2;
               }
          }
     }
     .blog-content {
          border: 1px solid #ddd;
          padding: 20px 30px;
          .blog_item--meta {
               margin-top: 5px;
               font-size: 12px;
               font-style: italic;
               font-family: 'Playfair Display', serif;
          }
          .blog_item--excerpt {
               font-size: 14px;
               margin-top: 15px;
          }
          .blog-read-more {
               margin-top: 20px;
               .link-text {
                    margin-left: 10px;
                    font-size: 12px;
                    font-weight: 600;
               }
          }
     }
     &:hover {
          .blog-image {
               display: block;
               img {
                   transform: scale(1.05);
               }
               &::before {
                    opacity: 1;
                    visibility: visible;
                    z-index: 1;
               }
          }
     }
}

.single-blog-large {
     .blog-thumbnail {
          position: relative;
          display: block;
          & .blog-inner-icon {
               transition: all 0.15s ease-in-out;
               border-radius: 50%;
               transform: translate(-50%,-50%);
               opacity: 0;
               visibility: hidden;
               position: absolute;
               left: 50%;
               top: 50%;
               width: 40px;
               height: 40px;
               line-height: 45px;
               text-align: center;
               z-index: 4;
               font-size: 14px;
               background-color: #fff;
               color: #232324;
               &:hover{
                    color: #fff;
                    background: #333;
               }
          }
          img {
               width: 100%;
          }
          & .blog-inner-video {
               transition: all 0.15s ease-in-out;
               border-radius: 50%;
               transform: translate(-50%,-50%);
               position: absolute;
               left: 50%;
               top: 50%;
               width: 50px;
               height: 50px;
               line-height: 50px;
               text-align: center;
               z-index: 4;
               font-size: 14px;
               background-color: transparent;
               border: 1px solid #ddd;
               color: $white;
               &:hover{
                    color: #fff;
               }
          }
          &:hover {
               img {
                    transform: scale(1.04);
               }
               .blog-overlay,.blog-inner-icon { 
                    visibility: visible;
                    opacity: 1; 
               }
          }
          .blog-overlay {
               position: absolute;
               left: 0;
               top: 0;
               right: 0;
               bottom: 0;
               visibility: hidden;
               opacity: 0;
               transition: all 250ms ease-out; 
               transition: opacity 0.25s ease-in-out;
               background-color: rgba(35, 35, 36, 0.5);
          }
     }
     .blog-content {
          max-width: 600px;
     }
     &.border-box {
          border: 1px solid #ddd;
          transition: all 0.3s ease-in-out;
          padding-bottom: 30px;
          .blog-content {
               padding: 0 30px;
          }
          &:hover {
               box-shadow: 0 0 38px 0 rgba(82, 109, 249, 0.33);
          }
     }
}
.blog-content-getro {
     .blog-meta {
          font-size: 14px;
     }
}
.single-blog-slick-active {
     .slick-arrow {
          position: absolute;
          bottom: 40px;
          left: 50%;
          height: 50px;
          width: 50px;
          line-height: 50px;
          background: #ddd;
          text-align: center;
          border-radius: 50%;
          cursor: pointer;
          font-size: 20px;
          z-index: 1;
          &:hover {
               background: $white;
          }
          &.arrow-next {
               margin-right: -55px;
          }
          &.arrow-prv  {
               margin-left: -55px;
          }
     }
}

.blog-post-feature {
     .thumbnail {
          display: block;
          width: 100%;
     }
     .post-quote-text {
          padding: 0 50px;
          max-width: 800px;
          text-align: center;
          margin: auto;
          @media #{$small-mobile} {
               padding: 0 20px;
          }
     }
}


.blog-post-footer {
     margin-top: 40px;
     border-bottom: 1px solid #ddd;
     padding-bottom: 20px;
     display: flex;
     justify-content: space-between;
     @media #{$small-mobile}{
          flex-direction: column;
     }
 }
 .tags-list {
      margin-top: 10px;
 }
 .sharing-single-posts {
      margin-top: 10px;
      position: relative;
      display: inline-block;
     .social--sharing {
          position: absolute;
          right: 0;
          top: -30px;
          opacity: 0;
     }
     &:hover {
          .social--sharing {
               opacity: 1;
          } 
     }
}
.post-navigation {
     display: flex;
     justify-content: space-between;
     margin-top: 50px;
     align-items: center;
     .nav-previous {
          width: 50%;
          padding-left: 30px;
          background: url(../images/icons/arrows-stre-left.png) no-repeat left center;
          .post-title {
               font-family: $font-family-two;
               display: block;
          }
     }
     .nav-next {
          width: 50%;
          padding-right: 30px;
          text-align: right;
          background: url(../images/icons/arrows-stre-right.png) no-repeat right center;
          .post-title {
               font-family: $font-family-two;
               display: block;
          }
     }
}



.author-info--blog {
     padding-left: 35px;
     position: relative;
     margin-bottom: 50px;
     .author-info--inner {
          background-color: #f7faff;
          padding-left: 50px;
     }
     .author-info__avatar-inner {
          width: 70px;
          position: absolute;
          left: 0;
          top: 30px;
     }
     img.avatar {
          width: 100%;
          border-radius: 50%;
      }
     .author-info__description {
          padding: 30px;
     }
     .author-info__description .social-media-link.style-default a {
         margin: 0 9px;
         font-size: 12px;
     }
}