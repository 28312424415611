/*======================================
=         17. Team Css            =
=======================================*/

.single-team-item {
     position: relative;
     .team-image {
          position: relative;
          display: block;
          img {
               width: 100%;
          }
          &.filter-image {
               img {
                    filter: grayscale(100%);
               }
          }
     }
     .team-info {
          text-align: center;
          .team-info-title {
               margin-top: 20px;
               .team-role {
                    font-size: 12px;
               }
          }
          & .item--social {
               font-size: inherit;
               position: absolute;
               bottom: auto;
               width: 100%;
               left: 0;
               opacity: 0;
               visibility: hidden;
               transition: all 250ms ease-out 50ms;
               transform: translate(0,30px);
               & a {
                    display: inline-block;
                    padding: 0 10px;
               }
          }
     }
    
     .entry-excerpt {
          padding-top: 10px;
          font-size: 12px;
          line-height: 2;
          min-height: 30px;
          transition: all 250ms ease-out 50ms;
     }
     &:hover {
          .item--social {
               visibility: visible;
               opacity: 1;
               transform: translate(0,10px);
               -webkit-transform: translate(0,10px);
          }
          .entry-excerpt {
               transform: translate(0,-20px);
               -webkit-transform: translate(0,-20px);
               opacity: 0;
               visibility: hidden;
          }
          & .filter-image {
               img {
                    filter: grayscale(0%);
               }
          }
     }
}



