/*======================================
=            09. Product Css             =       
========================================*/

.single-product-item {
     margin-top: 30px;
     position: relative;
     .products-images {
          position: relative;
          &.image-border {
               border: 1px solid #ddd;
          }
     }
     .product-thumbnail {
          position: relative;
          display: block;
          &::after {
               position: absolute;
               content: "";
               left: 0;
               top: 0;
               height: 100%;
               width: 100%;
               background: rgba(0,0,0,0.10);
               opacity: 0;
               visibility: hidden;
               transition: all 0.3s ease-in-out;
          }
          .image-primary {
               img {
                    width: 100%;
               }
          }
          .image-secondary {
               position: absolute;
               left: 0;
               top: 0;
               transition: all 0.3s ease-in-out;
               opacity: 0;
               visibility: hidden;
               width: 100%;
               > img {
                    width: 100%;
               }
          }
          .ribbon {
               position: absolute;
               left: 0px;
               top: 0px;
               background: $black;
               color: $white;
               padding: 3px 12px;
               font-size: 12px;
               &.out-of-stock {
                    background: $black;
               }
               &.onsale {
                    background: #f14705;
               }
          }
          &> img {
               width: 100%;
          }
     }
     .product-wishlist {
          position: absolute;
          right: 10px;
          top: 15px;
          padding: 0 15px;
     } 
     .product-actions {
          position: absolute;
          top: 90%;
          left: 50%;
          transform: translate(-50%,-50%);
          display: flex;
          align-items: center;
          & a {
               width: 40px;
               height: 40px;
               line-height: 43px;
               background-color: #fff;
               text-align: center;
               font-size: 14px;
               margin-left: 5px;
               margin-right: 5px;
               color: #000;
               transition: .5s;
               opacity: 0;
               transform: translateY(20px);
               // transition-delay: 0s;
               &:nth-child(1) {
                    transition-delay: 0s;
               }
               &:nth-child(2) {
                    transition-delay: .15s;
               }
               &:nth-child(3) {
                    transition-delay: .3s;
               }

               & .tool-tip {
                    position: absolute;
                    background: #000;
                    text-align: center;
                    padding: 8px 12px;
                    z-index: 3;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity .6s, margin .3s;
                    white-space: pre;
                    font-size: 13px;
                    line-height: 14px;
                    top: -30px;
                    left: 50%;
                    transform: translateX(-50%);
                    color: #fff;
                    border-radius: 2px;
                    &::after {
                         border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #000;
                         border-style: solid;
                         border-width: 5px 0 5px 5px;
                         content: "";
                         height: 0;
                         position: absolute;
                         bottom: 10px;
                         left: 50%;
                         width: 0;
                         bottom: -6px;
                         transform: translateX(-50%) rotate(90deg);
                    }
               }
               &:hover{
                    color: $theme-color--default;
                    .tool-tip {
                         visibility: visible;
                         opacity: 1;
                         top: -35px;
                    }
               }
              
          }
          // a + a {
          //      transition-delay: 0.15s;
          // }

     }
     &:hover {
          .product-thumbnail {
               &::after {
                    opacity: 1;
                    visibility: visible;
               }
               .image-secondary {
                    opacity: 1;
                    visibility: visible;
               }
          }
          .product-actions {
               & a {
                    transform: translateY(0);
                    opacity: 1;
                    &:nth-child(1) {
                    transition-delay: 0s;
               }
               &:nth-child(2) {
                    transition-delay: .15s;
               }
               &:nth-child(3) {
                    transition-delay: .3s;
               }
               }
          }
          .product-content-two {
               background: #fff;
               .product_item--action{
                    margin-bottom: 0;
                    visibility: visible;
                    opacity: 1;
               }
          }
     }
     .product-content {
          margin-top: 20px;
          .prodect-title {
               font-size: 14px;
               margin-bottom: 5px;
               font-weight: 600;
          }
          .product-price {
               span {
                   color: #666; 
                   font-size: 14px;
               }
               .old-price {
                    text-decoration: line-through;
               }
               .new-price {
                    color: $theme-color--default;
               }
          }

     }
     .product-content-two {
          position: absolute;
          width: 100%;
          bottom: 0;
          background: rgba(225, 225, 225, 0.9);
          padding: 20px;
          .prodect-title {
               font-size: 14px;
               margin-bottom: 5px;
               font-weight: 600;
          }
          .product_item--action{
               display: block;
               margin-bottom: -20px;
               visibility: hidden;
               opacity: 0;
               transition: all 250ms ease-out;
               ul {
                    li {
                         position: relative;
                         display: inline-block;
                         padding: 0 10px;
                         font-size: 12px;
                         text-transform: uppercase;
                         &::before {
                              content: "";
                              position: absolute;
                              height: 12px;
                              top: 50%;
                              left: -5px;
                              width: 1px;
                              background: #000;
                              transform: translateY(-50%);
                         }
                         &:first-child {
                              &::before {
                                   display: none;
                              }
                         }
                    }
               }
          }
     }
}

.single-product-item-two {
     margin-top: 30px;
     border: 1px solid #ddd;
     position: relative;
     .products-images {
          position: relative;
     }
     .product-thumbnail {
          position: relative;
          display: block;
          .image-primary {
               img {
                    width: 100%;
               }
          }
          .image-secondary {
               position: absolute;
               left: 0;
               top: 0;
               transition: all 0.3s ease-in-out;
               opacity: 0;
               visibility: hidden;
               width: 100%;
               > img {
                    width: 100%;
               }
          }
          .ribbon {
               position: absolute;
               left: 0px;
               top: 0px;
               background: $black;
               color: $white;
               padding: 3px 12px;
               font-size: 12px;
               &.out-of-stock {
                    background: $black;
               }
               &.onsale {
                    background: #f14705;
               }
          }
          &> img {
               width: 100%;
          }
     }
     .product-wishlist {
          position: absolute;
          right: 10px;
          top: 15px;
          padding: 0 15px;
          z-index: 3;
     }
     .product-actions {
          position: absolute;
          top: 90%;
          left: 50%;
          transform: translate(-50%,-50%);
          display: flex;
          align-items: center;
          & a {
               width: 40px;
               height: 40px;
               line-height: 43px;
               background-color: #fff;
               text-align: center;
               font-size: 14px;
               margin-left: 5px;
               margin-right: 5px;
               color: #000;
               transition: .5s;
               opacity: 0;
               background: #ebebeb;
               transform: translateY(20px);
               // transition-delay: 0s;
               &:nth-child(1) {
                    transition-delay: 0s;
               }
               &:nth-child(2) {
                    transition-delay: .15s;
               }
               &:nth-child(3) {
                    transition-delay: .3s;
               }

               & .tool-tip {
                    position: absolute;
                    background: #000;
                    text-align: center;
                    padding: 8px 12px;
                    z-index: 3;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity .6s, margin .3s;
                    white-space: pre;
                    font-size: 13px;
                    line-height: 14px;
                    top: -30px;
                    left: 50%;
                    transform: translateX(-50%);
                    color: #fff;
                    border-radius: 2px;
                    &::after {
                         border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #000;
                         border-style: solid;
                         border-width: 5px 0 5px 5px;
                         content: "";
                         height: 0;
                         position: absolute;
                         bottom: 10px;
                         left: 50%;
                         width: 0;
                         bottom: -6px;
                         transform: translateX(-50%) rotate(90deg);
                    }
               }
               &:hover{
                    color: $theme-color--default;
                    .tool-tip {
                         visibility: visible;
                         opacity: 1;
                         top: -35px;
                    }
               }
          }

     }
     &:hover {
          .product-thumbnail {
               .image-secondary {
                    opacity: 1;
                    visibility: visible;
               }
          }
          .product-actions {
               & a {
                    transform: translateY(0);
                    opacity: 1;
                    &:nth-child(1) {
                    transition-delay: 0s;
               }
               &:nth-child(2) {
                    transition-delay: .15s;
               }
               &:nth-child(3) {
                    transition-delay: .3s;
               }
               }
          } 
     }
     .product-content {
          margin: 20px 0;
          .prodect-title {
               font-size: 14px;
               margin-bottom: 5px;
               font-weight: 600;
          }
          .product-price {
               span {
                   color: #666; 
                   font-size: 14px;
               }
               .old-price {
                    text-decoration: line-through;
               }
               .new-price {
                    color: $theme-color--default;
               }
          }

     }
}

.single-product-item-three {
     margin-top: 30px;
     border: 1px solid #ddd;
     position: relative;
     .products-images {
          position: relative;
     }
     .product-thumbnail {
          position: relative;
          display: block;
          .image-primary {
               img {
                    width: 100%;
               }
          }
          .image-secondary {
               position: absolute;
               left: 0;
               top: 0;
               transition: all 0.3s ease-in-out;
               opacity: 0;
               visibility: hidden;
               width: 100%;
               > img {
                    width: 100%;
               }
          }
          .ribbon {
               position: absolute;
               left: 0px;
               top: 0px;
               background: $black;
               color: $white;
               padding: 3px 12px;
               font-size: 12px;
               &.out-of-stock {
                    background: $black;
               }
               &.onsale {
                    background: #f14705;
               }
          }
          &> img {
               width: 100%;
          }
     }
     .product-wishlist {
          position: absolute;
          right: 10px;
          top: 15px;
          padding: 0 15px;
          z-index: 3;
     }
     .product-actions {
          position: absolute;
          top: 90%;
          left: 50%;
          transform: translate(-50%,-50%);
          display: flex;
          align-items: center;
          & a {
               width: 40px;
               height: 40px;
               line-height: 43px;
               background-color: #fff;
               text-align: center;
               font-size: 14px;
               margin-left: 5px;
               margin-right: 5px;
               color: #000;
               transition: .5s;
               opacity: 0;
               background: #ebebeb;
               transform: translateY(20px);
               // transition-delay: 0s;
               &:nth-child(1) {
                    transition-delay: 0s;
               }
               &:nth-child(2) {
                    transition-delay: 0s;
               }
               &:nth-child(3) {
                    transition-delay: 0s;
               }

               & .tool-tip {
                    position: absolute;
                    background: #000;
                    text-align: center;
                    padding: 8px 12px;
                    z-index: 3;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity .6s, margin .3s;
                    white-space: pre;
                    font-size: 13px;
                    line-height: 14px;
                    top: -30px;
                    left: 50%;
                    transform: translateX(-50%);
                    color: #fff;
                    border-radius: 2px;
                    &::after {
                         border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #000;
                         border-style: solid;
                         border-width: 5px 0 5px 5px;
                         content: "";
                         height: 0;
                         position: absolute;
                         bottom: 10px;
                         left: 50%;
                         width: 0;
                         bottom: -6px;
                         transform: translateX(-50%) rotate(90deg);
                    }
               }
               &:hover{
                    color: $theme-color--default;
                    .tool-tip {
                         visibility: visible;
                         opacity: 1;
                         top: -35px;
                    }
               }
          }

     }
     .product-content {
          border-top: 1px solid #ddd;
          padding: 20px 10px;
          .product-content-box {
               opacity: 1;
               transform: translate(0,0);
               transition: all 250ms ease-out;
          }
          .prodect-title {
               font-size: 14px;
               margin-bottom: 5px;
               font-weight: 600;
          }
          .product-price {
               span {
                   color: #666; 
                   font-size: 14px;
               }
               .old-price {
                    text-decoration: line-through;
               }
               .new-price {
                    color: $theme-color--default;
               }
          }

     }
     &:hover {
          .product-thumbnail {
               .image-secondary {
                    opacity: 1;
                    visibility: visible;
               }
          }
          .product-actions {
               & a {
                    transform: translateY(0);
                    opacity: 1;
                    &:nth-child(1) {
                         transition-delay: 0s;
                    }
                    &:nth-child(2) {
                         transition-delay: 0s;
                    }
                    &:nth-child(3) {
                         transition-delay: 0s;
                    }
               }
          } 
          
          .product-content-box {
               opacity: 0;
               transform: translate(0,-20px);
               
          }
     }
}


.single-product-item-four {
     margin-top: 30px;
     position: relative;
     .products-images {
          position: relative;
     }
     .product-thumbnail {
          position: relative;
          display: block;
          .image-primary {
               img {
                    width: 100%;
               }
          }
          .image-secondary {
               position: absolute;
               left: 0;
               top: 0;
               transition: all 0.3s ease-in-out;
               opacity: 0;
               visibility: hidden;
               width: 100%;
               > img {
                    width: 100%;
               }
          }
          .ribbon {
               position: absolute;
               left: 0px;
               top: 0px;
               background: $black;
               color: $white;
               padding: 3px 12px;
               font-size: 12px;
               &.out-of-stock {
                    background: $black;
               }
               &.onsale {
                    background: #f14705;
               }
          }
          &> img {
               width: 100%;
          }
     }
     .product-wishlist {
          position: absolute;
          right: 10px;
          top: 15px;
          padding: 0 15px;
          z-index: 3;
     }
     .product-actions {
          position: absolute;
          top: 100%;
          left: 0%;
          transform: translateY(-100%);
          width: 100%;
          overflow: hidden;
          & a {
               width: 100%;
               height: 40px;
               line-height: 43px;
               background:rgba(39,39,39,.9);
               text-align: center;
               font-size: 14px;
               color: #fff;
               transition: .5s;
               opacity: 0;
               font-size: 12px;
               text-transform: uppercase;
               transform: translateY(20px);
               &:hover{
                    color: $white;
                    background: $theme-color--default;
               }
          }
     }

     .content-action {
          position: relative;
          .prodect-price {
               opacity: 1;
               transition: all 250ms ease-out;
          }
          .cart--action {
               opacity: 0;
               position: absolute;
               width: 100%;
               top: 0;
               a {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 12px;
                    &::before {
                         content: "+";
                         font-weight: 400;
                         padding-right: 2px;
                    }
               }
          }
     }

     &:hover {
          box-shadow: 0 0 38px 0 rgba(41,44,58,.09);
          .product-thumbnail {
               .image-secondary {
                    opacity: 1;
                    visibility: visible;
               }
          }
          .product-actions {
               & a {
                    transform: translateY(0);
                    opacity: 1;
               }
          } 
          .cart--action {
               opacity: 1;
               transition: all 250ms ease-out;
          }
          .prodect-price {
               opacity: 0;
          }
     }
     .product-content {
          padding: 20px 0;
          .prodect-title {
               font-size: 14px;
               margin-bottom: 5px;
               font-weight: 600;
          }
          .product-price {
               span {
                   color: #666; 
                   font-size: 14px;
               }
               .old-price {
                    text-decoration: line-through;
               }
               .new-price {
                    color: $theme-color--default;
               }
          }

     }
}

.one-product-area {
     overflow: hidden;
     position: relative;
     background: linear-gradient(90deg,#4ddfbd 0%,#9ee347 100%);

     .one-product-price {
          margin-right: 32px;
          text-align: right;
          margin-bottom: 20px;
          span {
               background: linear-gradient(90deg,#f98c43 0%,#f76b17 100%);
               display: inline-block;
               padding: 12px 30px;
               color: $white;
          }
     }
     
     .content {
          text-align: center;
          position: relative;
          font-family: $font-family-roboto;
          
          .title-1{
               font-size: 200px;
               line-height: 1;
               letter-spacing: -10px;
               text-shadow: 0 0 59px rgba(0,0,0,.17);
               margin: -19% 0 0;
               position: relative;
               z-index: 2;
               color: $white;
               font-weight: 700;
               @media #{$laptop-device}{
                    font-size: 180px;
               }
               @media #{$desktop-device}{
                    font-size: 98px;
                    letter-spacing: -3px;
               }
               @media #{$tablet-device}{
                    font-size: 68px;
                    letter-spacing: -3px;
               }
               @media #{$large-mobile}{
                    font-size: 68px;
                    letter-spacing: -3px;
               }
               @media #{$small-mobile}{
                    font-size: 48px;
                    letter-spacing: -3px;
               }
          }
          .images {
               position: relative;
               display: inline-block;
               margin-bottom: 20px;
               @media #{$large-mobile}{
                    display: block;
               }
          }
          .title-2 {
               font-size: 200px;
               letter-spacing: -16px;
               text-shadow: 0 0 59px rgba(0,0,0,.17);
               line-height: normal;
               opacity: .5;
               position: absolute;
               left: 50%;
               white-space: nowrap;
               top: 8px;
               transform: translate(-50%,0);
               -webkit-transform: translate(-50%,0);
               z-index: 0;
               font-weight: 700;
               color: #ffffff;
               @media #{$laptop-device}{
                    font-size: 180px;
               }
               @media #{$desktop-device}{
                    font-size: 98px;
                    letter-spacing: -3px;
               }
               @media #{$tablet-device}{
                    font-size: 68px;
                    letter-spacing: -3px;
               }
               @media #{$large-mobile}{
                    font-size: 68px;
                    letter-spacing: -3px;
               }
               @media #{$small-mobile}{
                    font-size: 48px;
                    letter-spacing: -3px;
               }
          }
     }
     
}

.product-slider-active {
     .slick-arrow {
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         left: 0px;
         z-index: 3;
         font-size: 24px;
         cursor: pointer;
         color: #666;
         transition: all 0.3s ease-in-out;
         &.arrow-next {
              right: 0px;
              left: auto;
         }
         @media #{$large-mobile} {
               left: 15px;
               &.arrow-next {
                    right: 15px;
               }
          }
         
     }
}


.product-list-item {
     .products-images {
          position: relative;
          .product-thumbnail {
               position: relative;
               display: block;
               .image-primary {
                    img {
                         width: 100%;
                    }
               }
               .image-secondary {
                    position: absolute;
                    left: 0;
                    top: 0;
                    transition: all 0.3s ease-in-out;
                    opacity: 0;
                    visibility: hidden;
                    width: 100%;
                    > img {
                         width: 100%;
                    }
               }
               &> img {
                    width: 100%;
               }
          }
          .product-actions {
               position: absolute;
               top: 50%;
               left: 50%; 
               transform: translate(-50%,-50%);
               display: flex;
               align-items: center;
               & a {
                    width: 40px;
                    height: 40px;
                    line-height: 43px;
                    background-color: #fff;
                    text-align: center;
                    font-size: 14px;
                    margin-left: 5px;
                    margin-right: 5px;
                    color: #000;
                    transition: .5s;
                    opacity: 0;
                    background: #ebebeb;
                    transform: translateY(20px);
                    // transition-delay: 0s;
                    &:nth-child(1) {
                         transition-delay: 0s;
                    }

                    & .tool-tip {
                         position: absolute;
                         background: #000;
                         text-align: center;
                         padding: 8px 12px;
                         z-index: 3;
                         opacity: 0;
                         visibility: hidden;
                         transition: opacity .6s, margin .3s;
                         white-space: pre;
                         font-size: 13px;
                         line-height: 14px;
                         top: -30px;
                         left: 50%;
                         transform: translateX(-50%);
                         color: #fff;
                         border-radius: 2px;
                         &::after {
                              border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #000;
                              border-style: solid;
                              border-width: 5px 0 5px 5px;
                              content: "";
                              height: 0;
                              position: absolute;
                              bottom: 10px;
                              left: 50%;
                              width: 0;
                              bottom: -6px;
                              transform: translateX(-50%) rotate(90deg);
                         }
                    }
                    &:hover{
                         color: $theme-color--default;
                         .tool-tip {
                              visibility: visible;
                              opacity: 1;
                              top: -35px;
                         }
                    }
               }
          }
     }

     &:hover {
          .product-thumbnail {
               .image-secondary {
                    opacity: 1;
                    visibility: visible;
               }
          }
          .product-actions {
               & a {
                    transform: translateY(0);
                    opacity: 1;
                    &:nth-child(1) {
                         transition-delay: 0s;
                    }
               }
          } 
     }

     .product-content {
          margin-top: 30px;
          .prodect-title {
               font-weight: 600;
          }
          .reting-star {
               a {
                    color: #f5b223;
               }
          }
          .product-price {
               span {
                   color: #666; 
                   font-size: 16px;
               }
               .old-price {
                    text-decoration: line-through;
               }
               .new-price {
                    color: $black;
               }
          }
     }
     .product-list-action {
          a {
              &:hover {
                   color: $theme-color--default;
              } 
          }
     }
}

.reting-star {
     a {
          color: #f5b223;
     }
}

.single-product-item-mesonry {
     margin-top: 30px;
     position: relative;
     overflow: hidden;
     .product-thumbnail {
          position: relative;
          display: block;
          &::after {
               position: absolute;
               content: "";
               left: 0;
               top: 0;
               height: 100%;
               width: 100%;
               background: rgba(0,0,0,0.4);
               opacity: 0;
               visibility: hidden;
               transition: all 0.3s ease-in-out;
          }
          .image-primary {
               &> img {
                    width: 100%;
               }
          }    
     }
     .product-wishlist {
          position: absolute;
          right: 10px;
          top: 15px;
          padding: 0 15px;
     }
     .product-actions {
          position: absolute;
          top: 60%;
          left: 50%;
          transform: translate(-50%,-50%);
          display: flex;
          align-items: center;
          & a {
               width: 40px;
               height: 40px;
               line-height: 43px;
               background-color: #fff;
               text-align: center;
               font-size: 14px;
               margin-left: 5px;
               margin-right: 5px;
               color: #000;
               transition: .5s;
               opacity: 0;
               transform: translateY(20px);
               // transition-delay: 0s;

               & .tool-tip {
                    position: absolute;
                    background: #000;
                    text-align: center;
                    padding: 8px 12px;
                    z-index: 3;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity .6s, margin .3s;
                    white-space: pre;
                    font-size: 13px;
                    line-height: 14px;
                    top: -30px;
                    left: 50%;
                    transform: translateX(-50%);
                    color: #fff;
                    border-radius: 2px;
                    &::after {
                         border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #000;
                         border-style: solid;
                         border-width: 5px 0 5px 5px;
                         content: "";
                         height: 0;
                         position: absolute;
                         bottom: 10px;
                         left: 50%;
                         width: 0;
                         bottom: -6px;
                         transform: translateX(-50%) rotate(90deg);
                    }
               }
               &:hover{
                    color: $theme-color--default;
                    .tool-tip {
                         visibility: visible;
                         opacity: 1;
                         top: -35px;
                    }
               }
              
          }
     }
     &:hover {
          .product-thumbnail {
               &::after {
                    opacity: 1;
                    visibility: visible;
               }
          }
          .product-actions {
               top: 50%;
               & a {
                    transform: translateY(0);
                    opacity: 1;
               }
          }
          .product-content { 
               bottom: 10px;
               opacity: 1;
               visibility: visible;
          }
     }
     .product-content {
          margin-top: 20px;
          position: absolute;
          bottom: -10px;
          width: 100%;
          text-align: center;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s ease-in-out;
          .prodect-title {
               font-size: 14px;
               margin-bottom: 5px;
               font-weight: 600;
               color: $white;
          }
          .prodect-price {
               span {
                   color: #fff; 
                   font-size: 14px;
               }
               .old-price {
                    text-decoration: line-through;
               }
               .new-price {
                    color: $white;
               }
          }
     }
}

.product-code {
     p {
          font-size: 10px !important;
          font-weight: 600;
          color: #000;
     }
}





/*======================================
=      18. Product Tab list Css        =       
=======================================*/

.product-tab-menu {
     li {
          padding: 0 15px;
          margin-top: 10px;
          line-height: 12px;
          cursor: pointer;
          display: block;
          &:last-child {
               border-right: none;
          }
          &:last-child {
               padding-right: 0;
          }
          &:first-child {
               padding-left: 0;
          }
          @media #{$large-mobile} {
               padding: 0 1px;
          }
          a {
               padding: 0;
               border: 1px solid transparent;
               padding: 15px 20px;
               &.active ,
               &:hover{
                    color: #999;
                    border: 1px solid #ddd;
               }
          }
     }
     &--sport {
          li {
               a {  
                    font-size: 13px;
                    padding: 0;
                    border: 1px solid transparent;
                    padding: 12px 20px;
                    position: relative;
                    &::after {
                         content: "";
                         height: 3px;
                         background-image: linear-gradient(90deg,#4ddfbd 0%,#9ee347 100%);
                         position: absolute;
                         bottom: -1px;
                         left: -1px;
                         right: -1px;
                         opacity: 0;
                         visibility: hidden;
                    }
                    &.active ,
                    &:hover{
                         color: #999;
                         border: 1px solid #ddd;
                         &::after {
                              background-image: linear-gradient(90deg,#4ddfbd 0%,#9ee347 100%);
                              opacity: 1;
                              visibility: visible;
                         }
                    }
               }
          }
     }
     &--gradient-two {
          li {
               a {  
                    font-size: 13px;
                    padding: 0;
                    border: 1px solid transparent;
                    padding: 12px 20px;
                    position: relative;
                    &::after {
                         content: "";
                         height: 3px;
                         background-image: linear-gradient(90deg,#d1ecff 0%,#efcdcd 90%);
                         position: absolute;
                         bottom: -1px;
                         left: -1px;
                         right: -1px;
                         opacity: 0;
                         visibility: hidden;
                    }
                    &.active ,
                    &:hover{
                         color: #999;
                         border: 1px solid #ddd;
                         &::after {
                              background-image: linear-gradient(90deg,#d1ecff 0%,#efcdcd 90%);
                              opacity: 1;
                              visibility: visible;
                         }
                    }
               }
          }
     }
     &--two {
          li {
               line-height: 12px;
               cursor: pointer;
               display: block;
               &:last-child {
                    border-right: none;
               }
               &:last-child {
                    padding-right: 0;
               }
               &:first-child {
                    padding-left: 0;
               }
               a {
                    padding: 0;
                    border: 1px solid transparent;
                    padding: 5px 20px;
                    letter-spacing: 2px;
                    font-family: $font-family-two;
                    font-size: 30px;
                    &.active ,
                    &:hover{
                         color: $theme-color--default;
                    }
               }
          }
     }
}
.product-tab-menu-two {
     li {
          padding: 5px 20px;
          margin-top: 10px;
          line-height: 12px;
          cursor: pointer;
          display: block;
          font-size: 12px;
          &:last-child {
               border-right: none;
          }
          &:last-child {
               padding-right: 0;
          }
          &:first-child {
               padding-left: 0;
          }
          a {
               padding: 0;
               border-bottom: 2px solid transparent;
               padding: 5px 0px;
               &.active ,
               &:hover{
                    color: #000;
                    border-bottom: 2px solid $theme-color--default;
               }
          }
     }
}
.more-product {
     font-size: 12px;
    color: #36393e;
    line-height: 1;
    text-align: center; 
}
.tab-content .tab-pane {
	display: block;
	overflow: hidden;
	height: 0;
	visibility: hidden;
	max-width: 100%;
	opacity: 0;
}
.tab-content .tab-pane.active {
	height: auto;
	visibility: visible;
	opacity: 1;
	overflow: visible;
}




/*================================================
=     19. Single Product details Css             =       
==================================================*/

.product-modal-box {
     .modal-dialog {
          margin: 5% auto 8%;
          max-width: 1000px;
          
          @media #{$tablet-device,$desktop-device} {
               width: calc(100% - 15px);
          }
          @media #{$large-mobile} {
               width: calc(100% - 10px);
          }
     }
     
     .modal-dialog .modal-content {
          border-radius: 0;
          padding: 0;
     }

     .modal-body {
          padding: 30px;
          @media #{$large-mobile} {
               padding: 15px;
          }
     }     

     .modal-dialog .modal-content {
          .modal-header {
               border: none;
               padding: 0;
               right: 20px;
               top: 20px;
               position: absolute;
               z-index: 9999;
               color: $black;
          }
     }
          
     .close {
          float: right;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
          color: #fff;
          text-shadow: 0 1px 0 #fff;
          opacity: .5;
          position: absolute;
          top: -48px;
          right: 0;
          padding: 0;
     }

}


.product-dec-small {
     margin: 5px;
}

.product-small-image {
     margin: 0 -10px;
     margin-top: 20px;
}
.product-dec-small {
    padding: 0 10px;
}

.product-small-image {
     position: relative;
     .slick-arrow {
          font-size: 14px;
          line-height: 30px;
          width: 30px;
          height: 30px;
          cursor: pointer;
          position: absolute;
          background: #fff;
          top: calc(50% - 15px);
          text-align: center;
          left: 15px;
          right: auto;
          z-index: 3;
          color: #000;
          box-shadow: 0 0 5px 0px rgba(0,0,0,0.15);
          &.pro-dec-next {
               right: 0px;
               left: auto;
          }
     }
}


.product-details-content {
     p {
          font-size: 14px;
     }
}


.quickview-action-wrap {
     .quickview-cart-box {
          display: flex;
          align-items: center;
          @media #{$extra-small-mobile}{
               flex-direction: column;
               display: block;
          }
          
     }
     .quickview-quality {
          border: 1px solid #ddd;
     }
     .quickview-button {
          display: flex;
          align-items: center;
          width: 100%;

          .button {
               margin-left: 15px;
          }
          @media #{$extra-small-mobile}{
               .button:first-child {
                    margin-left: 0px;
               }
          }
     }
     .quickview-wishlist {
          a {
               width: 50px;
               height: 46px;
               line-height: 46px;
               display: inline-block;
               font-size: 20px;
               border: 1px solid #ddd;
               text-align: center;
          }
     }
}




.cart-quality-box {
     border: 1px solid #ddd;
     max-width: 100px;
} 
.cart-quality-box-2 {
     display: inline-block;
     border: 1px solid #ddd;
}
 .cart-plus-minus {
     display: inline-block;
     height: 36px;
     overflow: hidden;
     padding: 0;
     position: relative;
     width: 100px;
     text-align: center;
     .qtybutton {
          color: #333;
          cursor: pointer;
          float: inherit;
          font-size: 14px;
          line-height: 20px;
          margin: 0;
          position: absolute;
          text-align: center;
          transition: all 0.3s ease 0s;
          width: 24px;
          &.dec {
               height: 34px;
               top: 12px;
               left: 4px;
               font-weight: 600;
               font-size: 15px;
               font-family: sans-serif;
          }
          &.inc {
               height: 34px;
               top: 12px;
               right: 4px;
               font-weight: 600;
               font-size: 15px;
               font-family: sans-serif;
          }
     }
     input {
          &.cart-plus-minus-box {
               background: transparent none repeat scroll 0 0;
               border: medium none;
               color: #262626;
               font-size: 14px;
               height: 46px;
               line-height: 40px;
               margin: 0;
               padding: 0px;
               text-align: center;
               width: 100px;
               font-weight: 600;
          }
     }
}
.quality-height-dec {
     .cart-plus-minus {
          height: 45px;
          .qtybutton {
               &.dec {
               height: 45px;
               top: 11px;

               }
               &.inc {
               height: 45px;
               top: 12px;
               }
          }
          input {
               &.cart-plus-minus-box {
               height: 45px;
               line-height: 45px;
               }
          }
     }
}
.quality-height-dec2 {
     .cart-plus-minus {
          height: 45px;
          // margin-left: -15px;
          .qtybutton {
               &.dec {
               height: 45px;
               top: 11px;
               font-weight: 500;
               left: 22px;
               }
               &.inc {
               height: 45px;
               top: 12px;
               font-weight: 500;
               right: 22px;
               }
          }
          input {
               &.cart-plus-minus-box {
               height: 45px;
               line-height: 45px;
               width: 80px;
               font-weight: 400;
               }
          }
     }
}

.singel-cart-wrap {
     margin-bottom: 20px;
     .singel-cart-title {
          display: inline-block;
          margin-left: 10px;
          .title {
               font-size: 14px;
               font-weight: 600;
          }
     }
}


.product-support-text {
     a {
          font-size: 14px;
          display: inline-block;
          margin-right: 10px;
          & i {
               margin-right: 10px;
          }
     }
}

.quickview-meta {
     span {
          display: block;
          font-size: 12px;
          margin-bottom: 10px;
     }
}

.product_meta {
     .item_meta {
          font-weight: 500;
          color: #666;
          .label {
               color: $black;
          }
          & a {
               &:hover {
                    color: #666;
                    text-decoration: underline;
               }
          }
     }
}

.product_socials-inline {
     li {
          display: inline-block;
          margin-right: 15px;
     }
}


.section-title-6 .title {
     font-size: 70px;
     font-weight: 700;
     color: #232324;
     text-transform: capitalize;
     position: relative;
     display: inline-block;
     padding-bottom: 18px;
     letter-spacing: -5px;
 }
 
.tab-pane.active {
     -webkit-animation: tabUp .5s;
     -moz-animation: tabUp .5s;
     -o-animation: tabUp .5s;
     animation: tabUp .5s;
 }
@-webkit-keyframes tabUp {
     from {
          transform: translate3d(0, 30px, 0);
          -webkit-transform: translate3d(0, 30px, 0);
          -moz-transform: translate3d(0, 30px, 0);
          -ms-transform: translate3d(0, 30px, 0);
          -o-transform: translate3d(0, 30px, 0);
     } to {
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
          -o-transform: translate3d(0, 0, 0);
     }
}
@keyframes tabUp {
          from {
          transform: translate3d(0, 30px, 0);
          -webkit-transform: translate3d(0, 30px, 0);
          -moz-transform: translate3d(0, 30px, 0);
          -ms-transform: translate3d(0, 30px, 0);
          -o-transform: translate3d(0, 30px, 0);
     } to {
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
          -o-transform: translate3d(0, 0, 0);
     }
}
 